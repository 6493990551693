import { useEffect } from 'react';
import bwipjs from 'bwip-js';

interface BarcodeProps {
  value: string;
  barcodeID: string;
}
export const Barcode = ({ value, barcodeID }: BarcodeProps) => {
  useEffect(() => {
    let canvas = document.createElement('canvas');
    bwipjs.toCanvas(canvas, {
      bcid: 'code128', // Barcode type
      text: value, // Text to encode
      scale: 2, // 3x scaling factor
      height: 20, // Bar height, in millimeters
      includetext: true, // Show human-readable text
      textxalign: 'center',
      rotate: 'R',
    });

    const img = document.getElementById(
      `${barcodeID}`
    ) as HTMLImageElement | null;
    if (img) {
      img.src = canvas.toDataURL('image/png');
    }
  });

  return (
    <div className="h-full px-0">
      <img className="object-cover" id={barcodeID} />
    </div>
  );
};
