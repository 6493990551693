import { Group, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
}

export const CryptoSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <img className="h-5" src={image} alt={`${label} icon`} />

        <div>
          <Text size="md">{label}</Text>
        </div>
      </Group>
    </div>
  )
);
