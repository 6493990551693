
import { SupportedSellMethod } from 'common/types'

export const getSellMethod = (sellMethodId: string) => {
    switch (sellMethodId) {
        case SupportedSellMethod.IN_PERSON:
            return 'Pickup - ATM';
        case SupportedSellMethod.PAYPAL:
            return 'Paypal';
        case SupportedSellMethod.VENMO:
            return 'Venmo';
        default:
            return 'Unknown Sell Method';
    }
};
