import { User } from 'common/types/user';

const STORAGE_PREFIX = 'unbank_webapp_';

const storage = {
  getToken: () => {
    const token = localStorage.getItem(`${STORAGE_PREFIX}token`);
    return token;
  },

  getTempToken: () => {
    const token = localStorage.getItem(`${STORAGE_PREFIX}temp_token`)
    return token
  },
  getTwoFAValid: () => {
    return localStorage.getItem(`${STORAGE_PREFIX}_check_2fa`)
  },
  setTwoFA: (validity: boolean) => {
    localStorage.setItem(`${STORAGE_PREFIX}_check_2fa`, JSON.stringify({ isValid: validity }));
  },
  setTempToken: (token: string) => {
    localStorage.setItem(`${STORAGE_PREFIX}temp_token`, token);
  },
  setToken: (token: string) => {
    localStorage.setItem(`${STORAGE_PREFIX}token`, token);
  },
  setUser: (user: User) => {
    localStorage.setItem(`${STORAGE_PREFIX}user`, JSON.stringify(user));
  },
  getUser: () => {
    const user = localStorage.getItem(`${STORAGE_PREFIX}user`);
    if (!user) return undefined;
    return JSON.parse(user) as User;
  },
  clearTempData: () => {
    localStorage.removeItem(`${STORAGE_PREFIX}temp_token`,)
  },
  clearAuthData: () => {
    localStorage.removeItem(`${STORAGE_PREFIX}token`);
    localStorage.removeItem(`${STORAGE_PREFIX}user`);
    localStorage.removeItem(`${STORAGE_PREFIX}_check_2fa`);
  },
  setRememberMe: (shouldRemeber: boolean) => {
    localStorage.setItem(`${STORAGE_PREFIX}remember_me`, `${shouldRemeber}`);
  },
  getRememberMe: () => {
    const shouldRemeber = localStorage.getItem(`${STORAGE_PREFIX}remember_me`);
    if (!shouldRemeber) return false;
    return JSON.parse(shouldRemeber) as boolean;
  },
  setPhone: (phone: string) => {
    localStorage.setItem(`${STORAGE_PREFIX}phone`, phone);
  },
  clearPhone: () => {
    localStorage.removeItem(`${STORAGE_PREFIX}phone`);
  },
  getPhone: () => {
    const phone = localStorage.getItem(`${STORAGE_PREFIX}phone`);
    return phone ?? '';
  },
};

export default storage;
