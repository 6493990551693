import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

type InvoiceExpiredProps = {
  orderId: string;
  invoiceId: string;
  onRestartProcess: () => void;
};

export const InvoiceExpired = ({
  orderId,
  invoiceId,
  onRestartProcess,
}: InvoiceExpiredProps) => {
  const navigate = useNavigate();
  return (
    <section className="mx-auto w-1/2">
      <header className="mb-4 text-center">
        <h2>Invoice Expired!</h2>
      </header>
      <div className="mb-8 flex flex-col gap-4">
        <p>
          This invoice has expired. An invoice is only valid for 15 minutes. You
          can{' '}
          <span
            onClick={onRestartProcess}
            className="cursor-pointer text-primary underline"
          >
            return to the sell page
          </span>{' '}
          if you would like to submit your payment again.
        </p>
        <p>
          If you tried to send a payment, it has not yet been accepted by the
          network. We have not yet received your funds.
        </p>
        <p>
          If we receive it at a later point, we will either process your order
          or contact you to make refund arrangements...
        </p>

        <div>
          <p>
            <span className="font-bold">Order ID: </span>
            {orderId}
          </p>
          <p>
            <span className="font-bold">Invoice ID: </span>
            {invoiceId}
          </p>
        </div>
      </div>

      <div className="flex gap-8">
        <Button
          color="bg-gray-dark"
          fullWidth
          onClick={() => navigate('/dashboard/overview')}
        >
          Back to Overview
        </Button>
        <Button fullWidth onClick={onRestartProcess}>
          Back to Sell
        </Button>
      </div>
    </section>
  );
};
