export const detectViewport = () => {
    let viewport;
    // Check the user's viewport/device
    if (window.innerWidth <= 768) {
        viewport = 'mobile';
    } else {
        viewport = 'desktop';
    }
    // Return the user's viewport/device
    return viewport;
};