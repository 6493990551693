import { ProgressBar } from 'components/ProgressBar';
import { useRef } from 'react';
import { useCountdown } from '../hooks/useCountDown';

type CountDownTimerProps = {
  endDate: string;
  startDate: string;
  showProgressBar: boolean;
  inLineTimer?: boolean;
};

export const CountDownTimer = ({
  endDate,
  startDate,
  showProgressBar = true,
  inLineTimer,
}: CountDownTimerProps) => {
  const { days, hours, minutes, seconds, isComplete, currentTime } =
    useCountdown(new Date(endDate).getTime());
  const startTime = useRef(new Date(startDate).getTime());

  const endTime = new Date(endDate).getTime();
  const diff = endTime - currentTime;
  const range = endTime - startTime.current;

  const percentage = 100 - (100 * diff) / range;
  if (inLineTimer) {
    return (
      <div className="numeric-same-width text-md text-primary font-semibold">
        This code expire within {days > 0 ? `${days}  days` : ''}
        {hours > 0 ? ` ${hours}  hours` : ''}
        {minutes > 0 ? ` ${minutes}  minutes ` : ''}
        {seconds} seconds
      </div>
    );
  } else {
    return (
      <div className="w-full space-y-2 text-center">
        {showProgressBar && (
          <div>
            <h3 className="text-lg font-bold">Expiration Timer</h3>
            <ProgressBar progressPercentage={isComplete ? 100 : percentage} />
          </div>
        )}

        <div className="numeric-same-width text-sm">
          {days}d {hours}hr {minutes}min {seconds}sec
        </div>
      </div>
    );
  }
};
