import { ForgotPasswordDTO } from '../api/forgotPassword';

/**
 * Used to remove the unused option between either `phone_number` or `email` to receive a verificaiton code
 */
export function stripUnusedKey(values: ForgotPasswordDTO): ForgotPasswordDTO {
  const tempValues: ForgotPasswordDTO = { ...values };
  if (values.email === '') {
    delete tempValues.email;
  }

  if (values.phone_number === '') {
    delete tempValues.phone_number;
  }
  return tempValues;
}
