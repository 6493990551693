import { AtmLocation } from '../types';

import logo from 'assets/imgs/unbank-logo-no-bg.svg';
import Button from 'components/Button';
import {
  MapIcon,
  ClockIcon,
  PlusIcon,
  MinusIcon,
} from '@heroicons/react/outline';
import { ArrowLeftIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import { formatOpeningHours } from '../utils';
import { LocationType } from '../types/filterConfig';
import { useNavigate } from 'react-router-dom';
import { Map } from './Map';

type AtmLocationDetailsProps = {
  location: AtmLocation;
  onClick: () => void;
  onGoBack: () => void;
  transactionType: LocationType;
};

export const AtmLocationDetails = ({
  location,
  transactionType,
  onGoBack,
  onClick,
}: AtmLocationDetailsProps) => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        condensed
        onClick={onGoBack}
        size="sm"
        className="mb-4 border border-primary bg-transparent text-primary"
        icon={<ArrowLeftIcon className="h-4" />}
      >
        Back
      </Button>
      <header className="mb-4 flex items-center gap-8">
        <div className="grid h-32 w-32 place-items-center rounded-3xl bg-primary p-4">
          <img src={logo} alt="unbank logo" />
        </div>
        <div>
          <h1>{location.name}</h1>
          <div className="mt-2 text-primary">
            Distance: {location.distance}
            {location.unit}
          </div>
        </div>
      </header>

      <div className="mb-8 flex flex-col gap-8 xl:flex-row xl:gap-28">
        {/* Details */}
        <div className="w-full xl:w-1/2">
          {/* Payment options */}

          {(Boolean(location.is_greendot) || Boolean(location.is_unbank)) && (
            <span className="btn mb-4 bg-light-green text-primary">Cash</span>
          )}
          {/* Buttons */}
          <div className="mb-4 flex gap-4">
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                size="sm"
                className="w-2/5"
                icon={<MapIcon className="h-6" />}
              >
                Directions
              </Button>
            </a>
          </div>

          {/* Address */}
          <div className="flex items-center gap-4 border-b border-t border-tw-gray-200 py-4">
            <div className="rounded-full bg-light-green p-4">
              <LocationMarkerIcon className="h-8 text-primary" />
            </div>
            {location.street_address}, {location.city}, {location.state}{' '}
            {location.zip_code}, {location.country}
          </div>

          {/* Opening Hours */}
          <div className="flex items-start gap-4 border-b border-tw-gray-200 py-4">
            <div className="rounded-full bg-light-green p-4">
              <ClockIcon className="h-8 text-primary" />
            </div>
            <ul className="mt-4 w-full pr-4">
              {Object.keys(location.data.operational_hours).map((day) => (
                <li key={day} className="flex">
                  <span className="capitalize">{day}</span>{' '}
                  <span className="ml-auto font-light">
                    {' '}
                    {formatOpeningHours(location.data.operational_hours[day])}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Map */}
        <div className="h-[370px] w-full xl:h-auto xl:w-1/2">
          <Map locations={[{ ...location }]} />
        </div>
      </div>
      {transactionType === 'both' ? (
        <div className="mt-4 flex flex-col justify-center gap-4 lg:flex-row">
          {Boolean(location.is_sell) && (
            <Button
              onClick={() =>
                navigate(`/dashboard/sell?location=${location.entity_id}`)
              }
              icon={<MinusIcon className="h-6" />}
            >
              Sell
            </Button>
          )}
          {Boolean(location.is_buy) && (
            <Button
              onClick={() => navigate(`/dashboard/buy`)}
              icon={<PlusIcon className="h-6" />}
            >
              Buy
            </Button>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          {' '}
          <Button
            className="capitalize"
            icon={
              transactionType === 'buy' ? (
                <PlusIcon className="h-6" />
              ) : (
                <MinusIcon className="h-6" />
              )
            }
            onClick={onClick}
          >
            {transactionType}
          </Button>
        </div>
      )}
    </div>
  );
};
