import logo from 'assets/imgs/unbank-logo.svg';

const Header = () => {
  return (
    <header className="sticky top-0 z-30 w-full bg-white  px-8 shadow-md">
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <a href="https://unbankworld.com/">
          <div className="flex items-center space-x-2 text-2xl font-semibold text-primary">
            <img src={logo} className="h-7" alt="Unbank Logo" />
            <h2>UNBANK</h2>
          </div>
        </a>
        <nav className="">
          <ul className="flex space-x-8 py-6 text-black">
            <li>
              <a href="https://unbankworld.com/about-us/">About</a>
            </li>
            <li>
              <a href="https://unbankworld.com/contact-us/">Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
