import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { UpdateAccountInfoResponse } from '../types/updateAccountInfoResponse';

export type AccountInfoDTO = {
  firstname: string;
  lastname: string;
  middlename: string;
  email: string;
  phone_number: string;
  ssn: string;
  venmo: {
    phone_number: string;
    currency: string;
  }
  paypal: {
    email: string;
    currency: string;
  }
};

const updateAccountInfo = async (credentials: Partial<AccountInfoDTO>) => {
  const resp = await axios.post<UpdateAccountInfoResponse>(
    '/api/account/update',
    {
      ...credentials,
    }
  );
  return resp.data;
};


export const useUpdateAccountInfo = (
  onSuccess: OnSuccessCallback<UpdateAccountInfoResponse>,
  successNotificationText?: string
) => {
  return useUpdateApi(updateAccountInfo, {
    onSuccess,
    successNotificationText: successNotificationText,
  });
};
