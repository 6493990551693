export const formatTime = (time: string) => {
  const hours = time.split(':')[0];
  if (parseInt(hours, 10) < 12) {
    return `${time} am`;
  }
  return `${time} pm`;
};

export const formatOpeningHours = (hours: string) => {
  const [opening, closing] = hours.split('-');
  return `${formatTime(opening)} - ${formatTime(closing)}`;
};
