import LoginForm from '../components/LoginForm';
import logo from 'assets/imgs/unbank-logo.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import storage from 'utils/storage';
import { useAuth } from '../providers';

const LoginPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Clear auth data when user first loads login

  useEffect(() => {
    storage.clearAuthData();
  }, []);

  const handleSuccess = async () => {
    if (!user?.two_factor) {
      // Login as normal with no 2FA

      navigate('/dashboard/overview');
    } else {
      storage.setTwoFA(false);
      navigate('/auth/2fa-verify');
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <div className="mx-8 flex h-full min-h-screen flex-col items-center justify-center lg:mx-auto">
      <div className="mb-8 rounded-lg bg-primary p-2">
        <a href="https://unbankworld.com/">
          <img src={logo} className="h-24 lg:h-32 " alt="Unbank Logo" />
        </a>
      </div>
      <LoginForm onSuccess={handleSuccess} />

      <footer>
        <div className="text-gray-600">
          Copyright &copy;Unbank {currentYear}
        </div>
      </footer>
    </div>
  );
};

export default LoginPage;
