import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Barcode } from 'components/Barcode';
import { Transaction } from 'modules/transactions';
import logo from 'assets/imgs/unbank-logo.svg';
import React from 'react';
import { BuyCryptoReceipt } from '../../types/buyCryptoReceipt';
import './GreendotReciept.css';

interface GreendotReceiptProps {
  transaction?: Transaction;
  showBarcode?: boolean;
  greendotData?: BuyCryptoReceipt;
  steps: GreendotSteps;
}

interface GreendotStep {
  content: JSX.Element;
}
interface GreendotSteps {
  customer: GreendotStep[];
  cashier: GreendotStep[];
}
export const GreendotReceipt = React.forwardRef<
  HTMLDivElement,
  GreendotReceiptProps
>((props, ref) => (
  <div ref={ref} className="my-10">
    <header id="greendot-header" className="mx-3">
      <div className="flex justify-center items-center space-x-2 text-3xl text-secondary my-10">
        <img src={logo} className="h-8" alt="Unbank Logo" />
        <h2 className="font-semibold">Unbank</h2>
      </div>
    </header>
    <main id="print-container">
      <div className="mx-3 mb-4">
        {/* Greendot Steps */}
        <div className="w-3/4 my-auto">
          <h3 className="text-2xl font-semibold">
            {' '}
            Add Cash to Unbank Account{' '}
          </h3>
          <p className="mb-4">
            {' '}
            Follow these steps to add cash to your unbank account!
          </p>
          {props.steps.customer.map((step, index) => {
            return (
              <div className="my-2">
                <h4 className="font-semibold text-primary text-md">
                  {' '}
                  Step {index + 1}
                </h4>
                <span className="text-sm">{step.content}</span>
              </div>
            );
          })}

          <p className="text-sm font-bold mb-8">
            Please note that the barcode expires on{' '}
            {props.transaction && (
              <span className="numeric-same-width text-lg text-primary font-bold">
                {new Date(
                  props.transaction.gd_expiry_date
                ).toLocaleDateString()}
              </span>
            )}
            {props.greendotData && (
              <span className="numeric-same-width text-lg text-primary font-bold">
                {new Date(
                  props.greendotData.data.gd_barcode_id_expiration
                ).toLocaleDateString()}
              </span>
            )}
            {' . '}
            After it expires please generate a new barcode
          </p>
        </div>
      </div>
      {/* Fraud Warning */}
      <article className="bg-red-100 rounded px-5 py-5 text-red-500  gap-6 flex justify-center items-center">
        <ExclamationCircleIcon className="h-[50px]" />
        <p className="font-lg">
          Only use this barcode to add money to your own account. If you recieve
          it from someone else, it is a scam!
        </p>
      </article>

      <div id="barcode-container" className="mx-3 my-4 flex justify-between">
        <section>
          <h3 className="text-xl font-semibold text-primary">
            {' '}
            Cashier Instructions{' '}
          </h3>
          {props.steps.cashier.map((step, index) => (
            <div className="my-2">
              <h4 className="font-semibold text-primary text-md">
                {' '}
                Step {index + 1}
              </h4>
              <span className="text-sm">{step.content}</span>
            </div>
          ))}
        </section>
        {/* Barcode data */}
        {/* If a transaction is passed in, use transaction fields */}
        <div>
          {props.greendotData && (
            <div id={`${props.showBarcode ? '' : 'print-hide'}`}>
              <Barcode
                value={props.greendotData.data.gd_barcode_id}
                barcodeID="greendotBarcode"
              />
            </div>
          )}
          {props.transaction && (
            <div id={`${props.showBarcode ? '' : 'print-hide'}`}>
              <Barcode
                value={props.transaction.gd_barcode_id}
                barcodeID="greendotBarcode"
              />
            </div>
          )}
        </div>
      </div>
    </main>
    <footer id="greendot-footer" className="py-3 px-3">
      <p>Copyright © 2022 Unbank</p>
      <p id="greendot-footer-support">
        Any Issues? Contact us at support@unbank.world
      </p>
    </footer>
  </div>
));
