import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { BankCard } from '../types/BankCard';

type MakeCardDefaultResponse = {
  status: string;
  message: string;
  data: BankCard;
};

const makeCardDefault = async (cardRef: string) => {
  const resp = await axios.post<MakeCardDefaultResponse>(
    `/api/account/bank/card/${cardRef}`,
    { is_default: true }
  );
  return resp.data;
};

export const useMakeCardDefault = (
  onSuccess: OnSuccessCallback<MakeCardDefaultResponse>
) => {
  return useUpdateApi(makeCardDefault, {
    onSuccess,
    successNotificationText: 'Bank card updated successfully',
  });
};
