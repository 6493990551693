import { Checkbox } from '@mantine/core';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import arrow_right from 'assets/icons/arrow_right.svg';
import Button from 'components/Button';
import TextField from 'components/TextField';
import storage from 'utils/storage';
import {
  RegisterPhoneCredentials,
  useRegisterPhone,
} from '../api/registerPhone';
import { ApiResponse } from '../types/register';
import PasswordField from 'components/PasswordField';
import { BROWSER_OBJECT } from 'modules/sift/util/constants';
import { trackSignUp } from 'modules/sift/api/siftEvents';
import { useVerify } from '../providers/verification';

type RegistrationFormProps = {
  onRegister: (phoneNumber: string, password: string) => void;
};

const RegistrationForm = ({ onRegister }: RegistrationFormProps) => {
  const { location } = useVerify();

  const onSuccess = (data: ApiResponse) => {
    storage.setToken(data.unbank.token);
    onRegister(values.phone_number, values.password);

    // Keep track of login event
    if (data) {
      const siftCredentials = {
        $type: '$create_account',
        $api_key: process.env.REACT_APP_SIFT_BEACON_KEY || '',
        $user_email: data.user.email,
        $session_id: data.unbank.token,
        $user_id: data.user.id.toString(),
        $verification_phone_number: data.user.phone_number,
        $name: data.user.name,
        $phone: data.user.phone_number,
        $browser: BROWSER_OBJECT,
        $location: location?.state!,
      };
      trackSignUp(siftCredentials);
    }
  };
  const apiQuery = useRegisterPhone(onSuccess);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<RegisterPhoneCredentials & { acceptTerms: boolean }>({
      initialValues: {
        firstname: '',

        lastname: '',
        phone_number: '',
        password: '',
        password_confirmation: '',
        acceptTerms: false,
      },
      validationSchema: Yup.object({
        firstname: Yup.string().required('First Name is a required field.'),

        lastname: Yup.string().required('Last Name is a required field.'),
        phone_number: Yup.string()
          .required('Phone Number is a required field.')
          .min(10, 'phone should be at least 10 characters long.'),
        password: Yup.string()
          .required('Password is a required field.')
          .min(8)
          .matches(/^.*[0-9].*$/, 'Needs at least one number'),
        password_confirmation: Yup.string()
          .required('Confirm Password is a required field')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        acceptTerms: Yup.boolean().equals(
          [true],
          'You must agree to the terms of service to create an account'
        ),
      }),
      onSubmit: (values) => {
        apiQuery.update(values);
      },
    });

  return (
    <>
      <header className="mb-6">
        <h2 className="text-3xl font-bold">Create Account</h2>
        <p className="text-base">
          Fill out the Information below to create an Account
        </p>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="space-y-5">
          <div className="grid gap-8 md:grid-cols-2">
            <TextField
              label="First Name"
              placeholder="First Name"
              name="firstname"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.firstname && touched.firstname && errors.firstname}
              data-testid="register-form-first-name-input"
            />

            <TextField
              label="Last Name"
              placeholder="Last Name"
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lastname && touched.lastname && errors.lastname}
              data-testid="register-form-last-name-input"
            />
          </div>
          <TextField
            label="Phone Number"
            placeholder="Phone Number"
            name="phone_number"
            type="tel"
            value={values.phone_number}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.phone_number && touched.phone_number && errors.phone_number
            }
            data-testid="register-form-phone-number-input"
          />
          <PasswordField
            label="Password"
            placeholder="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password && errors.password}
            data-testid="register-form-password-input"
          />
          <PasswordField
            label="Confirm Password"
            placeholder="Confirm Password"
            name="password_confirmation"
            value={values.password_confirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.password_confirmation &&
              touched.password_confirmation &&
              errors.password_confirmation
            }
            autoComplete="off"
            data-testid="register-form-confirm-password-input"
          />
          <Checkbox
            name="acceptTerms"
            classNames={{ input: 'border-primary' }}
            checked={values.acceptTerms}
            onBlur={handleBlur}
            onChange={handleChange}
            styles={{ label: { fontSize: '18px' } }}
            label={
              <span>
                By using Unbank, you agree to our{' '}
                <a
                  href="https://unbank.com/terms-of-service/"
                  className="text-primary hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </span>
            }
            data-testid="register-form-tos-checkbox"
          />
          <div className="!-mt-0 text-red-500">
            {errors.acceptTerms && touched.acceptTerms && errors.acceptTerms}
          </div>
          <div className=" text-center">
            <Button
              type="submit"
              iconPosition="right"
              icon={<img src={arrow_right} className="h-4" alt="right arrow" />}
              loading={apiQuery.isLoading}
              data-testid="register-form-create-account-button"
            >
              Create account
            </Button>
            <p className="mt-8 text-base">
              Already have an account?{' '}
              <Link to="/auth/login" className="text-primary hover:underline">
                Login
              </Link>
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegistrationForm;
