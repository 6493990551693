import { GlobeIcon } from '@heroicons/react/outline';
import {
  ExclamationCircleIcon,
  ChatIcon,
  MailIcon,
} from '@heroicons/react/solid';

interface SupportItem {
  title: string;
  body: any;
  icon: JSX.Element;
  link: string;
}
export const SupportPage = () => {
  const items: SupportItem[] = [
    {
      title: 'Help Center',
      body: `We love to hear your feedback on the application. Get in touch
      with us!`,
      icon: <ExclamationCircleIcon className="h-7" />,
      link: 'https://help.unbankworld.com/hc/845661299',
    },
    {
      title: 'Contact Us',
      body: `We love to hear your feedback on the application. Get in touch with us!`,
      icon: <ChatIcon className="h-7" />,
      link: 'https://help.unbankworld.com/hc/845661299',
    },
    {
      title: 'Email',
      body: (
        <p>
          Feel free to reach out to us at{' '}
          {
            <a
              className="font-bold text-primary"
              href="mailto:support@unbankworld.com"
            >
              support@unbank.world
            </a>
          }{' '}
          for any queries!
        </p>
      ),
      icon: <MailIcon className="h-7" />,
      link: 'mailto:support@unbankworld.com',
    },
    {
      title: 'Website',
      body: (
        <p>
          {' '}
          Check out our website{' '}
          <a className="text-primary" href="https://www.unbankworld.com">
            www.unbankworld.com{' '}
          </a>
        </p>
      ),
      icon: <GlobeIcon className="h-7" />,
      link: 'https://unbankworld.com',
    },
  ];
  return (
    <div className="dashboard-page min-h-[80vh]">
      <h1>Unbank Support</h1>

      {items.map((item, index) => (
        <a href={item.link} target="help">
          <article className="flex justify-between my-5 items-center  rounded hover:bg-light-green px-5 py-6 hover:cursor-pointer">
            <div>
              <h2 className="text-primary text-lg"> {item.title} </h2>
              <p> {item.body}</p>
            </div>
            <div className="text-primary rounded-full bg-light-green p-2">
              {item.icon}
            </div>
          </article>
        </a>
      ))}
    </div>
  );
};
