import { Loader } from '@mantine/core';
import exclamation from 'assets/icons/exclamation.svg';

type VerificationBannerProps = {
  type: 'email' | 'phone';
  contactDetail: string;
  onRefresh: () => void;
  isRefreshing: boolean;
};

const VerificationBanner = ({
  type,
  contactDetail,
  onRefresh,
  isRefreshing,
}: VerificationBannerProps) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-md bg-light-green py-20">
      <img
        src={exclamation}
        className="h-[60px] w-auto"
        alt="exclamation mark"
      />
      <h2 className="my-4 text-3xl font-bold">Verification</h2>
      <p>Please enter the verification code we sent via </p>
      <p>
        {type === 'email' ? type : 'text message'} to{' '}
        <span className="font-semibold text-primary">{contactDetail}</span>
      </p>
      <div className="mt-8">
        {isRefreshing ? (
          <Loader size="md" color="green" />
        ) : (
          <button
            onClick={onRefresh}
            type="button"
            className="text-primary underline"
          >
            Resend verification code
          </button>
        )}
      </div>
    </div>
  );
};

export default VerificationBanner;
