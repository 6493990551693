import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { Transaction } from 'modules/transactions';
import { axios } from 'lib/axios';
import { BuyCryptoReceipt } from '../types/buyCryptoReceipt';



export type BuyCryptoDTO = {
  amount: number;
  destination: string;
  method: string;
  speed: string;
  crypto: string;
  "3d_secure"?: {
    authenticationValue: string;
    eci: string;
    protocolVersion: string;
    dsTransId: string;
    acsTransId: string;
    cardToken: string;
    scaIndicator: boolean;
  }

  // Greendot fields 
  retailer_key: string;
  entity_id: string;


};

export interface ConvertCashDTO {
  balance: string;
  order_id: string;
}

const convertCash = async (credentials: ConvertCashDTO) => {
  const resp = await axios.post<Transaction>('/api/convert/cash', {
    ...credentials
  });

  return resp.data;
}
const buyCrypto = async (credentials: BuyCryptoDTO) => {
  const resp = await axios.post<BuyCryptoReceipt>('/api/buy', {
    ...credentials,
  });
  return resp.data;
};



export const useConvertCash = (
  onSuccess: OnSuccessCallback<Transaction>
) => {
  return useUpdateApi(convertCash, { onSuccess })
}

export const useBuyCrypto = (
  onSuccess: OnSuccessCallback<BuyCryptoReceipt>
) => {
  return useUpdateApi(buyCrypto, { onSuccess });
};
