import React,  { useContext } from 'react';
import AccountService from 'modules/account/services/AccountService';
import { axios } from 'lib/axios';

const accountServiceInstance = new AccountService(axios);

const AccountContext = React.createContext<AccountService>(accountServiceInstance);

// Export the hook
export const useAccountService = () => useContext(AccountContext);

export const AccountProvider: React.FC= ({ children }) => {
    return <AccountContext.Provider value={accountServiceInstance}> { children }</AccountContext.Provider>;
};

