import type { AxiosInstance } from "axios";

export default class AccountService {
    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    fetchAccountData() {
        return this.axios.get("/api/account/data");
    }
    
    fetchWallets() {
        return this.axios.get("/api/account/wallet");
    }

    fetchWidgetUrl(userGuid : string = "") {
        return this.axios.post("/api/account/transaction/ach/get_mx_connect_widget", {
            userGuid
        })
            .then(({ data }) => data)
            .catch(console.log);
    }

    fetchConnectedBankAccounts(userGuid: string) {
        return this.axios.post("/api/account/transaction/ach/listAllConnectedAccountByUser", {
            userGuid
        })
            .then(({ data }) => data)
            .catch(console.log);
    }

    fetchNotificationSettings(){
        return this.axios.get("/api/account/notification/settings");
    }

    updateNotificationSettings() {
        return this.axios.post("/api/account/notification/settings");
    }

    updateAccountInfo() {
        return this.axios.post("/api/account/update");
    }

    removeCashOutMethod(cashoutMethod: string) {
        return this.axios.delete(`api/account/remove/${cashoutMethod}`);
    }

}