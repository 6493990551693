import { FilterIcon, SearchIcon, XCircleIcon } from '@heroicons/react/solid';
import TextField from 'components/TextField';
import { useState } from 'react';
import { TransactionsTable } from '../components/TransactionsTable';
import { Transition } from '@headlessui/react';
import { useDebounce } from 'common/hooks';
import { TransactionsFilter } from '../components/TransactionsTable/TransactionsFilter';
import {
  SearchableTransactionProperty,
  TransactionFilterConfig,
} from '../types/TransactionFilterConfig';
import { useNavigate } from 'react-router-dom';
import Transitions from 'components/Transitions';

export const TransactionsPage = () => {
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [filters, setFilters] = useState<TransactionFilterConfig>({});

  const updateSearchFilter = (
    searchFieldName: SearchableTransactionProperty
  ) => {
    setFilters((prev) => ({ ...prev, searchTerm: search, searchFieldName }));
  };

  const handleSelectFilters = (filters: TransactionFilterConfig) => {
    setFilters((prev) => ({ ...prev, ...filters }));
  };

  useDebounce(
    () => {
      if (search.length === 0) clearSearch();
    },
    300,
    [search]
  );

  const toggleShowFilters = () => setShowFilters((prev) => !prev);

  const clearSearch = () =>
    setFilters((prev) => ({
      ...prev,
      searchTerm: undefined,
      fieldName: undefined,
    }));

  const navigate = useNavigate();
  return (
    <div className="dashboard-page">
      <header className="mb-12 flex justify-between">
        <div className="">
          <h2 className="mb-2 text-secondary">Transactions</h2>
          <p>Here is a list of your most recent transactions.</p>
        </div>
        <button
          className="h-12 px-6 py-2 text-sm rounded-lg bg-primary text-white border"
          onClick={() => navigate('/dashboard/transactions/limits')}
        >
          {' '}
          View Limits
        </button>
      </header>
      <div className="mb-8 flex items-center justify-end gap-4">
        <div className="relative w-1/3">
          <TextField
            onFocus={() => setShowSearchFilters(true)}
            onBlur={() => setShowSearchFilters(false)}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<SearchIcon className="h-5 text-gray-dark" />}
            rightSection={
              !!search.length && (
                <XCircleIcon
                  className="h-5 cursor-pointer text-gray-dark transition-colors hover:text-primary"
                  onClick={() => setSearch('')}
                />
              )
            }
            placeholder="Order ID, amount"
          />
          <Transition
            show={showSearchFilters}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="rounuded-sm absolute left-0 mt-2 flex w-full flex-col justify-start bg-white p-2 shadow-lg">
              <button
                onClick={() => updateSearchFilter('order_id')}
                className="p-2 text-left hover:bg-light-green hover:text-primary"
              >
                In Order ID: {search}
              </button>

              <button
                onClick={() => updateSearchFilter('amount')}
                className="p-2 text-left hover:bg-light-green hover:text-primary"
              >
                In Amount (USD): {search}
              </button>
            </div>
          </Transition>
        </div>
        <div className="relative">
          <button
            className="block self-stretch rounded-md bg-gray-medium p-4"
            onClick={toggleShowFilters}
          >
            <FilterIcon className="h-6 text-gray-dark" />
          </button>
          <Transitions.AppearDown reveal={showFilters}>
            <TransactionsFilter
              initialFilters={filters}
              onSelectFilters={handleSelectFilters}
              onClose={() => setShowFilters(false)}
            />
          </Transitions.AppearDown>
        </div>
      </div>
      <TransactionsTable filters={filters} />
    </div>
  );
};
