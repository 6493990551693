import TextField from 'components/TextField';
import { capitalizeFirstLetter } from 'utils/formatters';
import {
  AccountInfoDTO,
  useUpdateAccountInfo,
} from 'modules/account/api/updateAccount';
import { UpdateAccountInfoResponse } from 'modules/account/types/updateAccountInfoResponse';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import { useAuth } from 'modules/auth';

interface ModifyMethodFormProps {
  cashoutMethod: 'paypal' | 'venmo' | string;
  action: 'Add' | 'Edit';
}
export const ModifyMethodForm = ({
  cashoutMethod,
  action,
}: ModifyMethodFormProps) => {
  // Formik Setup

  const { setUser } = useAuth();

  const onSuccess = (data: UpdateAccountInfoResponse) => {
    setUser(data.data);
    window.location.reload();
  };
  const { handleChange, handleSubmit } = useFormik<{ contactField: string }>({
    initialValues: {
      contactField: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      console.log(values);
      let cashoutObj: Partial<AccountInfoDTO> = {};
      if (cashoutMethod === 'paypal') {
        cashoutObj = {
          paypal: {
            email: values.contactField,
            currency: 'USD',
          },
        };
      } else {
        cashoutObj = {
          venmo: {
            phone_number: values.contactField,
            currency: 'USD',
          },
        };
      }
      update(cashoutObj);
    },
  });

  const { isLoading, update } = useUpdateAccountInfo(onSuccess);
  const contactField = cashoutMethod === 'paypal' ? 'email' : 'phone_number';
  const fieldName = contactField === 'email' ? 'Email Address' : 'Phone Number';
  return (
    <form className="mt-8" onSubmit={handleSubmit}>
      <TextField
        name="contactField"
        onChange={handleChange}
        label={fieldName}
        placeholder={`Your ${fieldName.toLocaleLowerCase()}`}
      />
      <Button size="sm" className="my-3" loading={isLoading}>
        {capitalizeFirstLetter(action)}
        {` ${cashoutMethod} account`}
      </Button>
    </form>
  );
};
