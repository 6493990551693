import {
  BellIcon,
  CurrencyDollarIcon,
  MailIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/outline';

import { NotificationType } from '../types/notifications';

interface NotificationIconProps {
  notificationType: string;
}
export const NotificationIcon = ({
  notificationType,
}: NotificationIconProps) => {
  const iconStyles = 'h-6 w-6 my-auto text-white';
  switch (notificationType) {
    case NotificationType.MESSAGE_NOTIFICATION:
      return <BellIcon className={iconStyles} />;
    case NotificationType.VERIFY_EMAIL_NOTIFICATION:
      return <MailIcon className={iconStyles} />;

    case NotificationType.CRYPTO_ALERT:
      return <CurrencyDollarIcon className={iconStyles} />;

    case NotificationType.SECURITY_ALERT:
      return <ShieldExclamationIcon className={iconStyles} />;
  }

  return <BellIcon className="h-6 w-6 my-auto text-white" />;
};
