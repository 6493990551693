import { axios } from 'lib/axios';
import { useEffect, useRef, useState } from 'react';

type UseFetchApiQuery<T> = {
  hasError: boolean;
  isLoading: boolean;
  data: T | null;
};

// type UseApiHook<T> =
//  (updateFn: (data: T) => ApiResponse) => UseFetchApiQuery;

// const useFetchApi: UseApiHook = (updateFn) => {

export type OnSuccessCallback<TResponse = unknown> = (data: TResponse) => void;

type UseFetchApiOptions<TResponse> = {
  onSuccess?: OnSuccessCallback<TResponse>;
  successNotificationText?: string; // used for if we want to show success notifications
};

function useFetchApi<T>(
  path: string,
  options?: UseFetchApiOptions<T>
): UseFetchApiQuery<T> {
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetch = async () => {
      try {
        const resp = await axios.get<T>(path);
        if (!isMounted.current) return;
        setData(resp.data);

        if (options) {
          const { onSuccess } = options;
          if (onSuccess) {
            onSuccess(resp.data);
          }
        }
      } catch (err) {
        setHasError(true);
      } finally {
        if (!isMounted.current) return;
        setIsLoading(false);
      }
    };
    fetch();
    return () => {
      isMounted.current = false;
    };
  }, [path]);

  return { isLoading, hasError, data };
}

export default useFetchApi;
