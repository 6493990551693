import {
  SelectWithCards,
  SelectWithCardsOption,
} from 'components/SelectWithCards';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

import venmoIcon from 'assets/icons/venmoIcon.png';
import cashIcon from 'assets/icons/cashicon.png';
import { SupportedSellMethod } from 'common/types';
import { useState } from 'react';
import { getSellMethod } from 'common/utils';
import { useAuth } from 'modules/auth';
import { CashoutMethod } from 'common/types/user';
import { ModifyMethodForm } from 'modules/account/components/CashoutMethods/ModifyMethodForm';
import { capitalizeFirstLetter } from 'utils/formatters';

import PaypalLogo from 'assets/icons/paypalIcon.png';
import { useLimits } from 'modules/transactions/providers/limits';
import ResponsiveModal from 'components/Modal';

type SelectSellMethodProps = {
  onSelectSellMethod: (method: string) => void;
};

export const SelectSellMethod = ({
  onSelectSellMethod,
}: SelectSellMethodProps) => {
  // Component State
  const [selectedSellMethod, setSelectedSellMethod] = useState('');
  const [opened, setOpened] = useState(false);

  const { user } = useAuth();
  const { sellLimits } = useLimits();

  const cashoutMethods = user?.cashout_methods;
  const handleClick = () => {
    // Convert type
    const cashoutMethod = selectedSellMethod as CashoutMethod;

    // Prompt user to add sell method if it isn't added
    if (cashoutMethods && !cashoutMethods[cashoutMethod]) {
      console.log(`${cashoutMethod} has not been added`);
      setOpened(true);
    } else {
      onSelectSellMethod(selectedSellMethod);
    }
  };

  const paymentMethods: SelectWithCardsOption[] = [
    {
      label: getSellMethod(SupportedSellMethod.IN_PERSON),
      value: SupportedSellMethod.IN_PERSON,
      body: `Minimum $${sellLimits?.ncrpay360.min_per_transaction}, Maximum $${sellLimits?.ncrpay360.max_per_transaction} per transaction`,
      limit: `${sellLimits?.ncrpay360.daily_limit}`,
      src: cashIcon,
    },
    {
      label: 'Paypal',
      value: SupportedSellMethod.PAYPAL,
      body: `Minimum $${sellLimits?.ncrpay360.min_per_transaction}, Maximum $${sellLimits?.ncrpay360.max_per_transaction} per transaction`,
      limit: `${sellLimits?.ncrpay360.daily_limit}`,
      src: PaypalLogo,
    },
    {
      label: 'Venmo',
      value: SupportedSellMethod.VENMO,
      body: `Minimum $${sellLimits?.ncrpay360.min_per_transaction}, Maximum $${sellLimits?.ncrpay360.max_per_transaction} per transaction`,
      limit: `${sellLimits?.ncrpay360.daily_limit}`,
      src: venmoIcon,
    },
  ];

  const addMethodTitle = (
    <div>
      <h2 className="text-left">
        Add Cashout Method - {capitalizeFirstLetter(selectedSellMethod)}
      </h2>
      <p className="text-sm text-left">
        Enter the details for your your {selectedSellMethod} account for cashout
        by providing your contact information linked to {selectedSellMethod}
      </p>
    </div>
  );
  return (
    <div className="mt-10 text-center">
      <h3 className="my-8 text-2xl font-semibold">
        Choose your cashout method
      </h3>
      <ResponsiveModal
        size="50%"
        title={addMethodTitle}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <ModifyMethodForm cashoutMethod={selectedSellMethod} action={'Add'} />
      </ResponsiveModal>
      <SelectWithCards
        options={paymentMethods}
        onSelectOption={setSelectedSellMethod}
      />
      <p className="mb-14">
        If you don't have any cashout methods, you can go ahead and{' '}
        <Link className="text-primary underline-offset-1 hover:underline" to="">
          add a cashout method
        </Link>
      </p>
      <Button onClick={handleClick} disabled={selectedSellMethod === undefined}>
        Proceed
      </Button>
    </div>
  );
};
