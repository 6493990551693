import { AtmLocation } from '../types';
import logo from 'assets/imgs/unbank-logo-no-bg.svg';

type AtmLocationCardProps = {
  location: AtmLocation;
  onViewLocation: (location: AtmLocation) => void;
};

export const AtmLocationCard = ({
  location,
  onViewLocation,
}: AtmLocationCardProps) => {
  const { is_buy, is_sell } = location;
  const tag = is_buy && is_sell ? 'Both' : is_buy ? 'Buy' : 'Sell';
  return (
    <button
      onClick={() => onViewLocation(location)}
      className="relative flex cursor-pointer items-center rounded-md bg-white py-2 pl-6 pr-2 shadow-custom ring-offset-2 transition-all ease-linear hover:bg-light-green focus:outline-none focus:ring focus:ring-primary"
    >
      {/* <div className="flex"> */}
      <div className="mr-6 flex h-16 w-16 shrink-0 items-center justify-center rounded-xl bg-primary p-2">
        <img src={logo} alt="unbank logo" />
      </div>
      {/* right */}
      <div className="flex grow flex-col text-left">
        {tag === 'Both' ? (
          <div className="absolute right-2 top-2 self-end flex gap-3">
            <div
              className={
                'bg-light-green text-primary  rounded-sm px-6 py-1  text-sm'
              }
            >
              Buy
            </div>
            <div
              className={
                'bg-light-blue text-secondary rounded-sm px-6 py-1  text-sm'
              }
            >
              Sell
            </div>
          </div>
        ) : (
          <div
            className={`${
              tag === 'Buy'
                ? 'bg-light-green text-primary'
                : 'bg-light-blue text-secondary'
            } absolute right-2 top-2 self-end rounded-sm px-6 py-1  text-sm`}
          >
            {tag}
          </div>
        )}

        <div className="mb-1 mt-6 text-lg font-bold">{location.name}</div>
        <div className="pr-4 text-sm">
          {location.street_address}, {location.city}, {location.state}{' '}
          {location.zip_code}, {location.country}
        </div>
        <div className="text-sm text-primary">
          Distance {location.distance}
          miles
        </div>
      </div>
    </button>
    // </div>
  );
};
