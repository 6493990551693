import { Stepper } from '@mantine/core';
import { StyledStepper } from 'components/StyledStepper';
import { useMemo, useState } from 'react';
import {
  SelectWithCards,
  SelectWithCardsOption,
} from 'components/SelectWithCards';
import bankCardIcon from 'assets/icons/credit-card.svg';
import cashIcon from 'assets/icons/cash.svg';
import { SupportedPaymentMethod } from 'common/types';
import { ModalSectionHeader } from './ModalSectionHeader';
import { BankCardForm } from './BankCardForm';
import { getPaymentMethod } from 'common';
import { CardDetails } from '../api/addBankCard';
import { BillingInfoForm } from './BillingInfoForm';
import { VerifyCardForm } from './VerifyCardForm';
import { BankCard } from '../types/BankCard';
import { useGetClientIP } from '../api/getClientIP';
import { useGetAccountInfo } from 'modules/account';
import { useAuth } from 'modules/auth';

const paymentMethodsFull: SelectWithCardsOption[] = [
  {
    label: getPaymentMethod(SupportedPaymentMethod.BANK_CARD),
    value: SupportedPaymentMethod.BANK_CARD,
    src: bankCardIcon,
  },
  {
    label: getPaymentMethod(SupportedPaymentMethod.CASH),
    value: SupportedPaymentMethod.CASH,
    src: cashIcon,
  },
  {
    label: getPaymentMethod(SupportedPaymentMethod.UNBANK_CASH),
    value: SupportedPaymentMethod.UNBANK_CASH,
    src: cashIcon,
  },
];

const getHeaderTitle = (active: number) => {
  switch (active) {
    case 0:
      return 'Payment Info';
    case 1:
      return 'Billing Information';
    case 2:
      return 'Verify Payment Method';
    default:
      return 'Payment Info';
  }
};

type AddPaymentMethodModalProps = {
  onAddCard: (card: BankCard) => void;
  methodFilter?: SupportedPaymentMethod;
};

export const AddPaymentMethodModal = ({
  onAddCard,
  methodFilter,
}: AddPaymentMethodModalProps) => {
  const [active, setActive] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | undefined
  >(methodFilter ?? undefined);
  const [cardDetails, setCardDetails] = useState<CardDetails>();
  const [cardRef, setCardRef] = useState('');
  const [clientIP, setClientIP] = useState('');

  const { setUser } = useAuth();
  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );

  const paymentMethods: SelectWithCardsOption[] = useMemo(
    () =>
      methodFilter
        ? paymentMethodsFull.map((method) => ({
            ...method,
            disabled: method.value !== methodFilter,
          }))
        : paymentMethodsFull,
    [methodFilter]
  );

  useGetClientIP((data) => {
    setClientIP(data);
  });

  const nextStep = () => {
    setActive((current) => (current < 2 ? current + 1 : current));
  };

  const handleSelectPaymentMethod = (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleAddCardInfo = (cardDetails: CardDetails) => {
    setCardDetails(cardDetails);
    nextStep();
  };

  const handleAddBillingInfo = (cardRef: string) => {
    setCardRef(cardRef);
    nextStep();
  };

  const handleAddCardSuccess = (card: BankCard) => {
    fetchUserInfo({});
    onAddCard(card);
  };

  return (
    <div className=" px-8">
      <h2 className="mb-8 text-center">Add Payment Method</h2>

      <StyledStepper
        active={active}
        classNames={{
          stepLabel:
            'absolute left-0 right-0 top-[110%] text-sm text-center text-xs ',
        }}
      >
        <Stepper.Step label="Payment Info">
          <ModalSectionHeader
            position={active + 1}
            title={getHeaderTitle(active)}
          />
          <SelectWithCards
            initialSelection={selectedPaymentMethod}
            options={paymentMethods}
            onSelectOption={handleSelectPaymentMethod}
          />
          {selectedPaymentMethod === SupportedPaymentMethod.BANK_CARD && (
            <BankCardForm onSubmit={handleAddCardInfo} />
          )}
        </Stepper.Step>

        <Stepper.Step label="Billing Information">
          <ModalSectionHeader
            position={active + 1}
            title={getHeaderTitle(active)}
          />
          {cardDetails && (
            <BillingInfoForm
              clientIp={clientIP}
              cardDetails={cardDetails}
              onSubmit={handleAddBillingInfo}
            />
          )}
        </Stepper.Step>

        <Stepper.Step label="Verify Payment Method">
          <ModalSectionHeader
            position={active + 1}
            title={getHeaderTitle(active)}
          />
          <VerifyCardForm
            cardReference={cardRef}
            onSuccess={handleAddCardSuccess}
          />
        </Stepper.Step>
      </StyledStepper>
    </div>
  );
};
