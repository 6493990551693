import { TextInput, TextInputProps } from '@mantine/core';

type TextFieldProps = TextInputProps & React.RefAttributes<HTMLInputElement>;

const TextField = ({ ...props }: TextFieldProps) => {
  return (
    <TextInput
      styles={{
        input: { fontSize: '18px' },
        label: { fontSize: '14px' },
      }}
      variant="filled"
      color="gray-dark"
      size="lg"
      {...props}
    />
  );
};

export default TextField;
