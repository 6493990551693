import Button from 'components/Button';
import { useState } from 'react';
import { Wallet } from '../types/wallet';
import { getWalletIcon } from '../util/walletIcons';

import checkMark from 'assets/icons/check-mark.svg';

interface ViewSavedWalletsProps {
  wallets: Wallet[];
  onSelect: (wallet: Wallet) => void;
  crypto: string;
}
export const ViewWalletsModal = ({
  wallets,
  onSelect,
  crypto,
}: ViewSavedWalletsProps) => {
  const filterWallets = wallets.filter((wallet) => {
    console.log(wallet.crypto);
    return wallet.crypto.toLowerCase() === crypto.toLowerCase();
  });
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();

  const isSelected = (wallet: Wallet) => {
    return wallet.address === selectedWallet?.address;
  };
  return (
    <div className="px-3 py-5">
      <h2>Saved Wallets</h2>
      <p>Please select the wallet you would like to use for this transaction</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-4">
        {/* Render all wallets in account */}
        {wallets.length > 0 ? (
          filterWallets.map((wallet, index) => (
            <div
              className={`h-full rounded-md p-8 text-gray-dark shadow-custom w-full hover:border-primary hover:border cursor-pointer ${
                isSelected(wallet) ? 'border-primary' : ''
              }  relative flex flex-grow items-center  gap-4 rounded-md border bg-white p-6 ${
                isSelected(wallet) ? 'border-primary' : 'border-gray-dark'
              }`}
              onClick={() => {
                setSelectedWallet(wallet);
              }}
            >
              <span
                className={`absolute top-2 right-2 inline-grid h-6 w-6 place-items-center rounded-full border ${
                  isSelected(wallet)
                    ? 'border-primary bg-primary'
                    : 'border-gray-dark'
                }`}
              >
                <img src={checkMark} alt="" className="h-2" />
              </span>
              <div className="flex gap-4 items-center">
                <img
                  src={getWalletIcon(wallet.crypto)}
                  alt={`${wallet.crypto} icon`}
                  className="h-10"
                />
                <div>
                  <p className="my-2 font-bold text-gray-secondaryDark">
                    {' '}
                    {wallet.name}
                  </p>

                  <p className="text-sm my-2 truncate bg-gray-light rounded py-2 px-4">
                    {' '}
                    {wallet.address}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>You do not currently have any wallets saved to your account</div>
        )}
      </div>
      <div className="flex justify-center">
        {selectedWallet && (
          <Button
            size="sm"
            className="py-3 mx-auto"
            onClick={() => {
              onSelect(selectedWallet);
            }}
          >
            {' '}
            Confirm Selection
          </Button>
        )}
      </div>
    </div>
  );
};
