import { useGetApiInfo } from 'common/api/getApiInfo';
import { SystemInfoResponse } from 'common/types';
import React, { createContext, useContext, useState } from 'react';

type SystemInfoContextType = {
  systemInfo: SystemInfoResponse | null;
  supportedPaymentMethods: string[];
  isLoading: boolean;
  hasError: boolean;
  fetchSystemInfo: () => void;
};

const SystemInfoContext = createContext<SystemInfoContextType>({
  systemInfo: null,
  supportedPaymentMethods: [],
  isLoading: false,
  hasError: false,
  fetchSystemInfo: () => {},
});

export const SystemInfoProvider: React.FC = ({ children }) => {
  const [systemInfo, setSystemInfo] = useState<SystemInfoResponse | null>(null);
  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState<
    string[]
  >([]);

  const onSuccess = (systemInfo: SystemInfoResponse) => {
    setSystemInfo(systemInfo);

    const methods = Object.entries(systemInfo.supported_methods.buy).map(
      ([key, value]) => key
    );
    setSupportedPaymentMethods(methods);
  };

  const { update: query, isLoading, hasError } = useGetApiInfo(onSuccess);

  return (
    <SystemInfoContext.Provider
      value={{
        systemInfo,
        supportedPaymentMethods,
        isLoading,
        hasError,
        fetchSystemInfo: () => query({}),
      }}
    >
      {children}
    </SystemInfoContext.Provider>
  );
};

export function useSystemInfo() {
  const context = useContext(SystemInfoContext);
  if (!context) {
    throw new Error('useSystemInfo must be used within a SystemInfoProvider');
  }
  return context;
}
