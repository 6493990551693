import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { VerifyCardResponse } from '../types/VerifyCardResponse';

export type VerifyCardDTO = {
  amount: number;
  cardRef: string;
};

const verifyCard = async ({ cardRef, ...credentials }: VerifyCardDTO) => {
  const resp = await axios.post<VerifyCardResponse>(
    `/api/account/bank/card/${cardRef}/verify`,
    {
      ...credentials,
    }
  );
  return resp.data;
};

export const useVerifyCard = (
  onSuccess: OnSuccessCallback<VerifyCardResponse>
) => {
  return useUpdateApi(verifyCard, {
    onSuccess,
    successNotificationText: 'Card successfully verified',
  });
};
