import {
  createBrowserRouter,
} from "react-router-dom";
import { Navigate } from 'react-router';
import NotFound from 'components/NotFound';

import { PublicLocationsPage } from 'modules/locations/';
import { routes as authRoutes } from 'modules/auth/routes'; 
import { routes as dashboardRoutes } from 'modules/dashboard/routes';


export const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/dashboard/overview" />,
      },
      {
        path: '/view-locations',
        element: <PublicLocationsPage />,
      },
      {
        path: '/pickupready',
        element: <Navigate to="/dashboard/pickupready" />,
      },
      ...authRoutes,
      ...dashboardRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
]);
