const CashRegisterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 24.1111H34V31H0V24.1111ZM7.225 6.88889H11.9V5.16667H6.8V0H20.4V5.16667H15.3V6.88889H26.86C28.56 6.88889 30.26 8.61111 30.6 10.3333L31.45 22.3889H2.55L3.485 10.3333C3.485 8.61111 5.185 6.88889 7.225 6.88889ZM18.7 10.3333V13.7778H27.2V10.3333H18.7ZM6.8 10.3333V12.0556H10.2V10.3333H6.8ZM11.9 10.3333V12.0556H15.3V10.3333H11.9ZM6.8 13.7778V15.5H10.2V13.7778H6.8ZM11.9 13.7778V15.5H15.3V13.7778H11.9ZM6.8 17.2222V18.9444H10.2V17.2222H6.8ZM11.9 17.2222V18.9444H15.3V17.2222H11.9ZM8.5 1.72222V3.44444H18.7V1.72222H8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CashRegisterIcon;
