import { toast } from 'react-toastify';

const notification = {
  notify: (message: string, duration = 5000, id?: string) => {
    toast.info(message, { autoClose: duration, toastId: id });
  },
  notifyError: (message: string, duration = 5000) => {
    toast.error(message, { autoClose: duration });
  },
};

export default notification;
