import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid';
import { Loader } from '@mantine/core';
import { Transaction } from 'modules/transactions';
import { TransactionFilterConfig } from '../../types/TransactionFilterConfig';
import { filterTransactions } from '../../utils';
import { useSortableData } from 'common/hooks';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetCombinedTransactions } from '../../api/getTransactions';
import { useModals } from '@mantine/modals';
import { SellTransactionModal } from '../SellTransactionModal';
import { KyckStatus } from 'modules/sell/types/kyckStatuses';
import { Invoice } from 'modules/sell';
import { InvoiceModal } from '../InvoiceModal';
import { InvoiceRow } from './InvoiceRow';
import { TransactionRow } from './TransactionRow';
import { GreendotBuyModal } from '../GreendotBuyModal';
import { WebRecieptModal } from '../WebReceiptModal';
import { ServiceProvider } from 'common/types';
import { TritonBuyModal } from '../TritonBuyModal';

const headings = [
  { label: 'Order ID', propertyName: undefined },
  { label: 'Type', propertyName: undefined },
  { label: 'Amount', propertyName: undefined },
  { label: 'Crypto Amount', propertyName: undefined },
  { label: 'Crypto', propertyName: undefined },
  { label: 'Date', propertyName: 'transfer_date_utc' },
  { label: 'Payment Type', propertyName: undefined },
  { label: 'Status', propertyName: undefined },
  { label: 'Expiration Date', propertyName: 'expiry_date_utc' },
];

type TransactionTableProps = {
  showRecent?: boolean;
  filters?: TransactionFilterConfig;
};


export const TransactionsTable = ({
  showRecent = false,
  filters,
}: TransactionTableProps) => {
  const { isLoading, data, hasError } = useGetCombinedTransactions();
  const [combinedTransactions, setCombinedTransactions] = useState<
    (Transaction | Invoice)[]
  >([]);
  const modals = useModals();

  useEffect(() => {
    if (data) {
      setCombinedTransactions(data.data);
    } else {
      console.log('No Data');
    }
  }, [data, combinedTransactions, showRecent]);

  /** Filters out transactions based on model
   * @param transactionModel The respective model, whether a 'Transaction' or 'Invoice
   */
  const getTransactionsByModel = (transactionModel: string) =>
    combinedTransactions.filter((transaction: Transaction | Invoice) => {
      return transaction.model_type === transactionModel;
    });

  /**  Enforces Invoice Array type to result */
  const getInvoices = () => {
    return getTransactionsByModel('Invoice') as Invoice[];
  };

  /**  Enforces Transaction Array type to result */
  const getTransactions = () => {
    return getTransactionsByModel('Transaction') as Transaction[];
  };

  const { requestSort, sortConfig, sortedItems } = useSortableData(
    getTransactions(),
    { sortBy: 'transfer_date_utc', isAscending: false }
  );

  const filteredTransactions = useMemo(
    () => (filters ? filterTransactions(sortedItems, filters) : sortedItems),
    [filters, sortedItems]
  );

  const handleSelectTransaction = (transaction: Transaction) => {
    if (
      transaction.transaction_type === 'sell' &&
      transaction.status !== KyckStatus.EXPIRED
    ) {
      modals.openModal({
        size: 'md',
        children: <SellTransactionModal transaction={transaction} />,
      });
    } else if (transaction.status === 'Completed') {
      console.log('Selecting completed transaction');
      modals.openModal({
        size: 'xl',
        children: <WebRecieptModal transaction={transaction} />,
      });
    } else if (
      // Display greendot modal if transaction is greendot
      transaction.transaction_type === 'buy' &&
      transaction.service_provider === 'greendot'
    ) {
      modals.openModal({
        size: 'full',
        children: <GreendotBuyModal transaction={transaction} />,
      });
    } else if (
      transaction.transaction_type === 'buy' &&
      transaction.service_provider === ServiceProvider.TRITON
    ) {
      modals.openModal({
        size: 'xl',
        children: <TritonBuyModal transaction={transaction} />,
      });
    }
  };
  const handleSelectInvoice = (invoice: Invoice) => {
    modals.openModal({
      size: 'full',
      children: <InvoiceModal invoice={invoice} />,
    });
  };

  const handleSort = (field?: string) => {
    if (field) {
      requestSort(field);
    }
  };

  const SortIcon = sortConfig.isAscending
    ? SortAscendingIcon
    : SortDescendingIcon;

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  if (hasError) {
    return <div>Could not fetch transactions</div>;
  }

  if (combinedTransactions.length === 0) {
    return (
      <div>
        You don't have any transactions yet. Make one by{' '}
        <Link className="text-lg text-primary underline" to="/dashboard/buy">
          buying
        </Link>{' '}
        or{' '}
        <Link
          className="text-lg uppercase text-primary underline"
          to="/dashboard/sell"
        >
          selling
        </Link>{' '}
        some crypto!
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto">
        {console.log('test')}

        <thead className="bg-gray-light px-12 py-6 font-bold">
          <tr>
            {headings.map((heading) => (
              <th
                className={`${
                  heading.propertyName
                    ? 'cursor-pointer transition-colors hover:bg-tw-gray-100'
                    : ''
                } py-6 px-3`}
                key={heading.label}
                onClick={() => handleSort(heading.propertyName)}
              >
                <span className="inline-flex items-center gap-2">
                  {heading.label}{' '}
                  {heading.propertyName && (
                    <SortIcon
                      className={`${
                        sortConfig.sortBy === heading.propertyName
                          ? 'text-primary'
                          : 'text-tw-gray-400'
                      } h-4 shrink-0`}
                    />
                  )}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-sm">
          {/* Map out invoices */}
          {getInvoices().map((invoice) => (
            <InvoiceRow
              onSelectInvoice={handleSelectInvoice}
              invoice={invoice}
              key={invoice.order_id}
            />
          ))}

          {filteredTransactions.map((t) => (
            <TransactionRow
              key={t.order_id}
              onSelectTransaction={handleSelectTransaction}
              transaction={t}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
