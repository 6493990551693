export interface BankAccountAttributes {
    payeeId: string;
    memberId: string;
    routingNumber: string;
    accountNumber: string;
    accountName: string;
    accountType: string;
    institutionName: string;
    institutionCode: string;
    institutionLogo: string;
  }; 

export default class BankAccount implements BankAccountAttributes  {
  payeeId: string;
  memberId: string;
  routingNumber: string;
  accountNumber: string;
  accountName: string;
  accountType: string;
  institutionName: string;
  institutionLogo: string;
  institutionCode: string;

  constructor({ payeeId, memberId, routingNumber, accountNumber, accountName, accountType, institutionName, institutionLogo, institutionCode }: BankAccountAttributes){
    this.payeeId = payeeId;
    this.memberId = memberId;
    this.routingNumber = routingNumber;
    this.accountName = accountNumber;
    this.accountNumber = accountName;
    this.accountType = accountType;
    this.institutionName = institutionName;
    this.institutionLogo = institutionLogo;
    this.institutionCode = institutionCode
  }

  static createBankAccountsFromResponse(accounts: Array<any>): BankAccount[] {
    const bankAccounts: BankAccount[] = [];

    accounts.map(account => {
      return bankAccounts.push({
        payeeId: account.guid,
        memberId: account.member_guid,
        accountName: account.name,
        accountNumber: account.account_number,
        routingNumber: account.routing_number,
        accountType: account.type,
        institutionCode: "",
        institutionLogo: "",
        institutionName: "",
      });
    })
    return bankAccounts;
  }
}