import { useClipboard } from '@mantine/hooks';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';

import { Tooltip } from '@mantine/core';

type CopySegmentProps = {
  amountInCrypto: string;
  amountInUSD: number;
  crypto: string;
  walletAddress: string;
};

export const CopySegment = ({
  amountInCrypto,
  amountInUSD,
  walletAddress,
  crypto,
}: CopySegmentProps) => {
  const { copy } = useClipboard();
  return (
    <div>
      <div className="flex items-center justify-between border-b-2 border-gray-medium px-4  py-4">
        <span>Transaction Amount</span>
        <div className="bg-gray-medium text-gray-darker py-2 px-4 rounded flex">
          <p className="mx-3">
            ${amountInUSD} {' = '}
            {amountInCrypto} {crypto}
          </p>
          <Tooltip label={`Copy ${crypto} Amount`}>
            <DocumentDuplicateIcon
              className="h-6 hover:cursor-pointer"
              onClick={() => {
                copy(crypto);
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center justify-between border-b-2 border-gray-medium px-4  py-4">
        <p>{crypto} Address </p>
        <div className="flex bg-gray-medium text-gray-darker items-center py-2  px-4 rounded">
          <p className="mx-3">{walletAddress}</p>
          <Tooltip label={`Copy ${crypto} Address`}>
            <DocumentDuplicateIcon
              className="h-6 hover:cursor-pointer"
              onClick={() => {
                copy(walletAddress);
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
