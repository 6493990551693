import { RouteObject } from 'react-router-dom';
import LoginPage from 'modules/auth/pages/LoginPage';
import { TwoFactorVerify } from 'modules/auth/pages/TwoFactorVerify';
import RegistrationPage from 'modules/auth/pages/RegistrationPage';

export const routes: RouteObject[] = [
    {
      path: 'auth/*',
      children: [
        {
          path: 'register',
          element: <RegistrationPage />,
        },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: '2fa-verify',
          element: <TwoFactorVerify />,
        },
      ]
    },
];