import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { Invoice } from '../types/invoice';

const getInvoice = async (invoiceId: string) => {
  const resp = await axios.get<Invoice>(
    `/api/invoice/status?btc_invoice_id=${invoiceId}`
  );
  return resp.data;
};

export const useGetInvoice = (onSuccess: OnSuccessCallback<Invoice>) => {
  return useUpdateApi(getInvoice, { onSuccess });
};
