import { useFetchApi } from 'common/hooks';
import { GetTransactionsResponse, CombinedTransactionsResponse, TransactionFilterParams } from '../types/transaction';

export const useGetTransactions = () =>
  useFetchApi<GetTransactionsResponse>(`/api/account/transaction/list`);

const createFilters = (filterParams: Partial<TransactionFilterParams>) => {

  const filters = [
    `limit=${filterParams?.limit}`,
    `status=${filterParams?.status}`,
    `buy_or_sell=${filterParams?.buy_or_sell}`
  ]
  return filters.join('&');
}
export const useGetCombinedTransactions = (filterParams?: TransactionFilterParams) => {
  return useFetchApi<CombinedTransactionsResponse>(`/api/account/transaction`)
}