import { axios } from 'lib/axios';
import { OnSuccessCallback, useUpdateApi } from 'common/hooks';

// Toggle 2FA

export type RemoveCashoutResponse = {
    message: string;
    status: string;
}

export type RemoveCashoutParams = {
    cashoutMethod: string;
    reason: string;
}
export const removeCashoutMethod = async ({ cashoutMethod, reason }: RemoveCashoutParams) => {
    const resp = await axios.delete<RemoveCashoutResponse>(`api/account/remove/${cashoutMethod}`,
        {
            data: {
                reason: reason
            }
        });
    return resp.data;
}


export const useRemoveCashoutMethod = (
    onSuccess: OnSuccessCallback<RemoveCashoutResponse>
) => {
    return useUpdateApi(removeCashoutMethod, { onSuccess, successNotificationText: 'Cashout method successfully deleted' });
};