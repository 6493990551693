import logo from 'assets/imgs/unbank-logo.svg';

type NotFoundProps = {
  coloredBackground?: boolean;
};

const NotFound = ({ coloredBackground = true }: NotFoundProps) => {
  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-center  ${
        coloredBackground ? 'bg-light-green' : 'bg-white'
      }`}
    >
      <img src={logo} className="mb-8 h-16" alt="unbank logo" />
      <div className="flex items-center divide-x p-8 text-xl text-secondary">
        <p className="pr-4 font-bold">404</p>
        <h2 className="pl-4">This page could not be found.</h2>
      </div>
    </div>
  );
};

export default NotFound;
