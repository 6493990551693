import { useState, useEffect } from 'react';
import { TransactionsPage } from './TransactionsPage';
import { TransactionList } from '../components/TransactionList/TransactionList';
import { detectViewport } from 'utils/layout';

export const Transactions = () => {
  const [viewport, setViewport] = useState(detectViewport());

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  // Return the appropriate styles based on the user's viewport/device
  return (
    <div>
      {viewport === 'mobile' ? (
        <div className="dashboard-page">
          <TransactionList />
        </div>
      ) : (
        <TransactionsPage />
      )}
    </div>
  );
};
