import {
  verifyTwoFactor,
} from 'modules/account';
import { useState } from 'react';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import logo from 'assets/imgs/unbank-logo.svg';
import { LoginApiResponse } from '../types/login';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import storage from 'utils/storage';

export const TwoFactorVerify = () => {
  // API returns QR Response or Empty array
  const [ , setTwoFactorResult] = useState<LoginApiResponse>();
  const [authType, setAuthType] = useState('authenticator');

  const navigate = useNavigate();
  const { handleChange, handleSubmit } = useFormik<{ code: string }>({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      console.log('submitting');
      const result = await verifyTwoFactor(values.code, authType);
      setTwoFactorResult(result);

      if (result.user) {
        storage.setTwoFA(true);
        navigate('/dashboard/overview');
      }
    },
  });
  return (
    <div className="my-12">
      <header>
        <div className="mx-8 flex  flex-col items-center justify-center lg:mx-auto">
          <div className="mb-2 rounded-lg bg-primary p-2">
            <a href="https://unbankworld.com/">
              <img src={logo} className="h-11 lg:h-14 " alt="Unbank Logo" />
            </a>
          </div>
        </div>
      </header>
      <main className="flex flex-col items-center text-center ">
        <h1>Two Factor Authentication</h1>
        <p className="w-2/5 mb-4 text-sm text-gray-secondaryDark ">
          {' '}
          Thanks for keeping your account secure. Enter your verification code
          from your authenticator app or one of your recovery codes to access
          your unbank dashboard!
        </p>

        <section className="">
          <form onSubmit={handleSubmit}>
            <TextField
              name="code"
              onChange={handleChange}
              className="w-full py-2"
              placeholder={
                authType === 'authenticator'
                  ? 'Verification Code'
                  : 'Recovery Code'
              }
            ></TextField>
            <div className="flex items-center justify-center">
              <Button type="submit" size="sm">
                Log in securely
              </Button>{' '}
              {authType === 'authenticator' ? (
                <p
                  onClick={() => {
                    setAuthType('recovery_code');
                  }}
                  className="text-primary text-sm mt-3 ml-5 hover:cursor-pointer"
                >
                  {' '}
                  Use Recovery Code{' '}
                </p>
              ) : (
                <p
                  onClick={() => {
                    setAuthType('authenticator');
                  }}
                  className="text-primary text-sm mt-3 ml-5 hover:cursor-pointer"
                >
                  {' '}
                  Use Code from Authenticator App{' '}
                </p>
              )}
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};
