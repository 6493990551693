import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useVerifyPhone, VerifyPhoneCredentails } from '../api/verifyPhone';
import arrow_right from 'assets/icons/arrow_right.svg';
import TextField from 'components/TextField';
import Button from 'components/Button';
import VerificationBanner from './VerificationBanner';
import { useResendPhoneVerification } from '../api/resendPhoneVerification';

type PhoneVerificationFormProps = {
  onComplete: () => void;
  buttonLabel?: string;
  phone: string;
};

export const PhoneVerificationForm = ({
  onComplete,
  phone,
  buttonLabel = 'Verify Phone',
}: PhoneVerificationFormProps) => {
  const apiQuery = useVerifyPhone(onComplete);
  const { isLoading, update: sendVerification } = useResendPhoneVerification();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<VerifyPhoneCredentails>({
      initialValues: {
        code: '',
      },
      validationSchema: Yup.object({
        code: Yup.string().required(),
      }),
      onSubmit: (values) => {
        apiQuery.update(values);
      },
    });

  return (
    <form onSubmit={handleSubmit} className="space-y-5">
      <VerificationBanner
        type="phone"
        contactDetail={phone}
        onRefresh={() => sendVerification({})}
        isRefreshing={isLoading}
      />
      <TextField
        label="Verification Code"
        placeholder="Code"
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.code && touched.code && errors.code}
      />

      <div className="text-center">
        <Button
          type="submit"
          loading={apiQuery.isLoading}
          iconPosition="right"
          icon={<img src={arrow_right} className="h-4" alt="right arrow" />}
        >
          {buttonLabel}
        </Button>
      </div>
    </form>
  );
};
