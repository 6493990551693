import { useEffect, useState } from 'react';
import { useClipboard } from '@mantine/hooks';
import { ClipboardIcon } from '@heroicons/react/outline';
import { Tooltip } from '@mantine/core';
import notification from 'utils/notification';
import {
  getRecoveryCodes,
  useRegenerateRecoveryCodes,
} from 'modules/account/api/twoFactorAuth';
import Button from 'components/Button';

export type RecoveryCodesProps = {};
export const RecoveryCodes: React.FC<RecoveryCodesProps> = () => {
  const [recoveryCodes, setRecoveryCodes] = useState<[]>([]);
  const clipboard = useClipboard({ timeout: 500 });
  const { update: regenerateRecoveryCodes } = useRegenerateRecoveryCodes(
    () => {}
  );

  useEffect(() => {
    const fetchRecoveryCodes = async () => {
      const codes = await getRecoveryCodes();
      setRecoveryCodes(codes);
    };

    fetchRecoveryCodes().catch(console.error);
  }, []);

  // Regenerate new recovery codes
  const handleRecoveryCodeRegeneration = async () => {
    regenerateRecoveryCodes({});
    const newCodes = await getRecoveryCodes();
    setRecoveryCodes(newCodes);
  };

  return (
    <div>
      <h3 className="font-bold my-2">Recovery Codes</h3>
      <p className="my-2">
        Save your recovery codes below somewhere secure in the event that your
        authentication device is lost
      </p>

      <div className="flex justify-around  bg-gray-medium  p-5">
        <div className="grid grid-cols-2 w-full">
          {recoveryCodes?.map((recoveryCode) => (
            <p className="text-sm text-gray-secondaryDark"> {recoveryCode}</p>
          ))}
        </div>
        <Tooltip label="Copy Recovery Codes">
          <ClipboardIcon
            className="h-5 text-gray-secondaryDark hover:cursor-pointer"
            onClick={() => {
              clipboard.copy(recoveryCodes.join('\r\n'));
              notification.notify('Recovery Codes Successfully copied');
            }}
          />
        </Tooltip>
      </div>
      <div className="flex justify-between mt-5">
        <Button
          color="bg-secondary"
          size="sm"
          onClick={() => {
            handleRecoveryCodeRegeneration();
          }}
        >
          Regenerate Recovery Codes
        </Button>
      </div>
    </div>
  );
};
