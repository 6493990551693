import { Loader } from '@mantine/core';
import React from 'react';

type ButtonProps = {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  fullWidth?: boolean;
  condensed?: boolean;
  size?: 'sm' | 'lg';
  color?:
    | 'bg-primary'
    | 'bg-secondary'
    | 'bg-light-blue'
    | 'bg-light-green'
    | 'bg-gray-dark';
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  iconPosition = 'left',
  color = 'bg-primary',
  loading = false,
  fullWidth = false,
  condensed = false,
  size = 'lg',
  className,
  ...props
}) => {
  return (
    <button
      disabled={loading}
      className={`btn rounded-full ${
        fullWidth ? 'w-full' : condensed ? '' : 'w-full lg:w-1/3 lg:max-w-xs'
      } ${size === 'lg' ? 'py-4 px-6 text-lg font-semibold' : ''} ${color} ${
        className ? className : ''
      } min-w-max disabled:cursor-not-allowed`}
      {...props}
    >
      {loading ? (
        <Loader size="md" color="white" />
      ) : (
        <>
          {icon && iconPosition === 'left' && icon}
          <p>{children}</p>
          {icon && iconPosition === 'right' && icon}
        </>
      )}
    </button>
  );
};

export default Button;
