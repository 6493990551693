import { SupportedPaymentMethod } from "common/types";

export const currencyFormatter = (value: number, decimals = 2) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'usd',
    style: 'currency',
    minimumFractionDigits: decimals,
  }).format(value);
};

export const fundingSourceFormatter = (paymentMethod: SupportedPaymentMethod) => {
  switch (paymentMethod) {
    case SupportedPaymentMethod.BANK_CARD:
      return 'Card ending in '

    case SupportedPaymentMethod.UNBANK_CASH:
      return "Account Balance"

    case SupportedPaymentMethod.CASH:
      return "Cash"
  }
}
export const numberFormatter = (value: number, decimals = 2) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
  }).format(value);
};

export const getCryptoCurrencyTitle = (crypto: string) => {
  switch (crypto.toLowerCase()) {
    case 'btc':
      return 'Bitcoin';
    case 'ltc':
      return 'Litecoin';
  }
};
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const dateFormatter = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US').format(date);

export const timeAgo = (date: Date) => {

  const dateInMs = date.getTime()
  let ago = Math.floor(dateInMs / 1000);
  let part = 0;

  if (ago < 2) { return "a moment ago"; }
  if (ago < 5) { return "moments ago"; }
  if (ago < 60) { return ago + " seconds ago"; }

  if (ago < 120) { return "a minute ago"; }
  if (ago < 3600) {
    while (ago >= 60) { ago -= 60; part += 1; }
    return part + " minutes ago";
  }

  if (ago < 7200) { return "an hour ago"; }
  if (ago < 86400) {
    while (ago >= 3600) { ago -= 3600; part += 1; }
    return part + " hours ago";
  }

  if (ago < 172800) { return "a day ago"; }
  if (ago < 604800) {
    while (ago >= 172800) { ago -= 172800; part += 1; }
    return part + " days ago";
  }

  if (ago < 1209600) { return "a week ago"; }
  if (ago < 2592000) {
    while (ago >= 604800) { ago -= 604800; part += 1; }
    return part + " weeks ago";
  }

  if (ago < 5184000) { return "a month ago"; }
  if (ago < 31536000) {
    while (ago >= 2592000) { ago -= 2592000; part += 1; }
    return part + " months ago";
  }

  if (ago < 1419120000) { // 45 years, approximately the epoch
    return "more than year ago";
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return "never";
}