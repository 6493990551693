import Button from 'components/Button';
import TextField from 'components/TextField';

import { useFormik } from 'formik';
import { User } from 'common/types';
import * as Yup from 'yup';
import { AccountInfoDTO, useUpdateAccountInfo } from '../../api/updateAccount';

type SocialSecurityFormProps = {
  onSuccess: (data: User) => void;
};

export const SocialSecurityForm = ({ onSuccess }: SocialSecurityFormProps) => {
  const { isLoading, update } = useUpdateAccountInfo((data) => {
    onSuccess(data.data);
  }, 'Successfully added SSN to account');
  const { errors, touched, handleSubmit, ...formik } = useFormik<
    Pick<AccountInfoDTO, 'ssn'>
  >({
    initialValues: {
      ssn: '',
    },
    validationSchema: Yup.object({
      ssn: Yup.string().required(),
    }),
    onSubmit: (values) => {
      update(values);
    },
  });
  return (
    <form className="mt-8 space-y-8" onSubmit={handleSubmit}>
      <TextField
        placeholder="Social Security Number"
        {...formik.getFieldProps('ssn')}
        error={errors.ssn && touched.ssn && errors.ssn}
      />
      <div className="flex justify-center">
        <Button fullWidth loading={isLoading} className="lg:w-1/3 lg:max-w-xs">
          Add SSN
        </Button>
      </div>
    </form>
  );
};
