import { FindLocations } from '../components/FindLocations';
import { PublicLocationsBanner } from '../components/PublicLocationsBanner';

export const PublicLocationsPage = () => (
  <div>
    <PublicLocationsBanner />
    <div className="dashboard-page">
      <FindLocations transactionType="both" />
    </div>
  </div>
);
