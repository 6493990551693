export enum TritonStatuses {
    NEW = 'New',
    PENDING = 'Pending',
    PROCESSING = 'Processing',
    ERROR = 'Error',
    INVALID = 'Invalid',
    RECEIVED = 'Received',
    COMPLETED = 'Completed',
    EXPIRED = 'Expired'

}