import axios from 'axios';
import { useGetAccountInfo } from 'modules/account';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '.';
import { User } from 'common/types';
import storage from 'utils/storage';

interface GeolocationData {
  country_name: string;
  state: string;
  IPv4: string;
}

type VerificationContextType = {
  /** Used for Geofencing */
  isUserLocationValid: boolean;

  /** Restricts flows if User is Level 0 */
  isEmailVerified: boolean;
  user: User | undefined;

  /* Restrict flows if User is Level 1 */
  isLevelOneVerified: boolean;

  /* Used for getting device IP */
  location: GeolocationData | undefined;
  getLocationData: () => void;
};

const VerificationContext = createContext<VerificationContextType>({
  isUserLocationValid: false,
  isLevelOneVerified: false,
  isEmailVerified: false,
  user: undefined,
  location: undefined,
  getLocationData: () => {},
});

export const VerificationProvider: React.FC = ({ children }) => {
  const [location, setLocation] = useState<GeolocationData>();
  const { user, setUser } = useAuth();

  const getLocationData = async () => {
    const res = await axios.get(`https://geolocation-db.com/json/`);
    setLocation(res.data);
  };

  /** Pull User's location */
  useEffect(() => {
    getLocationData();
  }, []);

  const validCountires = ['United States', 'Canada', 'Jamaica'];
  const invalidStates = ['New York', 'Washington', 'Vermont', 'Alaska'];

  /** Fetch User Info to get account level */
  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );

  useEffect(() => {
    if (storage.getToken()) {
      fetchUserInfo({});
    }
  }, []);

  const checkLevelOneVerified = () => {
    return user && user?.account_level > 1;
  };

  const isLevelOneVerified = checkLevelOneVerified()!;

  const isLocationValid = () => {
    return (
      location &&
      validCountires.includes(location?.country_name) &&
      !invalidStates.includes(location?.state)
    );
  };

  const isUserLocationValid = isLocationValid()!;

  const checkEmailVerified = () => {
    return user && user?.account_level > 0;
  };

  const isEmailVerified = checkEmailVerified()!;

  return (
    <VerificationContext.Provider
      value={{
        getLocationData,
        isUserLocationValid,
        isEmailVerified,
        isLevelOneVerified,
        user,
        location,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};

export function useVerify() {
  const context = useContext(VerificationContext);
  if (!context) {
    throw new Error('useVerify must be used within an VerificationProvider.');
  }
  return context;
}
