import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/outline';

interface StyledOrderStatusProps {
  orderStatus: string;
}
export const StyledOrderStatus = ({ orderStatus }: StyledOrderStatusProps) => {
  const baseStyles =
    'flex px-12 py-2 w-1/3 gap-2 text-white rounded-lg justify-center items-center my-2 ';
  const iconStyles = 'h-5 text-white';
  switch (orderStatus) {
    case 'Expired' || 'Rejected':
      return (
        <article className={`${baseStyles} bg-red-500`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
    case 'New':
      return (
        <article className={`${baseStyles} bg-primary`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Success':
      return (
        <article className={`${baseStyles} bg-primary`}>
          <div>
            <CheckCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Completed':
      return (
        <article className={`${baseStyles} bg-primary`}>
          <div>
            <CheckCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
    case 'Pending':
      return (
        <article className={`${baseStyles} bg-blue-500`}>
          <div>
            <RefreshIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
    default:
      return (
        <article className={`${baseStyles} bg-blue-500`}>
          <div>
            <ExclamationCircleIcon className={`${iconStyles}`} />
          </div>
          <p className="text-sm font-semibold">{orderStatus}</p>
        </article>
      );
  }
};
