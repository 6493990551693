import { MAPBOX_PUBLIC_KEY } from 'config';
import logo from 'assets/imgs/unbank-logo.svg';
import { useCallback, useMemo, useState } from 'react';
import { getCenter } from 'geolib';
import ReactMapGL, {
  Marker,
  Popup,
  ViewStateChangeEvent,
  GeolocateControl,
} from 'react-map-gl';
import { AtmLocation } from '../types';

import 'mapbox-gl/dist/mapbox-gl.css';
import { AtmLocationCard } from './AtmLocationCard';
import { RefreshIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

type MapProps = {
  locations?: AtmLocation[];
  onViewLocation?: (location: AtmLocation) => void;
  onUpdateCenter?: (latitude: number, longitude: number) => void;
  onClose?: () => void;
};

export const Map = ({
  locations,
  onViewLocation,
  onUpdateCenter,
  onClose,
}: MapProps) => {
  const [selectedLocation, setSelectedLocation] = useState<AtmLocation | null>(
    null
  );
  const center = useMemo(() => {
    if (!locations) return false;
    const coors = locations.map(({ latitude, longitude }) => ({
      latitude,
      longitude,
    }));
    return getCenter(coors);
  }, [locations]);

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: center ? center.latitude : 37.8,
    longitude: center ? center.longitude : -122.4,
    zoom: 11,
  });

  const geolocateControlRef = useCallback((ref) => {
    if (ref) {
      // Activate as soon as the control is loaded
      ref.trigger();
    }
  }, []);

  const handleMove = ({
    viewState: { latitude, longitude, zoom },
  }: ViewStateChangeEvent) => {
    setViewport((prev) => ({ ...prev, latitude, longitude, zoom }));
  };

  return (
    <div className="relative h-full w-full">
      <ReactMapGL
        {...viewport}
        onMove={handleMove}
        style={{ width: '100%', height: '100%' }}
        mapboxAccessToken={MAPBOX_PUBLIC_KEY}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        {/* Close button */}
        {!!onClose && (
          <button
            className="absolute left-2 top-2 cursor-pointer rounded-md p-2"
            onClick={onClose}
          >
            <XIcon className="h-6" />
          </button>
        )}
        {onUpdateCenter && (
          <div
            className="absolute left-1/2 top-2 flex -translate-x-1/2 cursor-pointer items-center gap-2 rounded-md bg-white p-2 text-primary"
            onClick={() =>
              onUpdateCenter(viewport.latitude, viewport.longitude)
            }
          >
            <RefreshIcon className="h-6 " />{' '}
            <span className="text-lg">Reload Area</span>
          </div>
        )}

        <GeolocateControl ref={geolocateControlRef} />
        {locations?.map((location) => (
          <div key={location.atm_id}>
            <Marker longitude={location.longitude} latitude={location.latitude}>
              <div
                onClick={() => setSelectedLocation(location)}
                className="mr-6 flex h-8 w-8 items-center justify-center rounded-lg bg-primary p-2"
              >
                <img src={logo} alt="unbank logo" />
              </div>
            </Marker>
            {!!selectedLocation &&
              selectedLocation.atm_id === location.atm_id && (
                <Popup
                  latitude={location.latitude}
                  longitude={location.longitude}
                  anchor="top"
                  className="map-popup"
                  onClose={() => setSelectedLocation(null)}
                >
                  <AtmLocationCard
                    onViewLocation={onViewLocation ? onViewLocation : () => {}}
                    location={selectedLocation}
                  />
                </Popup>
              )}
          </div>
        ))}
      </ReactMapGL>
    </div>
  );
};
