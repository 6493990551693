import { RouteObject } from "react-router-dom";
import { TransactionLimitsPage } from 'modules/transactions/pages/TransactionLimitsPage';
import { Transactions } from 'modules/transactions/pages/Transactions';

export const routes: RouteObject[] = [
    {
        path: 'transactions/*',
        element: <Transactions />,
        children: [
            {
                path: 'limits',
                element: <TransactionLimitsPage />,
            },
            {
                path:'limits/sell',
                element: <div></div>
            }
        ]
    }
]