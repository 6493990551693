export const KyckStatus = {
  EXPIRED: 'Expired',
  NEW: 'New',
  SETTLED: 'Settled',
  SENT: 'Sent',
  SUBMITTED: 'Submitted',
  PICKUP_READY: 'Pickup Ready',
  PROCESSING: 'Processing',
  SUCCESS: 'Success',
  RETURNED: 'Returned',
  REJECTED: 'Rejected',
};
