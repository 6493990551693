import { createContext, useContext, useEffect, useState } from 'react';
import storage from 'utils/storage';
import { User } from 'common/types';

type AuthContextType = {
  user: User | undefined;
  token: string | null;
  setToken: (token: string) => void;
  isAuthenticated: boolean;
  isTwoFactorAuthenticated: boolean;
  isTwoFactorValid: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  clearUser: () => void;
  verifyContact: (type: 'phone' | 'email') => void;
};

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  token: '',
  setToken: (token) => {},
  isAuthenticated: false,
  setUser: () => {},
  clearUser: () => {},
  verifyContact: () => {},
  isTwoFactorAuthenticated: false,
  isTwoFactorValid: false,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(storage.getUser());
  const [token, setToken] = useState(storage.getToken());

  const verifyContact = (type: 'phone' | 'email') => {
    setUser((prev) => {
      return {
        ...prev!,
        isPhoneNumberVerified: type === 'phone' ? true : false,
        isEmailVerified: type === 'email' ? true : false,
      };
    });
  };
  const clearUser = () => {
    setUser(undefined);
    setToken('');
    storage.clearAuthData();
  };

  // Check if user has 2fa enabled
  const checkTwoFactorAuthenticated = () => {
    if (user) {
      return user.two_factor;
    }
    return false;
  };
  const isTwoFactorAuthenticated = checkTwoFactorAuthenticated();
  // Check if two factor result is valid
  const checkTwoFA = () => {
    const twoFactorValid = JSON.parse(storage.getTwoFAValid()!);
    if (twoFactorValid) {
      return twoFactorValid.isValid;
    } else {
      return false;
    }
  };

  const isTwoFactorValid = checkTwoFA();

  const isAuthenticated = user !== undefined;

  useEffect(() => {
    if (user) {
      storage.setUser(user);
    } else {
      storage.clearAuthData();
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setToken,
        isAuthenticated,
        isTwoFactorAuthenticated,
        isTwoFactorValid,
        clearUser,
        setUser,
        verifyContact,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }
  return context;
}
