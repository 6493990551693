import { NotificationSetting } from 'modules/account/types/notificationSettings';
import {
  getPlaintextCategory,
  isSetting,
  updateField,
} from 'modules/notifications/util';
import notification from 'utils/notification';

import Button from 'components/Button';
import { updateNotificationSettings } from '../../api/notificationSettings';

import { useState } from 'react';
import { NotificationSwitch } from '../NotificationSwitch';
import TimeAgo from 'react-timeago';
import { NotificationIcon } from 'modules/notifications/components/NotificationIcon';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';

interface NotificationPreferenceProps {
  settings: NotificationSetting;
  onExit: () => void;
}
export const NotificationPreference = ({
  settings,
  onExit,
}: NotificationPreferenceProps) => {
  const [notificationState, setNotificationState] =
    useState<NotificationSetting>(settings);

  const [modified, setModified] = useState(false);

  const handleUpdateNotification = (eventTarget: HTMLInputElement) => {
    // Toggle display for save button
    setModified(true);

    const { name, checked } = eventTarget;
    setNotificationState(updateField(name, checked, notificationState)!);
  };

  const handleSavePreferences = async () => {
    await updateNotificationSettings(notificationState);
    notification.notify('Notification Preferences Successfuly Updated');
  };
  return (
    <div className="">
      <ArrowNarrowLeftIcon
        className="h-6 my-3 text-tw-gray-600 cursor-pointer"
        onClick={() => onExit()}
      />
      <h3 className="font-bold text-md md:text-left text-center">
        {' '}
        {settings.title} Settings{' '}
      </h3>
      <div className="md:grid md:grid-cols-2 flex flex-col">
        <div>
          <p className="text-sm text-gray-secondaryDark"> Alert Settings</p>
          <div>
            {Object.keys(settings).map((setting) => (
              <div className="my-5">
                {isSetting(setting) && (
                  <div className="flex justify-between md:grid md:grid-cols-2">
                    <p> {getPlaintextCategory(setting)}</p>
                    <NotificationSwitch
                      settingName={setting}
                      notificationState={notificationState}
                      update={handleUpdateNotification}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-secondaryDark">
            Notification Preview
          </p>
          <div>
            <article className={`py-4 w-full grid grid-cols-8`}>
              <div className="bg-primary h-9 w-9 flex justify-center rounded">
                <NotificationIcon notificationType={settings.event} />
              </div>
              <div className="col-span-7">
                <h4 className="text-sm font-semibold ">{settings.title}</h4>
                <p className="text-xs w-4/5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempo
                </p>
                <p className="text-xs font-semibold text-gray-secondaryDark">
                  <TimeAgo date={new Date('2021-06-13T15:30:48.893Z')} />
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
      {modified && (
        <Button size="sm" onClick={handleSavePreferences}>
          {' '}
          Save Changes
        </Button>
      )}
    </div>
  );
};
