import { useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

const resendPhoneVerificaiton = async () => {
  const resp = await axios.post('/api/account/resend/verification/number');
  return resp.data;
};

export const useResendPhoneVerification = () => {
  return useUpdateApi(resendPhoneVerificaiton, {
    successNotificationText: 'New verification code sent!',
  });
};
