import { Transaction } from '../types/transaction';
import { CashCode, PickUpConfirmation } from 'modules/sell';
import { KyckStatus } from 'modules/sell/types/kyckStatuses';
import { useAuth } from 'modules/auth';
import { phoneNumberToAccountNumber } from 'modules/sell/utils';

type SellTransactionModalProps = {
  transaction: Transaction;
};

export const SellTransactionModal = ({
  transaction,
}: SellTransactionModalProps) => {
  const { user } = useAuth();

  const updateTransaction = () =>
    transaction.status === KyckStatus.PICKUP_READY;
  return [KyckStatus.PICKUP_READY, KyckStatus.SUCCESS].includes(
    transaction.status
  ) ? (
    <CashCode
      accountNumber={phoneNumberToAccountNumber(user!.phone_number)}
      transaction={transaction}
    />
  ) : (
    <PickUpConfirmation
      orderId={transaction.order_id}
      onPickupReady={updateTransaction}
      cashoutMethod={'ncrpay360'}
    />
  );
};
