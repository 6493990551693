import {
  SelectWithCards,
  SelectWithCardsOption,
} from 'components/SelectWithCards';
import globeIcon from 'assets/icons/globe-icon.svg';
import inPersonIcon from 'assets/icons/inperson-icon.svg';
import Button from 'components/Button';
import { useState } from 'react';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';

const processes: SelectWithCardsOption[] = [
  {
    label: 'Online Process',
    value: 'online',
    src: globeIcon,
  },
  {
    label: 'In-Person Process',
    value: 'in-person',
    src: inPersonIcon,
  },
];

type SelectPaymentMethodProcessProps = {
  onSelect: (option: string) => void;
  goBack: () => void;
};

export const SelectPaymentMethodProcess = ({
  onSelect,
  goBack,
}: SelectPaymentMethodProcessProps) => {
  const [selectedProcess, setSelectedProcess] = useState('');

  const handleClick = () => {
    onSelect(selectedProcess);
  };

  return (
    <>
      <ArrowNarrowLeftIcon
        className="h-6 my-3 text-tw-gray-600 cursor-pointer"
        onClick={goBack}
      />
      <div className="text-center">
        <h3 className="my-8 text-2xl font-semibold">
          Choose your payment method process
        </h3>

        <SelectWithCards
          columns={2}
          isColumnLayout={false}
          options={processes}
          onSelectOption={setSelectedProcess}
        />

        <Button
          className="mt-10"
          onClick={handleClick}
          disabled={!selectedProcess}
        >
          Proceed
        </Button>
      </div>
    </>
  );
};
