import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { ApiResponse } from '../types/register';
import { SharedRegistrationDetails } from '../types/sharedRegistrationDetails.model';

export type RegisterPhoneCredentials = SharedRegistrationDetails & {
  phone_number: string;
};

const register = async (credentials: RegisterPhoneCredentials) => {
  const resp = await axios.post<ApiResponse>('/api/register', {
    ...credentials,
  });
  return resp.data;
};

export const useRegisterPhone = (onSuccess?: OnSuccessCallback<ApiResponse>) =>
  useUpdateApi(register, { onSuccess });
