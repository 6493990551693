import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import { useState } from 'react';
import { ModifyWalletModal } from './ModifyWalletModal';

interface PromptSaveWalletProps {
  onSuccess: () => void;
  close: () => void;
  address: string;
  crypto: string;
}
export const PromptSaveWallet = ({
  onSuccess,
  close,
  crypto,
  address,
}: PromptSaveWalletProps) => {
  const [step, setStep] = useState(0);
  return (
    <div className="flex flex-col items-center mb-5 text-center">
      {step === 0 ? (
        <div className="px-3">
          <div className="mb-3 inline-block rounded-full bg-light-green p-5">
            <QuestionMarkCircleIcon className="h-16 text-primary" />
          </div>
          <h2 className="my-3">
            Would you like to save this wallet to your account?
          </h2>
          <p>
            This wallet is currently not saved to your account, would you like
            to save it?
          </p>
          <div className="my-3 flex gap-3 justify-center">
            <Button
              className="text-gray-secondaryDark bg-white"
              size="sm"
              onClick={() => {
                close();
              }}
            >
              {' '}
              No, continue as is{' '}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setStep(1);
              }}
            >
              {' '}
              Yes, Save Wallet{' '}
            </Button>
          </div>
        </div>
      ) : (
        <ModifyWalletModal
          type={'save'}
          address={address}
          crypto={crypto}
          onSuccess={() => {
            onSuccess();
          }}
        />
      )}
    </div>
  );
};
