import { GetExchangeRateResponse, SupportedPaymentMethod } from 'common/types';
import { Loader, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { POLLING_INTERVAL_IN_MS } from '../util/constants';
import Button from 'components/Button';
import {
  currencyFormatter,
  getCryptoCurrencyTitle,
  numberFormatter,
} from 'utils/formatters';
import { BuyCryptoDTO, useBuyCrypto } from '../api/buyCrypto';
import {
  performSecureChallenge,
  logFingerprintResult,
} from '../api/threeDSecure';
import { SecureChallengeResponse } from '../types/threeDSResponse';
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/outline';

type ConfirmTransactionDetailsProps = {
  exchangeRateInfo: GetExchangeRateResponse;
  fundingSource: string;
  buyCryptoRequestBody: BuyCryptoDTO;
  minerFee: number;
  paymentMethod: string;
  onSuccess: () => void;
};

export const ConfirmTransactionDetails = ({
  exchangeRateInfo,
  fundingSource,
  buyCryptoRequestBody,
  paymentMethod,
  onSuccess,
  minerFee,
}: ConfirmTransactionDetailsProps) => {
  const totalCost =
    exchangeRateInfo.unbank_fee!.fee + exchangeRateInfo.total_cost + minerFee;

  const { isLoading, update: buyCrypto } = useBuyCrypto((resp) => {
    onSuccess();
  });

  const [paayIframeSrc, setPaayIframeUrl] = useState('');
  const [secureResult, setSecureResult] = useState<SecureChallengeResponse>();
  const [fingerprintValid, setFingerprintValid] = useState(false);

  const [check3DS, setCheck3DS] = useState(false);
  const [opened, setOpened] = useState(false);
  const [secureCounter, setSecureCounter] = useState(0);

  const [secureFailure, setSecureFailure] = useState(false);

  // Poll Fingerprint result after iframe is opened
  const getFingerprintResult = async () => {
    const result = await logFingerprintResult();
    console.log(result.data);
    if (result.data.length) {
      setFingerprintValid(true);
    } else {
      setSecureCounter(secureCounter + 1);
    }

    console.log(secureCounter);
    // Say transaction failed after 5 failed polls
    if (secureCounter >= 5) {
      setSecureFailure(true);
    }
  };
  useEffect(() => {
    if (secureResult) {
      console.log('start fingerprint result');
      const interval = setInterval(() => {
        getFingerprintResult();
      }, POLLING_INTERVAL_IN_MS);

      return () => {
        clearInterval(interval);
      };
    }
  });

  // Check for validity in fingerpint
  useEffect(() => {
    if (check3DS && fingerprintValid) {
      buyCrypto(buyCryptoRequestBody);
    }
  }, []);
  const handleBuyCrypto = async () => {
    if (paymentMethod === SupportedPaymentMethod.BANK_CARD) {
      const secureResult = await performSecureChallenge(buyCryptoRequestBody);
      setSecureResult(secureResult);
      setPaayIframeUrl(secureResult['data']['iframe-url']);

      setOpened(true);
      setCheck3DS(true);
    } else {
      buyCrypto(buyCryptoRequestBody);
    }
  };
  if (check3DS) {
    return (
      <div>
        <div className="mx-auto flex max-w-lg flex-col items-center text-center lg:w-2/3 my-5">
          <div className=" mb-4 rounded-full bg-light-green p-2">
            <ShieldCheckIcon className="h-[100px] text-primary" />
          </div>
          <div className="mx-auto">
            <h3 className="font-bold text-xl"> Performing 3DS Check</h3>

            <p className="my-2">Please give us a moment to verify your card</p>

            <div className="mt-10 text-gray-secondaryDark flex justify-center gap-2">
              <Loader size="sm" /> Verifying card for 3DS...
            </div>
          </div>
        </div>
        <Modal
          opened={opened}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => {
            setOpened(false);
            getFingerprintResult();
          }}
        >
          <iframe
            name="PAAY-Iframe"
            src={paayIframeSrc}
            height="420"
            width="100%"
            frameBorder="0"
          />
        </Modal>
      </div>
    );
  } else if (secureFailure) {
    return (
      <div>
        <div className="mx-auto flex max-w-lg flex-col items-center text-center lg:w-2/3 my-5">
          <div className=" mb-4 rounded-full bg-light-green p-2">
            <ShieldExclamationIcon className="h-[100px] text-primary" />
          </div>
          <div className="mx-auto">
            <h3 className="font-bold text-xl"> Card Verification Failure</h3>

            <p className="my-2 mb-3">
              Your bank card could not be verified, please contact support for
              further details.
            </p>

            <Button
              size="sm"
              className="bg-white border border-primary text-primary mr-3"
            >
              Contact Support
            </Button>
            <Button size="sm" className="mr-3">
              Return to Dashboard
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3 className="my-8 text-2xl font-semibold">
          Confirm your {getCryptoCurrencyTitle(exchangeRateInfo.to)} purchase of{' '}
          {currencyFormatter(exchangeRateInfo.total_cost)}
        </h3>

        <div className="space-y-4">
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span> {getCryptoCurrencyTitle(exchangeRateInfo.to)} Address</span>
            <span className="truncate">
              <p className="truncate w-64">
                {buyCryptoRequestBody.destination}
              </p>
            </span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Funding Source</span>
            <span>{fundingSource}</span>
          </div>

          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Amount</span>
            <span>
              {numberFormatter(parseFloat(exchangeRateInfo.crypto_amount), 8)}{' '}
              {exchangeRateInfo.to}
            </span>
          </div>

          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Exchange Rate</span>
            <span>
              {currencyFormatter(parseFloat(exchangeRateInfo.exchange_rate!))}
            </span>
          </div>

          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Symbol</span>
            <span>{exchangeRateInfo.to}</span>
          </div>
          <br />
          <br />
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Total Crypto Purchase</span>
            <span>{currencyFormatter(exchangeRateInfo.total_cost)}</span>
          </div>

          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Unbank Fee</span>
            <span>{currencyFormatter(exchangeRateInfo.unbank_fee!.fee)}</span>
          </div>

          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Miner Fee</span>
            <span>{currencyFormatter(minerFee)}</span>
          </div>

          <br />
          <div className="flex items-center justify-between rounded-md bg-primary py-4 px-8 font-bold text-white">
            <span>Total Cost</span>
            <span>{currencyFormatter(totalCost)}</span>
          </div>
        </div>
        <div className="mt-12 text-center">
          <Button loading={isLoading} onClick={handleBuyCrypto}>
            Confirm
          </Button>
        </div>
      </div>
    );
  }
};
