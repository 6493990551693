import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { Invoice } from '../types/invoice';

export type CreateInvoiceDTO = {
  amount: string;
  entity_id?: string;
  crypto: string;
  cashout_method: string;
};

const createInvoice = async (credentials: CreateInvoiceDTO) => {
  const resp = await axios.post<Invoice>('/api/invoice/create', {
    ...credentials,
  });
  return resp.data;
};

export const useCreateInvoice = (onSuccess: OnSuccessCallback<Invoice>) => {
  return useUpdateApi(createInvoice, { onSuccess });
};
