import { Stepper } from '@mantine/core';
import { SelectCrypto } from '../components/SelectCrypto';
import { StyledStepper } from 'components/StyledStepper';
import { useEffect, useState } from 'react';
import { BuyTransactionForm } from '../components/BuyTransactionForm';
import { SelectPaymentMethod } from '../components/SelectPaymentMethod';
import { SelectPaymentMethodProcess } from '../components/SelectPaymentMethodProcess';
import { GetExchangeRateResponse, SupportedPaymentMethod } from 'common/types';
import { ConfirmTransactionDetails } from '../components/ConfirmTransactionDetails';
import { useAuth } from 'modules/auth';
import { AddPaymentMethodModal } from 'modules/payment-methods';
import { useModals } from '@mantine/modals';
import { OrderComplete } from '../components/OrderComplete';
import { BuyCryptoDTO } from '../api/buyCrypto';
import { useGetAccountInfo } from 'modules/account';
import { AtmLocation, FindLocations } from 'modules/locations';
import { AddCash } from '../components/AddCash';
import { ProcessingDeposit } from '../components/ProcessingDeposit';
import { Transaction } from 'modules/transactions';
import { ConvertCash } from '../components/ConvertCash/ConvertCash';
import notification from 'utils/notification';
import { fundingSourceFormatter } from 'utils/formatters';
import { OrderInformation } from '../components/OrderInformation';

export const BuyPage = () => {
  const [active, setActive] = useState(0);
  const [buyCryptoRequestBody, setBuyCryptoRequestBody] = useState<
    Partial<BuyCryptoDTO>
  >({});

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [fundingSource, setFundingSource] = useState('');

  // Transaction data
  const [transaction, setTransaction] = useState<Transaction>();

  // Toggle display for amount
  const [displayAmount, setDisplayAmount] = useState(true);

  /** Show location if the user is in Greendot or Triton Flows */
  const showLocation = () => {
    if (selectedPaymentMethod === SupportedPaymentMethod.CASH) {
      return true;
    } else if (
      selectedPaymentMethod === SupportedPaymentMethod.BANK_CARD &&
      selectedPaymentMethodProcess === 'in-person'
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Buy Invoice fields
  const [selectedSpeedFee, setSelectedSpeedFee] = useState(0);
  const [selectedPaymentMethodProcess, setSelectedPaymentMethodProcess] =
    useState<string>();
  const [exchangeRateInfo, setExchangeRateInfo] =
    useState<GetExchangeRateResponse>();

  const modals = useModals();
  const { user, setUser } = useAuth();

  const nextStep = () =>
    setActive((current) => (current < 5 ? current + 1 : current));
  
  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );

  useEffect(() => fetchUserInfo({}), []);

  const handleSelectPaymentMethod = (paymentMethod: string) => {
    switch (paymentMethod) {
      case SupportedPaymentMethod.BANK_CARD:
        if (!userHasDefaultCard()) {
          return openAddPaymentMethodModal();
        } else {
          setFundingSource(
            fundingSourceFormatter(paymentMethod) + user!.defaultBankCardLast4
          );
          nextStep();
        }
        break;

      case SupportedPaymentMethod.UNBANK_CASH:
        if (parseFloat(user?.account.balance!) > 0) {
          setFundingSource(fundingSourceFormatter(paymentMethod));
          nextStep();
        } else {
          notification.notifyError(
            'Your Unbank account balance needs to be greater than 0 to proceed'
          );
        }

        break;
      case SupportedPaymentMethod.CASH:
        setFundingSource(fundingSourceFormatter(paymentMethod));
        setDisplayAmount(false);
        nextStep();
        break;
    }

    setSelectedPaymentMethod(paymentMethod);
    setBuyCryptoRequestBody((prev) => ({ ...prev, method: paymentMethod }));
  };

  // Handle Greendot transaction deposit
  const handleDeposit = (transaction: Transaction) => {
    setTransaction(transaction);
    nextStep();
  };

  const handlePaymentProcessed = (transaction: Transaction) => {
    nextStep();
  };

  /**
   * Adds location details to buy request
   * @param location
   */
  const handleSelectLocation = (location: AtmLocation) => {
    setBuyCryptoRequestBody((prev) => ({
      ...prev,
      entity_id: location.entity_id,
      retailer_key: location.retailer_key,
    }));

    nextStep();
  };

  const userHasDefaultCard = () => {
    return user!.defaultBankCardLast4 !== null;
  };

  const openAddPaymentMethodModal = () => {
    modals.openModal({
      children: (
        <AddPaymentMethodModal
          methodFilter={SupportedPaymentMethod.BANK_CARD}
          onAddCard={() => {
            setFundingSource('Card ending in ' + user!.defaultBankCardLast4);
            nextStep();
          }}
        />
      ),
    });
  };

  const handleSelectPaymentMethodProcess = (paymentMethodProcess: string) => {
    setSelectedPaymentMethodProcess(paymentMethodProcess);

    // Set payment method to triton
    if (paymentMethodProcess === 'in-person') {
      setDisplayAmount(false);
      setBuyCryptoRequestBody((prev) => ({ ...prev, method: 'triton' }));
    }
    nextStep();
  };

  const handleSelectCrypto = (exchangeRateInfo: GetExchangeRateResponse) => {
    setExchangeRateInfo(exchangeRateInfo);
    setBuyCryptoRequestBody((prev) => ({
      ...prev,
      crypto: exchangeRateInfo.to,
    }));
    if (selectedPaymentMethod !== SupportedPaymentMethod.CASH) {
      setBuyCryptoRequestBody((prev) => ({
        ...prev,
        amount: exchangeRateInfo.exchange,
      }));
    }

    nextStep();
  };

  const handleSelectTransactionSpeed = (
    speed: { label: string; amount: number },
    address: string
  ) => {
    setBuyCryptoRequestBody((prev) => ({
      ...prev,
      speed: speed.label,
      destination: address,
    }));
    setSelectedSpeedFee(speed.amount);
    nextStep();
  };

  return (
    <div className="dashboard-page min-h-[80vh]">
      {/* First Step in Buy Flow */}
      <StyledStepper active={active}>
        <Stepper.Step label="Choose your payment method">
          <SelectPaymentMethod
            onSelectPaymentMethod={handleSelectPaymentMethod}
          />
        </Stepper.Step>

        {/* Handle Payment Process Flow */}
        {selectedPaymentMethod === SupportedPaymentMethod.BANK_CARD && (
          <Stepper.Step label="Choose payment process">
            <SelectPaymentMethodProcess
              onSelect={handleSelectPaymentMethodProcess}
              goBack={() => setActive(active - 1)}
            />
          </Stepper.Step>
        )}

        {/* Display Location Based on Flow */}
        {showLocation() && (
          <Stepper.Step label="Find Location">
            <h3 className="my-8 text-center text-2xl font-semibold">
              Find Location
            </h3>
            <FindLocations
              transactionType="buy"
              onSelectLocation={handleSelectLocation}
            />
          </Stepper.Step>
        )}

        {/* Second Step in Buy Flow */}
        <Stepper.Step label="Select Crypto">
          <SelectCrypto
            paymentMethod={selectedPaymentMethod}
            onSelectCrypto={handleSelectCrypto}
            displayAmount={displayAmount}
            goBack={() => setActive(active - 1)}
          />
        </Stepper.Step>

        {/* Third Step in Buy Flow */}
        <Stepper.Step label="Enter wallet address">
          {exchangeRateInfo && (
            <BuyTransactionForm
              paymentProcess={selectedPaymentMethodProcess!}
              minerFees={exchangeRateInfo.miner_fee!}
              onSelect={handleSelectTransactionSpeed}
              crypto={exchangeRateInfo.to}
              goBack={() => setActive(active - 1)}
            />
          )}
        </Stepper.Step>

        {/* Greendot Flow 
        Add Cash -> Processing Payment -> Cash Conversion
        */}
        {selectedPaymentMethod === SupportedPaymentMethod.CASH && (
          <Stepper.Step label="Add Cash">
            <AddCash
              onDeposit={handleDeposit}
              transactionType="create"
              showBarcode={true}
              buyCryptoRequestBody={buyCryptoRequestBody as BuyCryptoDTO}
            />
          </Stepper.Step>
        )}

        {selectedPaymentMethod === SupportedPaymentMethod.CASH && (
          <Stepper.Step label="Processing Purchase">
            {transaction && (
              <ProcessingDeposit
                onPaymentProcessed={handlePaymentProcessed}
                transactionID={transaction.order_id}
              />
            )}
          </Stepper.Step>
        )}

        {/* Convert crypto to cash once transaction is completed */}
        {selectedPaymentMethod === SupportedPaymentMethod.CASH &&
          transaction && (
            <Stepper.Step label="Convert to Cash">
              <ConvertCash onSuccess={nextStep} transaction={transaction} />
            </Stepper.Step>
          )}

        {/* Triton Flow 
          Display Order Number and Poll until complete
        */}
        {selectedPaymentMethodProcess === 'in-person' &&
          selectedPaymentMethod === SupportedPaymentMethod.BANK_CARD && (
            <Stepper.Step label="Order Information">
              <OrderInformation
                buyCryptoRequestBody={buyCryptoRequestBody as BuyCryptoDTO}
                onSuccess={nextStep}
              />
            </Stepper.Step>
          )}

        {/* Hide Confirmation on Greendot and Triton Flows */}
        {selectedPaymentMethod !== SupportedPaymentMethod.CASH &&
          selectedPaymentMethodProcess !== 'in-person' && (
            <Stepper.Step label="Confirmation">
              {exchangeRateInfo && (
                <ConfirmTransactionDetails
                  paymentMethod={selectedPaymentMethod}
                  buyCryptoRequestBody={buyCryptoRequestBody as BuyCryptoDTO}
                  fundingSource={fundingSource}
                  exchangeRateInfo={exchangeRateInfo}
                  minerFee={selectedSpeedFee}
                  onSuccess={nextStep}
                />
              )}
            </Stepper.Step>
          )}

        <Stepper.Step label="Order Complete">
          {exchangeRateInfo && (
            <OrderComplete
              fundingSource={fundingSource}
              exchangeRateInfo={exchangeRateInfo}
              minerFee={selectedSpeedFee}
            />
          )}
        </Stepper.Step>
      </StyledStepper>
    </div>
  );
};
