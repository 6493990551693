import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { User } from 'common/types';
import { axios } from 'lib/axios';

const getAccountInfo = async () => {
  const resp = await axios.get<{ data: User }>('/api/account/data');
  return resp.data;
};

export const useGetAccountInfo = (
  onSuccess: OnSuccessCallback<{ data: User }>
) => {
  return useUpdateApi(getAccountInfo, { onSuccess });
};
