import Button from 'components/Button';
import TextField from 'components/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useVerifyCard } from '../api/verifyCard';
import { BankCard } from '../types/BankCard';
import { CircularCardIcon } from './CircularCardIcon';

type VerifyCardFormProps = {
  onSuccess: (card: BankCard) => void;
  cardReference: string;
};

export const VerifyCardForm = ({
  onSuccess,
  cardReference,
}: VerifyCardFormProps) => {
  const { update, isLoading } = useVerifyCard((response) => {
    onSuccess(response.data);
  });
  const { errors, touched, ...formik } = useFormik<{
    amount: string;
    cardRef: string;
  }>({
    initialValues: {
      amount: '',
      cardRef: cardReference,
    },
    validationSchema: Yup.object({
      amount: Yup.number().required(),
    }),
    onSubmit: (values) => {
      update({ ...values, amount: parseFloat(values.amount) });
    },
  });

  return (
    <div className="space-y-8 text-center">
      <h2>Verify Card</h2>
      <CircularCardIcon />
      <div className="bg-light-green px-14 py-8">
        Verify the pending hold found in your banking app. You will not be
        charged for theses transactions
      </div>
      <form className="space-y-8 md:px-24" onSubmit={formik.handleSubmit}>
        <TextField
          type="number"
          placeholder="Amount"
          {...formik.getFieldProps('amount')}
          error={errors.amount && touched.amount && errors.amount}
        />
        <Button loading={isLoading} type="submit">
          Continue
        </Button>
      </form>
    </div>
  );
};
