import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Popover } from '@mantine/core';
import { useState } from 'react';
import { useUpdateWallet } from '../api/updateWallet';
import { Wallet } from '../types/wallet';
import { getWalletIcon } from '../util/walletIcons';
import { DeleteWalletModal } from './DeleteWalletModal';
import { ModifyWalletModal } from './ModifyWalletModal';
import ResponsiveModal from 'components/Modal';

interface WalletMethodOption {
  label: string;
  handler: () => void;
}

interface CryptoWalletProps {
  wallet: Wallet;
}
export const CryptoWallet = ({ wallet }: CryptoWalletProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const { update: updateWallet } = useUpdateWallet((resp) => {});

  const onEdit = () => {
    setEditModalOpened(true);
  };

  const onDelete = () => {
    setDeleteModalOpened(true);
  };

  const setDefault = async () => {
    if (wallet.is_default) {
      wallet.is_default = false;
    } else {
      wallet.is_default = true;
    }

    const result = updateWallet(wallet);

    return result;
  };

  const options: WalletMethodOption[] = [
    {
      label: `${wallet.is_default ? 'Unmark' : 'Mark'} as Default`,
      handler: setDefault,
    },
    {
      label: 'Edit',
      handler: onEdit,
    },
    {
      label: 'Delete',
      handler: onDelete,
    },
  ];

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="h-full rounded-md p-8 text-gray-dark shadow-custom w-full">
      {console.log(wallet)}
      <article className="flex flex-col">
        <Popover
          radius="md"
          spacing="xs"
          opened={showOptions}
          onClose={toggleShowOptions}
          position="right"
          target={
            <DotsVerticalIcon
              onClick={toggleShowOptions}
              className="absolute -right-2 h-5 cursor-pointer"
            />
          }
        >
          <div>
            <ul onClick={toggleShowOptions}>
              {options.map((option) => (
                <li
                  key={option.label}
                  className="-mx-2 rounded-md py-1 pl-2 pr-6 text-sm text-tw-gray-800 hover:bg-light-green hover:text-primary"
                >
                  <button onClick={option.handler}>{option.label}</button>
                </li>
              ))}
            </ul>
          </div>
        </Popover>
        <div>
          <img
            src={getWalletIcon(wallet.crypto)}
            alt={`${wallet.crypto} icon`}
            className="h-10"
          />
          <p className="my-2 font-bold text-gray-secondaryDark">
            {' '}
            {wallet.name}
          </p>

          <p className="text-sm my-2 truncate bg-gray-light rounded py-2 px-4">
            {' '}
            {wallet.address}
          </p>
          {wallet.is_default && (
            <p className="text-sm text-primary"> Default</p>
          )}
        </div>
        <ResponsiveModal
          size="50%"
          opened={editModalOpened}
          onClose={() => {
            setEditModalOpened(false);
          }}
        >
          <ModifyWalletModal
            type={'edit'}
            onSuccess={() => {
              setEditModalOpened(false);
            }}
            wallet={wallet}
          />
        </ResponsiveModal>
        <ResponsiveModal
          opened={deleteModalOpened}
          onClose={() => {
            setDeleteModalOpened(false);
          }}
          size="40%"
        >
          <DeleteWalletModal
            walletAddress={wallet.address}
            onSuccess={() => {
              setDeleteModalOpened(false);
              window.location.reload();
            }}
          />
        </ResponsiveModal>
      </article>
    </div>
  );
};
