import { OnSuccessCallback, useFetchApi } from 'common/hooks';
import { GetExchangeRateResponse } from '../types';

type GetExchangeRateDTO = {
  from: string;
  to: string;
  exchange?: number;
};

export const useGetExchangeRate = (
  { from, to, exchange = 1 }: GetExchangeRateDTO,
  onSuccess?: OnSuccessCallback<GetExchangeRateResponse>
) => {
  return useFetchApi<GetExchangeRateResponse>(
    `/api/exchange/rates?from=${from}&to=${to}${
      exchange ? '&exchange=' + exchange : ''
    }`,
    {
      onSuccess,
    }
  );
};
