import BitcoinIcon from 'assets/icons/bitcoin-btc-logo.svg';
import DogecoinIcon from 'assets/icons/dogecoin-doge-logo.png';
import EtheriumIcon from 'assets/icons/ethereum-eth-logo.png';
import LitecoinIcon from 'assets/icons/Litecoin.svg';
import { SupportedCurrencies } from '../types/wallet';

export const getWalletIcon = (crypto: string) => {
  switch (crypto.toLowerCase()) {
    case 'btc':
      return BitcoinIcon;
    case 'ltc':
      return LitecoinIcon;

    case 'doge':
      return DogecoinIcon;

    case 'eth':
      return EtheriumIcon;
  }
};

export const getCryptoCurrencies = () => {
  let selectArr = [];
  for (let entry of Object.entries(SupportedCurrencies)) {
    selectArr.push({
      value: entry[0],
      label: entry[1],
      image: getWalletIcon(entry[0]),
    });
  }

  return selectArr;
};
