import { Stepper } from '@mantine/core';
import { StyledStepper } from 'components/StyledStepper';
import { useAuth } from 'modules/auth';
import { AtmLocation, FindLocations } from 'modules/locations';
import { Transaction } from 'modules/transactions';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CashCode } from '../components/CashCode';
import { PaymentPage } from '../components/PaymentPage';
import { PickUpConfirmation } from '../components/PickUpConfirmation';
import { SelectCrypto } from '../components/SelectCrypto';
import { SelectSellMethod } from '../components/SelectSellMethod';
import { Invoice } from '../types/invoice';
import { phoneNumberToAccountNumber } from '../utils';
import { Modal } from '@mantine/core';

enum SellPageStep {
  SELECT_METHOD = 0,
  FIND_LOCATIONS = 1,
  CHOOSE_CRYPTO = 2,
  MAKE_PAYMENT = 3,
  PAYMENT_CONFIRMATION = 4,
  PICKUP_INSTRUCTIONS = 5,
}

export const SellPage = () => {
  const [active, setActive] = useState(0);
  const [entity_id, setEntity_id] = useState<string>();
  const [selectedSellMethod, setSelectedSellMethod] =
    useState<string>('ncrpay360');

  const [addModalOpened, setAddModalOpened] = useState(false);
  const [sellInvoice, setSellInvoice] = useState<Invoice>();
  const [searchParams] = useSearchParams();
  const { user } = useAuth();

  useEffect(() => {
    const incomingEntityId = searchParams.get('location');

    if (
      incomingEntityId &&
      typeof parseInt(incomingEntityId, 10) === 'number'
    ) {
      setEntity_id(incomingEntityId);
      setActive(SellPageStep.CHOOSE_CRYPTO);
    }
  }, []);

  const nextStep = () => {
    setActive((current) => {
      console.log(current);
      return current < 4 ? current + 1 : current;
    });
  };

  const handleSelectSellMethod = (sellMethod: string) => {
    // Store sell method in state
    setSelectedSellMethod(sellMethod);
    nextStep();
  };

  const handleSelectLocation = (location: AtmLocation) => {
    setEntity_id(location.entity_id);
    nextStep();
  };

  const handleUpdateInvoice = (sellInvoice: Invoice) => {
    setSellInvoice(sellInvoice);
    nextStep();
  };

  const handleUpdateTransaction = (transaction: Transaction) => {
    setSellInvoice((prev) => ({ ...prev!, last_transaction: transaction }));
    nextStep();
  };

  return (
    <div className="dashboard-page min-h-[80vh]">
      <StyledStepper active={active}>
        <Stepper.Step label="Choose Cashout Method">
          <SelectSellMethod onSelectSellMethod={handleSelectSellMethod} />
        </Stepper.Step>

        {/* Display Add Cashout Method if Method is not found */}
        <Modal
          opened={addModalOpened}
          title="test"
          size="50%"
          // Reset component state on close
          onClose={() => {
            setAddModalOpened(false);
          }}
        >
          <div>this is a modal</div>
        </Modal>
        {/* Display steps based on cashout method */}
        {selectedSellMethod === 'ncrpay360' && (
          <Stepper.Step label="Find Location">
            <h3 className="my-8 text-center text-2xl font-semibold">
              Find Location
            </h3>
            <FindLocations
              transactionType="sell"
              onSelectLocation={handleSelectLocation}
            />
          </Stepper.Step>
        )}

        <Stepper.Step label="Choose Crypto">
          {
            <SelectCrypto
              onSelectCrypto={handleUpdateInvoice}
              entity_id={entity_id}
              cashoutMethod={selectedSellMethod}
            />
          }
        </Stepper.Step>
        <Stepper.Step label="QR Code">
          {sellInvoice && (
            <PaymentPage
              sellInvoice={sellInvoice}
              onPaymentReceived={() => nextStep()}
              onRestartProcess={() => setActive(SellPageStep.FIND_LOCATIONS)}
            />
          )}
        </Stepper.Step>

        <Stepper.Step label="Process Payment">
          {sellInvoice && (
            <PickUpConfirmation
              cashoutMethod={selectedSellMethod}
              onPaymentProcessed={() => nextStep()}
              onPickupReady={handleUpdateTransaction}
              orderId={sellInvoice.order_id}
            />
          )}
        </Stepper.Step>

        {selectedSellMethod === 'ncrpay360' && (
          <Stepper.Step label="Receive Cash Code">
            {sellInvoice && sellInvoice.last_transaction && (
              <CashCode
                accountNumber={phoneNumberToAccountNumber(user!.phone_number)}
                transaction={sellInvoice.last_transaction!}
              />
            )}
          </Stepper.Step>
        )}

        <Stepper.Step label="Transaction Complete">
          {sellInvoice && (
            <div>
              <h1> Test </h1>
            </div>
          )}
        </Stepper.Step>
      </StyledStepper>
    </div>
  );
};
