import logo from 'assets/imgs/unbank-logo.svg';

type UnsupportedLocationProps = {
  coloredBackground?: boolean;
};

const UnsupportedLocation = ({
  coloredBackground = false,
}: UnsupportedLocationProps) => {
  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-center  ${
        coloredBackground ? 'bg-light-green' : 'bg-white'
      }`}
    >
      <img src={logo} className="mb-8 h-16" alt="unbank logo" />
      <div className=" text-secondary text-center w-1/3">
        <h2 className="pl-4 text-xl">
          The Unbank App is Unavailable in your location
        </h2>
        <p className="pr-4 text-sm">
          We are sorry, but we do not currently operate within your state or
          country. You can view a list of the states we operate in{' '}
          <a
            href="https://unbank.com/states-and-countries-where-we-are-licensed-to-operate/"
            className="underline"
            target="Unsupported Locations"
          >
            here{' '}
          </a>
        </p>
      </div>
    </div>
  );
};

export default UnsupportedLocation;
