import { ReactElement, useState, useEffect } from "react";
import Button from 'components/Button';
import { useAccountService } from 'modules/account/providers/AccountProvider';
import ResponsiveModal from "components/Modal";
import BankAccount from "modules/account/models/BankAccount";
import BankAccountSkeletonLoader from "../BankAccountSkeletonLoader";
import { useAuth } from "modules/auth";


export type Props = {
    bankAccounts?: BankAccount[];
};

export function BankAccountCashOut({ bankAccounts = [] }: Props): ReactElement{
    const { user  } = useAuth();
    // If the user hasnt connected a bank account yet
    const mxConnectId = user?.mx_connect_id ? user.mx_connect_id : "";
    const accountService = useAccountService();
    const [isWidgetLoading, setIsWidgetLoading] = useState(false);
    const [isBankAccountLoading, setIsBankAccountLoading] = useState(false);
    const [widgetUrl, setWidgetUrl] = useState("");
    const [isModalActive, setIsModalActive] = useState(false);
    const [fetchedBankAccounts, setFetchedBankAccounts] = useState<BankAccount[]>(bankAccounts);


    const fetchWidgetUrl = async () => {
        setIsWidgetLoading(true);

        const widgetResponse = await accountService.fetchWidgetUrl(mxConnectId);

        setWidgetUrl(widgetResponse.url);
        setIsModalActive(true);
        setIsWidgetLoading(false);
    }

    useEffect(() => {
        const fetchConnectedAccounts = async () =>  {
          setIsBankAccountLoading(true);
          if (mxConnectId) {
            const response = await accountService.fetchConnectedBankAccounts(mxConnectId);
            setFetchedBankAccounts(BankAccount.createBankAccountsFromResponse(response.accounts));
          }
          setIsBankAccountLoading(false);
        };
    
        fetchConnectedAccounts()
        .catch((error) => {
            setIsBankAccountLoading(false);
            console.error(error);
        });
    
      }, [accountService, mxConnectId]);

    const watchMxIframe = ({ data }: MessageEvent) => {
        switch (data.type) {
            case "mx/connect/memberConnected": 
                setIsModalActive(false);
            break;

            case "mx/connect/memberStatusUpdate":
                setIsModalActive(false);
            break;
        }
    }

    window.addEventListener("message", watchMxIframe);

    return (
        <div className="w-full"> 
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl">Bank Accounts</h1>
                <div>
                <Button data-testid="account-addbankcashout-button" className="flex flex-row w-full md:w-auto" onClick={fetchWidgetUrl}>
                    {
                    isWidgetLoading ? 
                        (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mr-3 inline-block" viewBox="0 0 24 24"><path fill="#fff" stroke="#fff" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path fill="#fff" stroke="#fff" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
                        ) 
                        :
                        (
                            <svg className="h-4 mr-3 inline-block" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.5 18H18V28.5H11V18H0.5V11H11V0.5H18V11H28.5V18Z" fill="white"/>
                            </svg>
                        )
                    }
                    Connect A Bank Account
                </Button>
                </div>
            </div> 
            <div className="grid grid-cols-3 gap-4">
                { isBankAccountLoading ? 
                    (<BankAccountSkeletonLoader/>) 
                :
                    fetchedBankAccounts?.map((account: BankAccount) => {
                        return (
                            <div className="flex items-center cursor-pointer p-4 shadow-sm border border-gray-light transition-shadow hover:shadow-md rounded-sm" key={account.payeeId} data-testid="account-addbankcashout-account-item">
                                <div className="p-2 mr-2">
                                    {
                                    account.institutionLogo === "" ? 
                                        (
                                            <svg className="h-16" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_2795_1161)">
                                                <path d="M107.76 131.56H23.8C10.71 131.56 0 120.85 0 107.76V23.8C0 10.71 10.71 0 23.8 0H107.76C120.85 0 131.56 10.71 131.56 23.8V107.76C131.56 120.85 120.85 131.56 107.76 131.56Z" fill="#3CCE49"/>
                                                <path d="M108.82 39.7896L66.3601 19.8396L22.5901 39.7895L108.82 39.7896Z" fill="white" stroke="white" strokeWidth="8.4499" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M98.1499 92.9697L98.1499 47.9197" stroke="white" strokeWidth="9.1206" strokeMiterlimit="10"/>
                                                <path d="M76.4199 92.9697L76.4199 47.9197" stroke="white" strokeWidth="9.9744" strokeMiterlimit="10"/>
                                                <path d="M54.6899 92.9697L54.6899 47.9197" stroke="white" strokeWidth="9.9744" strokeMiterlimit="10"/>
                                                <path d="M32.96 92.9697L32.96 47.9197" stroke="white" strokeWidth="9.9744" strokeMiterlimit="10"/>
                                                <path d="M103.45 92.9697L27.5099 92.9697" stroke="white" strokeWidth="9.612" strokeMiterlimit="10" strokeLinecap="round"/>
                                                <path d="M102.81 47.9199L28.1601 47.9199" stroke="white" strokeWidth="5.7423" strokeMiterlimit="10" strokeLinecap="round"/>
                                                <path d="M22.76 101.1L108.76 101.1C109.86 101.1 110.76 102 110.76 103.1L110.76 108.35C110.76 109.45 109.86 110.35 108.76 110.35L22.76 110.35C21.66 110.35 20.76 109.45 20.76 108.35L20.76 103.1C20.76 102 21.66 101.1 22.76 101.1Z" fill="white"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_2795_1161">
                                                <rect width="131.56" height="131.56" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        ) 
                                        : 
                                        (
                                            <img className="max-h-16" src={account.institutionLogo} alt="institution logo" />
                                        )
                                }
                                </div>
                                <div className="w-full">
                                    <p className="text-lg font-medium mb-1">{ account.accountType }</p>
                                    <p className="text-primary">{ account.institutionName }</p>
                                    <p className="text-primary">{ account.accountNumber }</p>
                                </div>
                                <div>
                                    <svg className="h-4" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.5625 28.0961L11.9725 16.2252L0.5625 4.32841L4.07518 0.673828L19.0227 16.2252L4.07518 31.7766L0.5625 28.0961Z" fill="#C1C1C1"/>
                                    </svg>
                                </div>
                            </div>
                        )
                    })
                } 
            </div>
            
            {!!widgetUrl && 
            
            <ResponsiveModal
                opened={isModalActive}
                size="50%"
                onClose={() => { setIsModalActive(false) }}
                title="Add A Bank Account"
                > 
                <div>
                    <iframe className="h-full w-full"  data-testid="account-addbankcashout-iframe" style={{height: "600px"}} title="MxConnectWidget" src={widgetUrl}></iframe>
                </div>
            </ResponsiveModal>
            }
 
        </div>

    )
};