import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

export type VerifyEmailDTO = {
  code: string;
};

const verifyEmail = async ({ code }: VerifyEmailDTO) => {
  const resp = await axios.post('/api/account/verify/email', { code });
  return resp.data;
};

export const useVerifyEmail = (onSuccess: OnSuccessCallback) => {
  return useUpdateApi(verifyEmail, {
    onSuccess,
    successNotificationText: 'Successfully verified email!',
  });
};
