import { OnSuccessCallback, useUpdateApi } from "common/hooks";
import { axios } from "lib/axios";
import { WalletResponse, UpdateWalletRequest } from "../types/wallet";

export const updateWallet = async (credentials: UpdateWalletRequest) => {
    const resp = await axios.post<WalletResponse>('/api/account/wallet', {
        ...credentials
    });
    return resp.data;
}

export const useUpdateWallet = (
    onSuccess: OnSuccessCallback<WalletResponse>
) => {
    return useUpdateApi(updateWallet, { onSuccess, successNotificationText: 'Wallet Successfully Updated' });
};
