import { Stepper } from '@mantine/core';
import { useState } from 'react';
import EmailRegistrationForm from '../components/EmailRegistrationForm';
import { EmailVerificationForm } from '../components/EmailVerificationForm';
import { PhoneVerificationForm } from '../components/PhoneVerificationForm';
import RegistationForm from '../components/RegistationForm';
import ConfirmationSection from '../components/ConfirmationSection';
import Header from 'components/Header';
import { StyledStepper } from 'components/StyledStepper';
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
  const [active, setActive] = useState(0);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const navigate = useNavigate();

  const handleRegister = (phoneNumber: string, password: string) => {
    setPassword(password);
    setPhoneNumber(phoneNumber);
    nextStep();
  };

  const verifyEmail = (email: string) => {
    setUserEmail(email);
    setIsVerifyingEmail(true);
  };

  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));

  return (
    <>
      <Header />
      <div className="mx-8 my-8 flex min-h-[calc(100vh-88px)] max-w-4xl flex-col items-center pt-2 lg:mx-auto">
        <StyledStepper active={active}>
          <Stepper.Step label="Registration">
            <RegistationForm onRegister={handleRegister} />
          </Stepper.Step>

          <Stepper.Step label="Verify Phone">
            <PhoneVerificationForm
              onComplete={nextStep}
              buttonLabel="Continue"
              phone={phoneNumber}
            />
          </Stepper.Step>

          <Stepper.Step label="Verify Email">
            {isVerifyingEmail ? (
              <EmailVerificationForm
                onComplete={() => navigate('/auth/login')}
                buttonLabel="Continue"
                email={userEmail}
              />
            ) : (
              <>
                <EmailRegistrationForm onComplete={verifyEmail} />
              </>
            )}
          </Stepper.Step>
          <Stepper.Step label="Confirmation">
            <ConfirmationSection
              phoneNumber={phoneNumber}
              password={password}
            />
          </Stepper.Step>
        </StyledStepper>
      </div>
    </>
  );
};

export default RegistrationPage;
