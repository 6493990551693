import storage from 'utils/storage';

export default function siftTracker() {
    const user = storage.getUser();
    const _user_id = user?.id;
    const _session_id = storage.getToken();

    window.addEventListener('load', function() {
        const siftScript = document.createElement('script');
        siftScript.src = 'https://cdn.sift.com/s.js';
        document.body.appendChild(siftScript);

        // Wait until the sift script has loaded to push
        siftScript.onload = function() {
            if (_user_id && _session_id) {
                const _sift = (window._sift = window._sift || []);
                _sift.push(['_setAccount', process.env.REACT_APP_SIFT_BEACON_KEY]);
                _sift.push(['_setUserId', _user_id]);
                _sift.push(['_setSessionId', _session_id]);
                _sift.push(['_trackPageview']);
            }
        }
    }, false);  
}
