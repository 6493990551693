import { ModalProps, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { detectViewport } from 'utils/layout';

const ResponsiveModal = (props: ModalProps) => {
  const [viewport, setViewport] = useState(detectViewport());

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  return (
    <>
      {viewport === 'mobile' ? (
        <BottomSheet
          open={props.opened}
          snapPoints={({ minHeight }) => minHeight}
          onDismiss={props.onClose}
          header={props.title}
          blocking={true}
        >
          <div className="px-5">{props.children}</div>
        </BottomSheet>
      ) : (
        <Modal {...props}>{props.children}</Modal>
      )}
    </>
  );
};

export default ResponsiveModal;
