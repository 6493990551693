import { FindLocations } from '../components/FindLocations';

export const FindLocationsPage = () => (
  <div className="dashboard-page">
    <header className="mb-16">
      <h2 className="text-secondary">Find Locations</h2>
      <p>View a list of locations where you can transact Unbank business.</p>
    </header>
    <FindLocations transactionType="both" />
  </div>
);
