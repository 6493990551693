import { DotsVerticalIcon } from '@heroicons/react/solid';
import { BankCard } from '../types/BankCard';
import { Popover } from '@mantine/core';
import { useState } from 'react';

type BankCardCardProps = {
  card: BankCard;
  userName: string;
  onDelete: () => void;
  onUpdate: () => void;
};

type CardOption = {
  label: string;
  handler: () => void;
};

export const BankCardCard = ({
  card,
  userName,
  onDelete,
  onUpdate,
}: BankCardCardProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const options: CardOption[] = [
    {
      label: 'Delete',
      handler: onDelete,
    },
    {
      label: 'Make Default',
      handler: onUpdate,
    },
  ];

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <button className="h-48 rounded-md p-8 text-gray-dark shadow-custom">
      <div className="relative flex h-full justify-between">
        <div className="mt-10 flex flex-col justify-between text-left">
          {Boolean(card.is_default) && (
            <span className="absolute top-0 text-sm">*Default</span>
          )}

          <div className="flex items-center text-lg">
            **** **** **** {card.last_4}
          </div>
          <div className="w-[180px] overflow-hidden overflow-ellipsis whitespace-nowrap text-black">
            {userName}
          </div>
        </div>
        <div className="flex flex-col items-end justify-between gap-4">
          <Popover
            radius="md"
            spacing="xs"
            opened={showOptions}
            onClose={toggleShowOptions}
            position="right"
            target={
              <DotsVerticalIcon
                onClick={toggleShowOptions}
                className="relative -right-2 h-5 cursor-pointer"
              />
            }
          >
            <div>
              <ul onClick={toggleShowOptions}>
                {options.map((option) => (
                  <li
                    key={option.label}
                    className="-mx-2 rounded-md py-1 pl-2 pr-6 text-sm text-tw-gray-800 hover:bg-light-green hover:text-primary"
                  >
                    <button onClick={option.handler}>{option.label}</button>
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
          <div className="flex items-center gap-2">
            <div className="flex flex-col text-[10px] leading-tight">
              <span>VALID</span>
              <span>THRU</span>
            </div>
            <span className="text-xs text-black">
              {card.expiry_month}/{card.expiry_year}
            </span>
          </div>
        </div>
      </div>
    </button>
  );
};
