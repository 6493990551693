import { useState, useMemo, useCallback } from 'react';

type SortConfig = {
  sortBy: string;
  isAscending: boolean;
};

export function useSortableData<TData extends Record<string, any>>(
  data: TData[],
  initialConfig?: SortConfig
) {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sortBy: initialConfig?.sortBy ?? '',
    isAscending: initialConfig?.isAscending ?? false,
  });

  const sortData = useCallback((arr: TData[], sortConfig: SortConfig) => {
    const { sortBy, isAscending } = sortConfig;
    let sortedItems = [...arr];
    sortedItems.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        return isAscending ? 1 : -1;
      }
      if (a[sortBy] < b[sortBy]) {
        return isAscending ? -1 : 1;
      }
      return 0;
    });
    return sortedItems;
  }, []);

  const sortedItems = useMemo(
    () => sortData(data, sortConfig),
    [data, sortConfig, sortData]
  );

  function requestSort(field: string) {
    setSortConfig((prev) => ({
      sortBy: field,
      isAscending: prev.sortBy === field ? !prev.isAscending : prev.isAscending,
    }));
  }

  return { sortedItems, requestSort, sortConfig };
}
