import React, { ReactElement } from "react"
import ContentLoader from "react-content-loader"


export default function BankAccountSkeletonLoader(): ReactElement{
    return(
        <ContentLoader 
        speed={2}
        width={600}
        height={150}
        viewBox="0 0 800 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
    <rect x="0" y="0" rx="5" ry="5" width="350" height="150" /> 
    <rect x="380" y="0" rx="5" ry="5" width="350" height="150" />
    </ContentLoader>
    );
}