import { Radio, RadioGroup } from '@mantine/core';
import TextField from 'components/TextField';
import {
  ForgotPasswordDTO,
  useForgotPassword,
} from 'modules/auth/api/forgotPassword';
import { ConfirmationMedium } from 'modules/auth/types/confirmationMedium';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { stripUnusedKey } from 'modules/auth/utils';
import { ArrowSmRightIcon } from '@heroicons/react/solid';
import Button from 'components/Button';

type ForgotPasswordFormProps = {
  onFormSubmit: (value: ForgotPasswordDTO) => void;
};

export const ForgotPasswordForm = ({
  onFormSubmit,
}: ForgotPasswordFormProps) => {
  const [confirmationMedium, setConfirmationMedium] =
    useState<ConfirmationMedium>('phone_number');

  const handleChange = (val: ConfirmationMedium) => {
    formik.resetForm();
    setConfirmationMedium(val);
  };
  const { isLoading, update } = useForgotPassword(() => {
    onFormSubmit(stripUnusedKey(formik.values));
  });

  const verbage = {
    phoneVerbage: `Enter the phone number associated with your account and we'll send a text message with instructions to change your password`,
    emailVerbage: `Enter the email address associated with your account and we'll send an email with instruction
    to change your password`,
  };
  const { errors, touched, ...formik } = useFormik<ForgotPasswordDTO>({
    initialValues: {
      email: '',
      phone_number: '',
    },
    validationSchema: Yup.object().shape(
      {
        email: Yup.string().when('phone_number', {
          is: (phone_number: string) =>
            !phone_number || phone_number.length === 0,
          then: Yup.string().email().required('Email Address is required'),
          otherwise: Yup.string(),
        }),

        phone_number: Yup.string().when('email', {
          is: (email: string) => !email || email.length === 0,
          then: Yup.string().required('Phone number is required'),
          otherwise: Yup.string(),
        }),
      },
      [['email', 'phone_number']]
    ),
    onSubmit: (values) => {
      const finalValue = stripUnusedKey(values);
      update(finalValue);
    },
  });

  return (
    <>
      <p className="mb-4 text-sm">
        Where would you like to receive your comfirmation code?
      </p>
      <RadioGroup
        classNames={{
          radio: 'checked:bg-primary',
        }}
        name="confirmationMedium"
        value={confirmationMedium}
        onChange={handleChange}
      >
        <Radio data-autofocus value="phone_number">
          Phone
        </Radio>
        <Radio value="email">Email</Radio>
      </RadioGroup>
      <form className="mt-8 accent-primary" onSubmit={formik.handleSubmit}>
        <p className="mb-4 text-sm">
          {confirmationMedium === 'email'
            ? verbage.emailVerbage
            : verbage.phoneVerbage}
        </p>
        {confirmationMedium === 'email' ? (
          <TextField
            type="email"
            {...formik.getFieldProps('email')}
            label="Email Address"
            placeholder="example@example.com"
            error={errors.email && touched.email && errors.email}
          />
        ) : (
          <TextField
            type="tel"
            label="Phone Number"
            {...formik.getFieldProps('phone_number')}
            placeholder="(555)-555-5555"
            error={
              errors.phone_number && touched.phone_number && errors.phone_number
            }
          />
        )}

        <Button
          className="mt-8"
          type="submit"
          loading={isLoading}
          icon={<ArrowSmRightIcon className="h-6" />}
          fullWidth={true}
        >
          Send Instructions
        </Button>
      </form>
    </>
  );
};
