import { useState } from 'react';
import { RadioOption } from './RadioOption';

export type SelectWithCardsOption = {
  label: string;
  body?: string;
  limit?: string;
  value: string;
  src: string;
  disabled?: boolean;
};

type SelectWithCardsProps = {
  options: SelectWithCardsOption[];
  initialSelection?: string;
  isColumnLayout?: boolean;
  columns?: 2 | 3;
  onSelectOption: (option: string) => void;
};

export const SelectWithCards = ({
  onSelectOption,
  options,
  initialSelection,
  isColumnLayout = true,

  columns = 3,
}: SelectWithCardsProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    initialSelection ?? ''
  );

  const handleClick = (value: string) => {
    setSelectedPaymentMethod(value);

    onSelectOption(value);
  };

  const getGridCols = (): string => {
    switch (columns) {
      case 2:
        return 'lg:grid-cols-2';
      case 3:
        return 'lg:grid-cols-3';
    }
  };

  return (
    <div
      className={`mx-auto my-10 grid max-w-7xl grid-cols-1 gap-6 ${getGridCols()}`}
    >
      {options.map(({ label, src, value, disabled, body, limit }) => (
        <RadioOption
          isColumnLayout={isColumnLayout}
          key={value}
          label={label}
          disabled={disabled}
          src={src}
          limit={limit}
          body={body}
          onSelect={() => handleClick(value)}
          isSelected={selectedPaymentMethod === value}
        />
      ))}
    </div>
  );
};
