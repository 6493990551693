import {
  BillingInformation,
  CardDetails,
  useAddBankCard,
} from '../api/addBankCard';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { Select } from '@mantine/core';
import { useEffect, useState } from 'react';

type BillingInfoFormProps = {
  cardDetails: CardDetails;
  clientIp: string;
  onSubmit: (cardRef: string) => void;
};

const stateData: { label: string; value: string }[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const BillingInfoForm = ({
  onSubmit,
  cardDetails,
  clientIp,
}: BillingInfoFormProps) => {
  const [state, setState] = useState<string | null>('');

  useEffect(() => {
    if (state) {
      formik.setFieldValue('state', state);
    }
  }, [state]);

  const { isLoading, update } = useAddBankCard((resp) => {
    onSubmit(resp.data.card_ref);
  });

  const { errors, touched, ...formik } = useFormik<BillingInformation>({
    initialValues: {
      city: '',
      state: '',
      street_address: '',
      zip_code: '',
      country: 'US',
    },
    validationSchema: Yup.object({
      city: Yup.string().required(),
      state: Yup.string().required(),
      street_address: Yup.string().required(),
      zip_code: Yup.string().required(),
      country: Yup.string().required(),
    }),
    onSubmit: (values) => {
      update({
        ...cardDetails,
        ...values,
        ignore_duplicates: false,
        software: 'webapp',
        capture: true,
        transaction_details: {
          description: 'Verify Bank Card',
          client_ip: clientIp,
        },
        save_card: true,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-8">
      <TextField
        placeholder="Street Address"
        {...formik.getFieldProps('street_address')}
        error={
          errors.street_address &&
          touched.street_address &&
          errors.street_address
        }
      />
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        <TextField
          placeholder="City"
          {...formik.getFieldProps('city')}
          error={errors.city && touched.city && errors.city}
        />
        <Select
          placeholder="State"
          searchable
          nothingFound="No states match"
          variant="filled"
          size="lg"
          styles={{
            input: { fontSize: '18px', height: '60px' },
          }}
          {...formik.getFieldProps('state')}
          value={state}
          onChange={(val) => setState(val)}
          error={errors.state && touched.state && errors.state}
          data={stateData}
        />
        <TextField
          placeholder="Zip Code"
          {...formik.getFieldProps('zip_code')}
          error={errors.zip_code && touched.zip_code && errors.zip_code}
        />
      </div>
      <TextField
        {...formik.getFieldProps('country')}
        disabled={true}
        error={errors.country && touched.country && errors.country}
      />
      <div className="text-center">
        <Button loading={isLoading} type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};
