import { ServiceProvider } from 'common/types';
import { Transaction } from './types/transaction';
import { TransactionFilterConfig } from './types/TransactionFilterConfig';

export const getPaymentType = (serviceProvider: string) => {
  switch (serviceProvider) {
    case ServiceProvider.GREEN_DOT:
      return 'Cash';
    case ServiceProvider.MAGIC_PAY:
      return 'Bank Card';
    case ServiceProvider.KYCK:
      return 'NCRpay360';
    case ServiceProvider.UNBANK:
      return 'Account Balance'

    case ServiceProvider.TRITON:
      return 'Triton'
    default:
      return 'Unknown Payment Type';
  }
};

export const filterTransactions = (
  transactions: Transaction[],
  config: TransactionFilterConfig
) => {
  return transactions.filter((t) => {
    const { searchTerm, searchFieldName, ...rest } = config;

    if (
      searchFieldName &&
      searchTerm &&
      (t[searchFieldName] === undefined ||
        !t[searchFieldName]
          .toLocaleLowerCase()
          .startsWith(searchTerm.toLowerCase()))
    ) {
      return false;
    }
    for (let key in rest) {
      if (config[key] !== undefined) {
        if (t[key] === undefined || t[key] !== config[key]) {
          return false;
        }
      }
    }
    return true;
  });
};

/**
 * 
 * @param limit 
 * @param limitSum 
 */
export const calculateLimitPercentage = (limit: number, limitSum: number) => {
  return (limitSum / limit) * 100;
}

export const calculateRemainingLimit = (limit: number, limitSum: number) => {
  return limit - limitSum
}