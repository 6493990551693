import { OnSuccessCallback, useFetchApi } from 'common/hooks';
import { GetBankCardsResponse } from '../types/GetBankCardsResponse';

export type GetBankCardsDTO = {
  bank_card_id: number;
};

export const useGetBankCards = (
  onSuccess: OnSuccessCallback<GetBankCardsResponse>
) => {
  return useFetchApi<GetBankCardsResponse>(`/api/account/bank/cards`, {
    onSuccess,
  });
};
