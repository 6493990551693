import { BankCardCard } from '../components/BankCardCard';
import { Loader, LoadingOverlay } from '@mantine/core';
import { useModals } from '@mantine/modals';

import addCardIcon from 'assets/icons/add-card-icon.svg';
import { useAuth } from 'modules/auth';
import { useMemo, useState } from 'react';
import { useGetBankCards } from '../api/getBankCards';
import { AddPaymentMethodModal } from '../components/AddPaymentMethodModal';
import { CircularCardIcon } from '../components/CircularCardIcon';
import { BankCard } from '../types/BankCard';
import { useDeleteBankCard } from '../api/deleteBankCard';
import { SupportedPaymentMethod } from 'common/types';
import { useMakeCardDefault } from '../api/makeCardDefault';
import ResponsiveModal from 'components/Modal';
export const PaymentMethods = () => {
  const [bankCards, setBankCards] = useState<BankCard[]>([]);
  const [paymentMethodModalOpened, setPaymentMethodModalOpened] =
    useState(false);
  const [cardRefToDelete, setCardRefToDelete] = useState('');
  const { user } = useAuth();

  const { isLoading } = useGetBankCards((data) => {
    setBankCards(data.rows);
  });

  const deleteQuery = useDeleteBankCard(() => {
    setBankCards((cards) =>
      cards.filter((card) => card.card_ref !== cardRefToDelete)
    );
    setCardRefToDelete('');
  });

  const updateQuery = useMakeCardDefault((resp) => {
    const card = resp.data;
    setBankCards((cards) =>
      cards.map((c) => (c.card_ref === card.card_ref ? card : c))
    );
  });

  const modals = useModals();

  const handleAddCard = (card: BankCard) => {
    setBankCards([...bankCards, card]);
    modals.closeAll();
  };

  const handleDeleteCard = (cardRef: string) => {
    modals.closeAll();
    deleteQuery.update(cardRef);
    setCardRefToDelete(cardRef);
  };

  const handleUpdateCard = (cardRef: string) => {
    updateQuery.update(cardRef);
  };

  const handleOpenAddPaymentMethodModal = () => {
    setPaymentMethodModalOpened(true);
  };

  const openDeleteConfirmationModal = (cardRef: string) => {
    modals.openModal({
      title: 'Delete Card',
      size: 'xs',
      centered: true,
      children: (
        <div className="">
          Are you sure you want to delete this card?
          <div className="space-x-4 text-right">
            <button
              onClick={modals.closeAll}
              className="btn bg-white text-tw-gray-700"
            >
              Cancel
            </button>
            <button
              className="btn bg-red-500 text-white"
              onClick={() => handleDeleteCard(cardRef)}
            >
              DELETE
            </button>
          </div>
        </div>
      ),
    });
  };

  const dummyCardCount = useMemo(
    () => (bankCards.length === 1 ? 2 : 1),
    [bankCards]
  );

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div>
      <LoadingOverlay
        loaderProps={{ color: '#3BCE49', size: 'lg' }}
        visible={deleteQuery.isLoading || updateQuery.isLoading}
      />
      <h1 className="text-2xl font-bold">Payment Methods</h1>
      <p>You can add or view payment methods here</p>

      {bankCards.length === 0 ? (
        <div className="my-24 flex flex-col items-center">
          <CircularCardIcon />
          <div className="flex items-center gap-2">
            <img src={addCardIcon} className="h-6" alt="" />
            <button
              onClick={handleOpenAddPaymentMethodModal}
              className="text-lg text-primary hover:underline"
            >
              Add Payment Method
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="my-6 mx-auto grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3">
            {bankCards.map((card) => (
              <BankCardCard
                key={card.card_ref}
                userName={user!.name}
                card={card}
                onUpdate={() => handleUpdateCard(card.card_ref)}
                onDelete={() => openDeleteConfirmationModal(card.card_ref)}
              />
            ))}
            {Array(dummyCardCount)
              .fill('')
              .map((_, index) => (
                <button
                  key={index}
                  onClick={handleOpenAddPaymentMethodModal}
                  className="group grid h-48 place-items-center rounded-md p-8 text-gray-dark shadow-custom"
                >
                  <div className="flex items-center gap-2">
                    <img src={addCardIcon} className="h-6" alt="" />
                    <div className="text-lg text-primary group-hover:underline">
                      Add Payment Method
                    </div>
                  </div>
                </button>
              ))}
          </div>
        </>
      )}
      <ResponsiveModal
        opened={paymentMethodModalOpened}
        closeOnClickOutside={false}
        onClose={() => setPaymentMethodModalOpened(false)}
        size="2xl"
      >
        <AddPaymentMethodModal
          methodFilter={SupportedPaymentMethod.BANK_CARD}
          onAddCard={handleAddCard}
        />
      </ResponsiveModal>
    </div>
  );
};
