import checkMark from 'assets/icons/check-mark.svg';

export type RadioOptionProps = {
  label: string;
  src: string;
  isColumnLayout?: boolean;
  isSelected?: boolean;
  body?: string;
  limit?: string;
  disabled?: boolean;
  onSelect: () => void;
};

export const RadioOption = ({
  label,
  src,
  isColumnLayout = true,
  isSelected = false,
  body = '',
  limit = '',
  disabled = false,
  onSelect,
}: RadioOptionProps) => {
  return (
    <button
      onClick={disabled ? undefined : onSelect}
      className={`${isSelected ? 'border-primary' : 'border-white'} ${
        isColumnLayout ? 'lg:flex-col  lg:py-10' : 'flex-row'
      } ${
        disabled
          ? 'cursor-not-allowed border border-tw-gray-200 opacity-50'
          : 'hover:border-light-green hover:bg-light-green'
      } relative flex flex-grow items-center  gap-4 rounded-md border bg-white p-6 ${
        isSelected ? 'border-primary' : 'border-gray-dark'
      }`}
    >
      <span
        className={`absolute top-2 right-2 inline-grid h-6 w-6 place-items-center rounded-full border ${
          isSelected ? 'border-primary bg-primary' : 'border-gray-dark'
        }`}
      >
        <img src={checkMark} alt="" className="h-2" />
      </span>
      <div className={`flex ${body ? '' : 'items-center'} text-left`}>
        <img src={src} className="h-16 mr-7" alt={label + ' icon'} />
        {body ? (
          <div>
            <h3 className="text-xl font-bold"> {label} </h3>{' '}
            <p className="text-gray-secondaryDark w-4/5 text-sm"> {body} </p>
            <p className="text-primary font-bold mt-2">
              Up to ${limit} Per Day
            </p>
          </div>
        ) : (
          <span className="text-md ">{label}</span>
        )}
      </div>
    </button>
  );
};
