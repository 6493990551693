import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

export type ForgotPasswordDTO = {
  [key in 'phone_number' | 'email']?: string;
};

const forgotPassword = async (credentials: ForgotPasswordDTO) => {
  const resp = await axios.post('/api/forgot-password', {
    ...credentials,
  });
  return resp.data;
};

export const useForgotPassword = (onSuccess: OnSuccessCallback) => {
  return useUpdateApi(forgotPassword, {
    onSuccess,
    successNotificationText: 'Recovery Code Sent!',
  });
};
