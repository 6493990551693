import { axios } from 'lib/axios';
import { NotificationReadResponse, NotificationResponse } from '../types/notifications';

export const getNotifications = async (email: string) => {

    const resp = await axios.get<NotificationResponse>('/api/account/notifications',
        {
            data: {
                email: email
            }
        });
    return resp.data;
}


/** Mark notifications as read based on their ID */
export const markAsRead = async (notifications: string[]) => {
    const resp = await axios.post<NotificationReadResponse>('/api/account/notifications/read',
        {
            read: notifications
        })

    return resp.data;
}