export interface NotificationSettings {
    data: NotificationSetting[]
}

export enum NotificationTypes {
    PRODUCT_ANNOUNCEMENT = 'product_announcemnet',
    BTC_PRICE_ALERT = 'btc_price_alert',
    LTC_PRICE_ALERT = 'ltc_price_alert',
    SECURITY_ALERT = 'security_alert'
}

export enum NotificationCategory {
    ENABLE_EMAIL = 'enable_email',
    ENABLE_WEB = 'enable_web',
    ENABLE_FCM = 'enable_fcm'
}

export interface NotificationSetting {
    name: string;
    title: string;
    event: string;
    enable_email: boolean;
    enable_web: boolean;
    enable_fcm: boolean;
    enable_sms: boolean;
}