import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useDeleteWallet } from '../api/deleteWallet';

interface DeleteWalletProps {
  walletAddress: string;
  onSuccess: () => void;
}
export const DeleteWalletModal = ({
  walletAddress,
  onSuccess,
}: DeleteWalletProps) => {
  const { update: deleteWallet } = useDeleteWallet((resp) => {
    onSuccess();
  });

  return (
    <div className="flex flex-col items-center px-5">
      <div className="mb-3 inline-block rounded-full bg-light-green p-5">
        <ExclamationCircleIcon className="h-10 text-primary" />
      </div>
      <h2 className="font-lg my-2"> Delete Wallet</h2>
      <p className="text-center">
        Are you sure you want to delete this wallet? If you would like to modify
        this wallet, click the "Edit Wallet" button{' '}
      </p>
      <div className="flex items-center my-5">
        <button onClick={() => {}} className="btn bg-white text-tw-gray-700">
          Cancel
        </button>
        <button
          className="btn bg-primary text-white"
          onClick={() => {
            deleteWallet(walletAddress);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};
