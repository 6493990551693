import { Radio, RadioGroup } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import Button from 'components/Button';
import { TransactionFilterConfig } from '../../types/TransactionFilterConfig';
import { useState } from 'react';

const typeFilters = [
  {
    label: 'Buy',
    value: 'buy',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
];

const paymentTypeFilters = [
  {
    label: 'Cash',
    value: 'greendot',
  },
  {
    label: 'Bank Card',
    value: 'magicpay',
  },
  {
    label: 'NCRpay360',
    value: 'kyck',
  },
];

const transactionStatusFilters = [
  {
    label: 'New',
    value: 'New',
  },
  {
    label: 'Expired',
    value: 'Expired',
  },
  {
    label: 'Cancelled',
    value: 'Cancelled',
  },
  {
    label: 'Pickup Ready',
    value: 'Pickup Ready',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
];

type TransactionsFilterProps = {
  onSelectFilters: (filter: TransactionFilterConfig) => void;
  initialFilters: TransactionFilterConfig;
  onClose: () => void;
};

export const TransactionsFilter = ({
  initialFilters,
  onSelectFilters,
  onClose,
}: TransactionsFilterProps) => {
  const [filters, setFilters] = useState(initialFilters);
  const ref = useClickOutside(onClose);

  const handleType = (value: string) => {
    setFilters((prev) => ({
      ...prev,
      transaction_type: value,
    }));
  };

  const handlePaymentType = (value: string) => {
    setFilters((prev) => ({
      ...prev,
      service_provider: value,
    }));
  };

  const handleStatus = (value: string) => {
    setFilters((prev) => ({
      ...prev,
      status: value,
    }));
  };

  const handleFilter = () => {
    onSelectFilters(filters);
    onClose();
  };

  const handleClear = () => {
    onSelectFilters({
      transaction_type: undefined,
      service_provider: undefined,
      status: undefined,
    });
    onClose();
  };
  return (
    <div
      ref={ref}
      className="absolute top-[110%] right-0 z-10 w-72 rounded-lg bg-white p-2 text-left shadow-lg"
    >
      <header className="mb-2 text-lg font-semibold text-secondary">
        Filter
      </header>
      <div className="flex flex-col gap-2">
        <div>
          <RadioGroup
            label="Transaction Type"
            size="sm"
            classNames={{
              radio: 'checked:bg-primary',
            }}
            name="distance"
            value={filters.transaction_type}
            onChange={handleType}
          >
            {typeFilters.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <div>
          <RadioGroup
            size="sm"
            label="Transaction Payment Type"
            classNames={{
              radio: 'checked:bg-primary',
            }}
            name="type"
            value={filters.service_provider}
            onChange={handlePaymentType}
          >
            {paymentTypeFilters.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <div>
          <RadioGroup
            size="sm"
            label="Transaction Status"
            classNames={{
              radio: 'checked:bg-primary',
            }}
            name="status"
            value={filters.status}
            onChange={handleStatus}
          >
            {transactionStatusFilters.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="mt-4 flex gap-2">
        <Button size="sm" fullWidth color="bg-gray-dark" onClick={handleClear}>
          Clear
        </Button>
        <Button size="sm" onClick={handleFilter} fullWidth>
          Filter
        </Button>
      </div>
    </div>
  );
};
