import logo from 'assets/imgs/unbank-logo.svg';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

type IdentityVerificationRequiredProps = {
  coloredBackground?: boolean;
};

const IdentityVerificationRequired = ({
  coloredBackground = false,
}: IdentityVerificationRequiredProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-center  ${
        coloredBackground ? 'bg-light-green' : 'bg-white'
      }`}
    >
      <img src={logo} className="mb-8 h-16" alt="unbank logo" />
      <div className=" text-secondary text-center w-1/3">
        <h2 className="pl-4 text-xl">Please Verify your Identity</h2>
        <p className="pr-4 text-sm">
          Buying/Selling cryptocurrency is currently disabled on your account.
          To enable the ability to buy/sell please proceed to identity
          verification
        </p>
        <Button
          className="my-4"
          size="sm"
          onClick={() => navigate('/dashboard/account/identity-verification')}
        >
          {' '}
          Identity Verification{' '}
        </Button>
      </div>
    </div>
  );
};

export default IdentityVerificationRequired;
