import { useEffect, useState } from 'react';

const MIN_IN_MS = 1000 * 60;
const HR_IN_MS = MIN_IN_MS * 60;
const DAY_IN_MS = HR_IN_MS * 24;

type CountDownReturnType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isComplete: boolean;
  currentTime: number;
};

export const useCountdown = (targetDate: number): CountDownReturnType => {
  const countDownDate = new Date(targetDate).getTime();
  const [isComplete, setIsComplete] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const newRemainder = countDownDate - now;
      setCurrentTime(now);
      setCountDown(newRemainder);
      if (newRemainder < 1000) {
        setIsComplete(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [countDownDate]);
  return { ...getReturnValues(countDown, isComplete), isComplete, currentTime };
};

const getReturnValues = (countDown: number, isComplete: boolean) => {
  const days = isComplete ? 0 : Math.floor(countDown / DAY_IN_MS);
  const hours = isComplete ? 0 : Math.floor((countDown % DAY_IN_MS) / HR_IN_MS);
  const minutes = isComplete
    ? 0
    : Math.floor((countDown % HR_IN_MS) / MIN_IN_MS);
  const seconds = isComplete ? 0 : Math.floor((countDown % MIN_IN_MS) / 1000);
  return { days, hours, minutes, seconds };
};
