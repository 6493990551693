import { axios } from "lib/axios";
import { NotificationSetting, NotificationSettings } from "../types/notificationSettings";

export const getNotificationSettings = async () => {
    const resp = await axios.get<NotificationSettings>('/api/account/notification/settings', {
    });
    return resp.data;
}


export const updateNotificationSettings = async (credentials: NotificationSetting) => {
    const resp = await axios.post<NotificationSetting>('/api/account/notification/settings', {
        ...credentials
    })

    return resp.data
}   