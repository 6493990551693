import { useFetchApi } from 'common/hooks';
import { GetLocationsResponse } from '../types';

export type GetLocationsDTO = {
  term?: string;
  lat?: number;
  long?: number;
  distance?: string;
  unit?: 'K' | 'M';
  buy_or_sell?: 'buy' | 'sell' | 'both';
};

export const useGetLocations = ({
  term,
  lat,
  long,
  distance = '25',
  unit = 'M',
  buy_or_sell = 'both',
}: GetLocationsDTO) =>
  useFetchApi<GetLocationsResponse>(
    `/api/resources/atm/bst?distance=${distance}&buy_or_sell=${buy_or_sell}&unit=${unit}${
      term !== '' ? '&term=' + term : ''
    }${lat ? '&lat=' + lat : ''} ${long ? '&long=' + long : ''}`
  );
