import { getPaymentType, Transaction } from 'modules/transactions';
import { dateFormatter, numberFormatter } from 'utils/formatters';

type TransactionRowProps = {
  transaction: Transaction;
  onSelectTransaction: (transaction: Transaction) => void;
};

const columnStyle = 'border-x-2 border-gray-light px-3 py-4 text-center';

export const TransactionRow = ({
  transaction,
  onSelectTransaction,
}: TransactionRowProps) => {
  return (
    <tr
      onClick={() => onSelectTransaction(transaction)}
      className="cursor-pointer border-b-2 border-gray-light hover:bg-tw-gray-100"
    >
      <td className={`${columnStyle} border-l-0`}>{transaction.order_id}</td>
      <td className={columnStyle}>
        <span
          className={`${
            transaction.transaction_type === 'buy'
              ? 'bg-light-green text-primary'
              : 'bg-light-blue text-secondary'
          } py-1.5 px-8  font-semibold`}
        >
          {transaction.transaction_type}
        </span>
      </td>
      <td className={columnStyle}>
        {transaction.amount} {transaction.currency}
      </td>
      <td className={columnStyle}>
        {numberFormatter(transaction.amount_in_crypto, 8)}
      </td>
      <td className={columnStyle}>{transaction.crypto}</td>
      <td className={columnStyle}>
        {dateFormatter(new Date(transaction.transfer_date_utc))}
      </td>
      <td className={columnStyle}>
        {getPaymentType(transaction.service_provider)}
      </td>
      <td className={columnStyle}>{transaction.status}</td>
      <td className={`${columnStyle} border-r-0`}>
        {transaction.expiry_date_utc !== ''
          ? dateFormatter(new Date(transaction.expiry_date_utc))
          : 'N/A'}
      </td>
    </tr>
  );
};
