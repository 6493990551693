import { OnSuccessCallback, useUpdateApi } from "common/hooks";
import { axios } from "lib/axios";

interface DeleteWalletResponse {
    status: string;
    message: string
}

export const deleteWallet = async (walletAddress: string) => {
    const resp = await axios.delete<{ status: string, message: string }>(`/api/account/wallet/${walletAddress}`, {

    });
    return resp.data;
}

export const useDeleteWallet = (
    onSuccess: OnSuccessCallback<DeleteWalletResponse>
) => {
    return useUpdateApi(deleteWallet, { onSuccess, successNotificationText: 'Wallet Successfully Deleted' });
};
