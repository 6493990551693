import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type PasswordFieldProps = PasswordInputProps &
  React.RefAttributes<HTMLInputElement>;

const PasswordField = ({ ...props }: PasswordFieldProps) => {
  return (
    <PasswordInput
      styles={{
        input: { fontSize: '18px' },
        label: { fontSize: '14px' },
      }}
      variant="filled"
      color="gray-dark"
      size="lg"
      visibilityToggleIcon={({ reveal, size }) =>
        reveal ? <VisibilityOff /> : <Visibility />
      }
      {...props}
    />
  );
};

export default PasswordField;
