import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { ForgotPasswordDTO } from './forgotPassword';

export type ResetPasswordDTO = {
  password: string;
  password_confirmation: string;
  token: string;
} & ForgotPasswordDTO;

const resetPassword = async (credentials: ResetPasswordDTO) => {
  const resp = await axios.post('/api/reset-password', {
    ...credentials,
  });
  return resp.data;
};

export const useResetPassword = (onSuccess: OnSuccessCallback) => {
  return useUpdateApi(resetPassword, {
    onSuccess,
    successNotificationText: 'Your password has been reset!',
  });
};
