import { NavLink } from 'react-router-dom';
type NavItemProps = {
  link: string;
  label: string;
  onNavigate: () => void;
  testId?: string;
};
export function NavItem({ link, label, onNavigate, testId }: NavItemProps) {
  return (
    <li
      className="last:pr-6 sm:pr-0 md:last:pr-12"
      onClick={() => onNavigate()}
    >
      <NavLink to={link} data-testid={testId}>
        {({ isActive }) => (
          <span
            className={`${
              isActive
                ? 'font-bold md:text-black text-primary'
                : 'text-gray-dark'
            } transition-colors duration-300 md:hover:text-black hover:text-primary md:text-sm text-xs`}
          >
            {label}
          </span>
        )}
      </NavLink>
    </li>
  );
}
