import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { LoginApiResponse } from '../types/login';

export type LoginCredentialsDTO = {
  phone_number: string;
  password: string;
};

const login = async (credentials: LoginCredentialsDTO) => {
  const resp = await axios.post<LoginApiResponse>('/api/login', {
    ...credentials,
  });

  return resp.data;
};

export const useLogin = (onSuccess: OnSuccessCallback<LoginApiResponse>) => {
  return useUpdateApi(login, { onSuccess });
};
