import React, { Component, ErrorInfo } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/imgs/unbank-logo-no-bg.svg';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = (error: Error) => {
    return {
      hasError: true,
    };
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-screen flex-col items-center justify-center gap-8 bg-light-green">
          <img
            src={logo}
            className="h-16 rounded-md bg-primary p-2"
            alt="unbank logo"
          />
          <h1 className="text-secondary">Something went wrong :(</h1>
          <Link
            to="/dashboard/overview"
            className="btn py-4 px-6 text-lg font-bold"
          >
            Back To Overview
          </Link>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
