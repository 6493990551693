import { currencyFormatter } from 'utils/formatters';

type AccountBalanceCardProps = {
  balance: number;
};
export function AccountBalanceCard({ balance }: AccountBalanceCardProps) {
  return (
    <div className="md:w-[400px] w-full rounded-md bg-white bg-card-bg bg-[position:left_105%] bg-no-repeat p-8 shadow-custom">
      <h2 className="mb-2 text-lg">Account Balance</h2>
      <div className="mb-12 ">
        <div className="mb-2 text-3xl font-bold text-primary">
          {currencyFormatter(balance)}
        </div>
        <p className="text-sm font-thin">
          This is your current available balance.
        </p>
      </div>
      <p className="text-right text-xs text-tw-gray-400">
        Last updated a few seconds ago
      </p>
    </div>
  );
}
