import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Button from 'components/Button';

import { useNavigate } from 'react-router-dom';

type InvoiceExpiredProps = {
  orderId: string;
  onRestartProcess: () => void;
};

export const TransactionExpired = ({
  orderId,
  onRestartProcess,
}: InvoiceExpiredProps) => {
  const navigate = useNavigate();

  return (
    <section className="mx-auto w-1/2">
      <header className="mb-4 text-center flex flex-col items-center justify-center">
        <div className=" mb-4 rounded-full bg-light-green p-2">
          <ExclamationCircleIcon className="h-[100px] text-primary" />
        </div>
        <h2>Transaction Expired</h2>
      </header>
      <div className="mb-5 flex flex-col gap-4 text-center">
        <p>
          Each transaction is only valid for a limited time{' '}
          <span
            onClick={onRestartProcess}
            className="cursor-pointer text-primary underline"
          >
            return to the buy page
          </span>{' '}
          to create a new transaction
        </p>
        <div>
          <p>
            <span className="font-bold">Order ID: </span>
            {orderId}
          </p>
        </div>
      </div>

      <div className="flex gap-5 justify-center">
        <Button
          color="bg-gray-dark"
          size={'sm'}
          onClick={() => navigate('/dashboard/overview')}
        >
          Back to Overview
        </Button>
        <Button size={'sm'} onClick={onRestartProcess}>
          Back to Buy
        </Button>
      </div>
    </section>
  );
};
