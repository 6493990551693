import { Transaction, getPaymentType } from 'modules/transactions';
import { capitalizeFirstLetter, numberFormatter } from 'utils/formatters';
import { ArrowSmRightIcon } from '@heroicons/react/solid';
import { StyledOrderStatus } from 'components/StyledOrderStatus';

type TransactionCardProps = {
  transaction: Transaction;
  onSelectTransaction: (transaction: Transaction) => void;
};

export const TransactionCard = ({
  transaction,
  onSelectTransaction,
}: TransactionCardProps) => {
  return (
    <article
      className="border border-gray-dark rounded-lg py-5 my-4 px-3"
      onClick={() => onSelectTransaction}
    >
      <div className="flex justify-between items-center">
        <p> {getPaymentType(transaction.service_provider)}</p>
        <span
          className={`text-xs ${
            transaction.transaction_type === 'buy'
              ? 'bg-light-blue text-secondary'
              : 'bg-light-green text-primary'
          } py-1.5 px-5  font-semibold rounded-full`}
        >
          {capitalizeFirstLetter(transaction.transaction_type)}
        </span>
      </div>
      <div className="flex items-center gap-3 text-sm">
        <p>{transaction.amount}</p>
        <ArrowSmRightIcon className="h-7 text-primary" />
        <p> {numberFormatter(transaction.amount_in_crypto, 8)} </p>
      </div>
      <div>
        <StyledOrderStatus orderStatus={transaction.status} />
      </div>
    </article>
  );
};
