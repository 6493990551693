export interface WalletResponse {
    data: Wallet[];
}

export interface ValidateCryptoRequest {
    address: string;
    crypto: string;
}

export interface ValidateCryptoResponse {
    isWalletValid: boolean;
}
export interface UpdateWalletRequest {
    name: string;
    address: string;
    crypto: string;
    is_default: boolean
}

export interface Wallet {
    name: string;
    address: string;
    crypto: string;
    is_default: boolean;
    risk_score: string;
    sanction_risk_score: number,
    gambling_risk_score: number,
    metadata: WalletMetaData,
    risk_data: WalletRiskData,
    created_at: string,
    updated_at: string

}

export interface WalletFields {
    name: string;
    address: string;
    crypto: (keyof typeof SupportedCurrencies) | '';
    is_default: boolean;
}

export enum SupportedCurrencies {
    btc = 'Bitcoin',
    ltc = 'Litecoin',
}

interface WalletOwnerData {
    url: string,
    name: string,
    type: string,
    country: string,
    subpoenable: boolean
}

interface WalletMetaData {
    owner: WalletOwnerData;
    walletId: string;
    revision: number;
    totalAddressCount: number
}

interface WalletRiskData {
    risk: number,
    address: string,
    sanctionsRisk: number,
    gamblingOkRisk: number,
    updatedToBlock: number,
    callBackSeconds: number
}

