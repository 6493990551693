import { OnSuccessCallback, useFetchApi } from 'common/hooks';

export type ClientIPResponse = string;

export const useGetClientIP = (
  onSuccess: OnSuccessCallback<ClientIPResponse>
) => {
  return useFetchApi<ClientIPResponse>(`https://checkip.amazonaws.com/`, {
    onSuccess,
  });
};
