import { CheckCircleIcon } from '@heroicons/react/solid';
import { Loader } from '@mantine/core';
import { Transaction } from 'modules/transactions';
import { useEffect } from 'react';
import { capitalizeFirstLetter } from 'utils/formatters';
import { useGetTransaction } from '../api/getTransaction';
import { KyckStatus } from '../types/kyckStatuses';

const CHECK_INVOICE_INTERVAL_IN_MS = 1000 * 60 * 2;

type PickUpConfirmationProps = {
  orderId: string;
  onPickupReady: (data: Transaction) => void;
  onPaymentProcessed?: () => void;
  cashoutMethod: string;
};

export const PickUpConfirmation = ({
  orderId,
  cashoutMethod = 'ncrpay360',
  onPickupReady,
  onPaymentProcessed,
}: PickUpConfirmationProps) => {
  const getInvoiceQuery = useGetTransaction((data) => {
    if (
      data.status === KyckStatus.PICKUP_READY &&
      cashoutMethod === 'ncrpay360'
    ) {
      onPickupReady(data);
    } else if (data.status === KyckStatus.SUCCESS) {
      console.log('This works');
    }
  });

  useEffect(() => {
    const interval = setInterval(
      () => getInvoiceQuery.update(orderId),
      CHECK_INVOICE_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  const IN_PERSON_TEXT = ` We will notify you once your cash is ready to be picked up at
  the given location.`;

  const ONLINE_TEXT = `We will notify you once your cash is ready in your ${capitalizeFirstLetter(
    cashoutMethod
  )} account`;
  return (
    <>
      <div className="mx-auto flex max-w-lg flex-col items-center text-center lg:w-2/3">
        <div className=" mb-4 rounded-full bg-light-green p-2">
          <CheckCircleIcon className="h-[100px] text-primary" />
        </div>
        <p>
          Thank you for using Unbank. We are currently processing your payment.{' '}
          {cashoutMethod === 'ncrpay360' ? IN_PERSON_TEXT : ONLINE_TEXT}
        </p>
        <div className="mt-8 w-full bg-gray-medium py-4 text-xl font-semibold tracking-wider ">
          {orderId}
        </div>
        <h3 className="mt-2 text-lg font-bold text-primary">Order Number</h3>
        <div className="mt-12 flex gap-2">
          <Loader size="sm" /> Checking for status change...
        </div>
      </div>
    </>
  );
};
