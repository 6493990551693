import { CashoutItem } from './CashoutItem/CashoutItem';
import { useAuth } from 'modules/auth';
import { useEffect } from 'react';
import { useGetAccountInfo } from 'modules/account/api/getAccountInfo';
import { BankAccountCashOut } from 'modules/account/components/CashoutMethods/BankAccountCashOut';

export const CashoutMethods = () => {
  const { user, setUser } = useAuth();
  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );
  
  useEffect(() => {
    fetchUserInfo({});
  }, []);

  return (
    <section>
      <h1 className="text-2xl font-bold">My Cashout Methods</h1>
      {user ? (
        <>
          <div className="my-6 mx-a grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3">
            {Object.entries(user.cashout_methods).map((cashoutMethod, index) => {
              return <CashoutItem cashoutItem={cashoutMethod} key={index} />;
            })}
          </div>
          <BankAccountCashOut/>
        </>
      ) : (
        <div></div>
      )}
    </section>
  );
};
