import { GetExchangeRateResponse } from 'common/types';
import Button from 'components/Button';
import checkCircle from 'assets/icons/check_circle.svg';
import { currencyFormatter, numberFormatter } from 'utils/formatters';
import { useNavigate } from 'react-router-dom';

type OrderCompleteProps = {
  exchangeRateInfo: GetExchangeRateResponse;
  fundingSource: string;
  minerFee: number;
};

export const OrderComplete = ({
  exchangeRateInfo,
  fundingSource,
  minerFee,
}: OrderCompleteProps) => {
  const navigate = useNavigate();

  const navigateToOverview = () => {
    navigate('/dashboard/overview');
  };

  const totalCost =
    exchangeRateInfo.unbank_fee!.fee + exchangeRateInfo.total_cost + minerFee;
  return (
    <div>
      <div className="my-16 text-center">
        <img src={checkCircle} className="mx-auto h-[80px] w-auto" alt="" />
        <h3 className="mt-4 text-2xl font-semibold">Completed</h3>
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
          <span>Funding Source</span>
          <span>{fundingSource}</span>
        </div>

        <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
          <span>Amount</span>
          <span>
            {numberFormatter(parseFloat(exchangeRateInfo.crypto_amount), 8)}{' '}
            {exchangeRateInfo.to}
          </span>
        </div>

        <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
          <span>Exchange Rate</span>
          <span>
            {currencyFormatter(parseFloat(exchangeRateInfo.exchange_rate!))}
          </span>
        </div>

        <div className="flex items-center justify-between rounded-md bg-primary py-4 px-8 font-bold text-white">
          <span>Total Cost</span>
          <span>{currencyFormatter(totalCost)}</span>
        </div>
        <div className="text-center">
          <Button onClick={navigateToOverview} className="mt-4">
            Back to Overview
          </Button>
        </div>
      </div>
    </div>
  );
};
