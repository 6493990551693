import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { SystemInfoResponse } from 'common/types/systemInfo';
import { axios } from 'lib/axios';

const getApiInfo = async () => {
  const resp = await axios.get<SystemInfoResponse>('/api/system/info');
  return resp.data;
};

export const useGetApiInfo = (
  onSuccess: OnSuccessCallback<SystemInfoResponse>
) => {
  return useUpdateApi(getApiInfo, { onSuccess });
};
