import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

export type DataTransferObjectType = {
  cardRef: string;
};

const deleteBankCard = async (cardFef: string) => {
  const resp = await axios.delete(`/api/account/bank/card/${cardFef}`);
  return resp.data;
};

export const useDeleteBankCard = (onSuccess: OnSuccessCallback) => {
  return useUpdateApi(deleteBankCard, {
    onSuccess,
    successNotificationText: 'Bank card successfully deleted',
  });
};
