import { RouteObject } from "react-router-dom";
import { GuardedRoute } from 'modules/auth';
import NotFound from 'components/NotFound';
import { PickupReadyPage } from 'modules/transactions/pages/PickupReadyPage';
import { Dashboard } from "modules/dashboard/pages/Dashboard";

import { routes as overviewRoutes } from 'modules/overview/routes'; 
import { routes as accountRoutes } from 'modules/account/routes';
import { routes as buyRoutes } from 'modules/buy/routes';
import { routes as sellRoutes } from 'modules/sell/routes';
import { routes as locationRoutes } from 'modules/locations/routes';
import { routes as transactionRoutes } from 'modules/transactions/routes';
import { routes as supportRoutes } from 'modules/support/routes';

export const routes: RouteObject[] = [
    {
        path: 'dashboard/*',
        element: <GuardedRoute component={<Dashboard />} />,
        children: [
          ...accountRoutes,
          ...locationRoutes,
          ...overviewRoutes,
          ...buyRoutes,
          ...sellRoutes,
          ...transactionRoutes,
          ...supportRoutes,
          {
            path: 'pickupready',
            element: <PickupReadyPage />,
          },
          {
            path: '*',
            element: (
              <div className="overflow-hidden rounded-md">
                <NotFound coloredBackground={false} />
              </div>
            ),
          },
        ],
      },
]