import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useVerifyEmail, VerifyEmailDTO } from '../api/verifyEmail';
import Button from 'components/Button';
import TextField from 'components/TextField';
import VerificationBanner from './VerificationBanner';
import arrow_right from 'assets/icons/arrow_right.svg';
import { useResendEmailVerification } from '../api/resendEmailVerification';

type EmailVerificationFormProps = {
  onComplete: () => void;
  buttonLabel?: string;
  email: string;
};

export const EmailVerificationForm = ({
  onComplete,
  email,
  buttonLabel = 'Verify Email',
}: EmailVerificationFormProps) => {
  const apiQuery = useVerifyEmail(onComplete);
  const { isLoading, update: sendVerification } = useResendEmailVerification();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<VerifyEmailDTO>({
      initialValues: {
        code: '',
      },
      validationSchema: Yup.object({
        code: Yup.string().required(),
      }),
      onSubmit: (values) => {
        apiQuery.update(values);
      },
    });
  return (
    <form onSubmit={handleSubmit} className="text-center">
      <VerificationBanner
        type="email"
        contactDetail={email}
        onRefresh={() => sendVerification({})}
        isRefreshing={isLoading}
      />
      <TextField
        className="my-5"
        label="Verification Code"
        placeholder="Code"
        name="code"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.code && touched.code && errors.code}
      />

      <div className="text-center">
        <Button
          type="submit"
          loading={apiQuery.isLoading}
          iconPosition="right"
          icon={<img src={arrow_right} className="h-4" alt="right arrow" />}
        >
          {buttonLabel}
        </Button>
      </div>
    </form>
  );
};
