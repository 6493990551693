type ModalSectionHeaderProps = {
  position: number;
  title: string;
};

export const ModalSectionHeader = ({
  position,
  title,
}: ModalSectionHeaderProps) => {
  return (
    <header className="mt-4">
      <h4>
        {position}. {title}
      </h4>
      <hr className="mt-2 mb-6 border-gray-medium" />
    </header>
  );
};
