export const PublicLocationsBanner = () => (

  <div>
    <nav className="absolute top-0  w-full px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900" style={{ paddingLeft: '6%', paddingRight: '6%' }} >
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <a href="https://unbankworld.com/" className="flex items-center">
          <img
            src="https://unbankworld.com/wp-content/uploads/2022/05/Unbank-Logo.svg"
            className="mr-3 h-6 sm:h-9"
            alt="Unbank World"
          />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-white hover:text-green-600" style={{ fontSize: 18, fontWeight: 500 }} >
            
            <li>
              <a
                href="https://unbankworld.com"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded  md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-green dark:hover:bg-gray-700 dark:hover:text-green md:dark:hover:bg-transparent"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="https://app.unbankworld.com/view-locations"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded  md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-green dark:hover:bg-gray-700 dark:hover:text-green md:dark:hover:bg-transparent"
              >
                Locations
              </a>
            </li>
            <li>
              <a
                href="https://unbankworld.com/features/"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded  md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-green dark:hover:bg-gray-700 dark:hover:text-green md:dark:hover:bg-transparent"
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="https://unbankworld.com/faq/"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded  md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-green dark:hover:bg-gray-700 dark:hover:text-green md:dark:hover:bg-transparent"
              >
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>


    <div className="bg-gradient-to-b from-secondary to-primaryDark flex items-center p-6 mb-1" style={{ paddingTop: 120, paddingBottom: 80 }} >
      <header className="mantine-TextInput-root md:mx-20 mantine-ei3cv0">
        <h1 className="text-white text-7xl">Find Locations</h1>
        <p className="md:w-1/2 w-full text-white">
          Our platform makes it possible to buy, sell and liquidate Bitcoin at a
          supermarket, convenience store, gas station or any of our supported
          locations in the US and other countries.
        </p>
      </header>
    </div>
  </div>
);
