import { ArrowSmRightIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import PasswordField from 'components/PasswordField';
import TextField from 'components/TextField';
import { ForgotPasswordDTO } from 'modules/auth/api/forgotPassword';
import {
  ResetPasswordDTO,
  useResetPassword,
} from 'modules/auth/api/resetPassword';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type ResetPasswordFormProps = {
  onFormSubmit: () => void;
  forgotPasswordRequestData: ForgotPasswordDTO;
};

export const ResetPasswordForm = ({
  onFormSubmit,
  forgotPasswordRequestData,
}: ResetPasswordFormProps) => {
  const { isLoading, update } = useResetPassword(onFormSubmit);
  const { errors, touched, ...formik } = useFormik<ResetPasswordDTO>({
    initialValues: {
      ...forgotPasswordRequestData,
      password: '',
      password_confirmation: '',
      token: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required().min(8),
      token: Yup.string().required(),
      password_confirmation: Yup.string()
        .required('confirm password is a required field')
        .oneOf([Yup.ref('password')], 'passwords must match'),
    }),
    onSubmit: (values) => {
      update(values);
    },
  });
  return (
    <>
      <p>Please enter the verification code along with your new password </p>
      <form className="mt-4 flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <TextField
          id="token"
          placeholder="Code"
          {...formik.getFieldProps('token')}
          error={errors.token && touched.token && errors.token}
        />
        <PasswordField
          id="password"
          placeholder="Password"
          {...formik.getFieldProps('password')}
          autoComplete="off"
          error={errors.password && touched.password && errors.password}
        />
        <PasswordField
          id="password_confirmation"
          placeholder="Confirm Password"
          autoComplete="off"
          {...formik.getFieldProps('password_confirmation')}
          error={
            errors.password_confirmation &&
            touched.password_confirmation &&
            errors.password_confirmation
          }
        />
        <Button
          className="mt-8"
          type="submit"
          loading={isLoading}
          icon={<ArrowSmRightIcon className="h-6" />}
          fullWidth={true}
        >
          Reset Password
        </Button>
      </form>
    </>
  );
};
