import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

const logout = async () => {
  const resp = await axios.get('/api/logout');
  return resp.data;
};

export const useLogout = (onSuccess: OnSuccessCallback) => {
  return useUpdateApi(logout, { onSuccess });
};
