import { AccountBalanceCard } from './../components/AccountBalanceCard';
import { BitcoinChart } from './../components/BitcoinChart';
import { TransactionsTable } from 'modules/transactions';
import { DepositLimitCard } from '../components/DepositLimitCard';
import { useAuth } from 'modules/auth';
import CashRegisterIcon from 'assets/icons/CashRegisterIcon';
import { Link } from 'react-router-dom';
import { detectViewport } from 'utils/layout';
import { useEffect, useState } from 'react';
import { TransactionList } from 'modules/transactions/components/TransactionList/TransactionList';
import { useLimits } from 'modules/transactions/providers/limits';

export const OverviewPage = () => {
  const { user } = useAuth();

  const [viewport, setViewport] = useState(detectViewport());

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  const { overallLimits } = useLimits();

  return (
    <div className="space-y-8 ">
      <div className="flex gap-8 md:flex-row flex-col">
        <AccountBalanceCard balance={parseFloat(user!.account.balance)} />
      </div>
      <div className="flex gap-8">
        <div className="grow">
          <BitcoinChart />
        </div>
        <aside className="w-72 space-y-4 md:block hidden">
          <header className="flex items-center gap-2">
            <CashRegisterIcon className="h-5 text-lg text-primary" />
            <h2 className="text-lg">Transaction Limits Summary</h2>
          </header>
          <DepositLimitCard
            title="Daily"
            limit={overallLimits?.daily_limit!}
            usage={overallLimits?.daily_sum!}
          />
          <DepositLimitCard
            title="Weekly"
            limit={overallLimits?.weekly_limit!}
            usage={overallLimits?.weekly_sum!}
          />
          <DepositLimitCard
            title="Monthly"
            limit={overallLimits?.monthly_limit!}
            usage={overallLimits?.monthly_sum!}
          />
        </aside>
      </div>
      <section className="rounded-md bg-white p-8 shadow-custom">
        <header className="mb-12 flex items-start justify-between">
          <div>
            <h2 className="mb-4 text-2xl font-bold">Transactions</h2>
            <p>Here is a list of your most recent transactions.</p>
          </div>
          <Link to="/dashboard/transactions" className="btn">
            View More
          </Link>
        </header>

        {viewport === 'mobile' ? (
          <TransactionList />
        ) : (
          <TransactionsTable showRecent={true} />
        )}
      </section>
    </div>
  );
};
