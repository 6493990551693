import { axios } from "lib/axios";
import { LoginEvent, RegisterEvent } from "../types/authEvents";
import { SiftEventResponse } from "../types/eventResponse";
import { SIFT_EVENTS_API } from "../util/constants";

export const trackLogin = async (credentials: LoginEvent) => {
    const resp = await axios.post<SiftEventResponse>(SIFT_EVENTS_API, {
        ...credentials
    })

    return resp.data
}

export const trackSignUp = async (credentials: RegisterEvent) => {
    const resp = await axios.post<SiftEventResponse>(SIFT_EVENTS_API, {
        ...credentials
    })

    return resp.data
}

export const trackTransaction = () => {

}