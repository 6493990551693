import { useState } from 'react';


import {
  useEnableTwoFactorAuth,
} from 'modules/account/api/twoFactorAuth';
import { TwoFactorQR } from './TwoFactorQR';
import { ConfirmPassword } from './ConfirmPassword';
import { ConfirmTwoFactor } from './ConfirmTwoFactor';
import Button from 'components/Button';
import ResponsiveModal from 'components/Modal';

export type TwoFactorSetupModal = {
  opened: boolean;
  onClose(): void;
};
export const TwoFactorSetupModal: React.FC<TwoFactorSetupModal> = ({
  opened,
  onClose,
  ...props
}) => {
  // Auth Values
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [ , setTwoFactorConfirmed] = useState(false);

  // Proceed to confirm 2FA
  const [proceed, setProceed] = useState(false);

  // 2FA QR Code Hooks
  const { update: enableTwoFactorAuth } = useEnableTwoFactorAuth((resp) => {});

  // Enable 2FA and show recovery codes
  const handleQREnable = async () => {
    setPasswordConfirmed(true);
    enableTwoFactorAuth({});
  };

  const handleSuccess = () => {
    handleQREnable();
    setTwoFactorConfirmed(true);
  };

  return (
    <ResponsiveModal
      opened={opened}
      title={<h2 className="py-2"> Setup Two Factor Authentication</h2>}
      size="50%"
      onClose={onClose}
      {...props}
    >
      {passwordConfirmed ? (
        <div className="px-5 py-3">
          <div>
            {proceed ? (
              <ConfirmTwoFactor
                handleTwoFactorConfirmed={() => handleSuccess()}
              />
            ) : (
              <div>
                <TwoFactorQR />
                <div className="flex items-center justify-center">
                  <Button
                    type="submit"
                    size="sm"
                    onClick={() => setProceed(true)}
                  >
                    Continue
                  </Button>{' '}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <ConfirmPassword handlePasswordConfirmed={() => handleQREnable()} />
      )}
    </ResponsiveModal>
  );
};
