import { ProgressBar } from 'components/ProgressBar';
import { currencyFormatter } from 'utils/formatters';

type DepositLimitCardProps = {
  title: string;
  limit: number;
  usage: number;
};
export const DepositLimitCard = ({
  title,
  limit,
  usage,
}: DepositLimitCardProps) => {
  const usagePercentage = (usage / limit) * 100;
  return (
    <div className="flex flex-col gap-2 rounded-md bg-white p-4 shadow-custom">
      <h3>{title}</h3>
      {/* Bar line */}
      <ProgressBar progressPercentage={usagePercentage} />
      <small className="text-xs text-primary">
        *used {currencyFormatter(usage)} of {currencyFormatter(limit, 0)}
      </small>
    </div>
  );
};
