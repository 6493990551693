import Axios, { AxiosError } from 'axios';
import { BASE_URL } from 'config';
import notification from 'utils/notification';
import storage from 'utils/storage';

export const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.interceptors.request.use(
  (config) => {
    const token = storage.getToken();

    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // TODO have a switch statement to catch errors and check the request's response url and the status 404 etc to write custome error messages
    console.dir(error);
    if (!error.response?.data.errors) {
      notification.notifyError(error.response?.data.message);
    } else {
      Object.keys(error.response?.data.errors).forEach((key) => {
        notification.notifyError(error.response?.data.errors[key][0]);
      });
    }
    if (error.response?.status === 401) {
      storage.clearAuthData();
    }
    return Promise.reject(error);
  }
);
