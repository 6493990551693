import { LoadingOverlay } from '@mantine/core';
import { useModals } from '@mantine/modals';
import TextField from 'components/TextField';
import {
  EmailVerificationForm,
  PhoneVerificationForm,
  useAuth,
} from 'modules/auth';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AccountInfoDTO, useUpdateAccountInfo } from '../api/updateAccount';
import { UpdateAccountInfoResponse } from '../types/updateAccountInfoResponse';

export const General = () => {
  const { setUser, user } = useAuth();
  const modals = useModals();
  const onSuccess = (data: UpdateAccountInfoResponse) => {
    setUser(data.data);
    setIsNotEditing(true);
    if (emailChanged) {
      setEmailChanged(false);
      const emailModalId = modals.openModal({
        children: (
          <div className="px-8">
            <EmailVerificationForm
              email={formik.values.email!}
              onComplete={() => closeModal(emailModalId)}
            />
          </div>
        ),
      });
    }
    if (phoneChanged) {
      setPhoneChanged(false);
      const phoneModalId = modals.openModal({
        children: (
          <div className="px-8">
            <PhoneVerificationForm
              phone={formik.values.phone_number!}
              onComplete={() => closeModal(phoneModalId)}
            />
          </div>
        ),
      });
    }
  };
  const { isLoading, update: submitForm } = useUpdateAccountInfo(onSuccess);
  const { errors, touched, ...formik } = useFormik<Partial<AccountInfoDTO>>({
    initialValues: {
      firstname: user!.firstname,
      lastname: user!.lastname,
      middlename: user!.middlename,
      email: user!.email ?? '',
      phone_number: user!.phone_number,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(),
      lastname: Yup.string().required(),
      middlename: Yup.string(),
      email: Yup.string()
        .email()
        .when([], {
          is: () => user!.email !== '',
          then: Yup.string().required(),
        }),
      phone_number: Yup.string().required(),
    }),
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const closeModal = (modalId: string) => modals.closeModal(modalId);

  const [isNotEditing, setIsNotEditing] = useState(true);

  const [emailChanged, setEmailChanged] = useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);

  useEffect(() => {
    setEmailChanged(user!.email !== formik.values.email);
    setPhoneChanged(user!.phone_number !== formik.values.phone_number);
  }, [formik.values.email, formik.values.phone_number, user]);

  return (
    <section>
      <form onSubmit={formik.handleSubmit} className="space-y-8">
        <LoadingOverlay
          loaderProps={{ color: '#3BCE49', size: 'lg' }}
          visible={isLoading}
        />
        <div className="mb-12 flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-bold">Personal Information</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <TextField
            id="firstname"
            placeholder="First Name"
            {...formik.getFieldProps('firstname')}
            disabled={isNotEditing}
            error={errors.firstname && touched.firstname && errors.firstname}
          />
          <TextField
            id="middlename"
            placeholder="Middle Name"
            {...formik.getFieldProps('middlename')}
            disabled={isNotEditing}
            error={errors.middlename && touched.middlename && errors.middlename}
          />
          <TextField
            id="lastname"
            placeholder="Last Name"
            {...formik.getFieldProps('lastname')}
            disabled={isNotEditing}
            error={errors.lastname && touched.lastname && errors.lastname}
          />
        </div>
        <div className="flex flex-col gap-8 md:flex-row">
          <TextField
            className="md:w-2/5"
            id="phone_number"
            placeholder="Phone"
            {...formik.getFieldProps('phone_number')}
            disabled={true}
            error={
              errors.phone_number && touched.phone_number && errors.phone_number
            }
          />
          <TextField
            className="md:w-3/5"
            id="email"
            placeholder="Email"
            {...formik.getFieldProps('email')}
            disabled={true}
            error={errors.email && touched.email && errors.email}
          />
        </div>
      </form>
      {/* <div className="w-1/2 my-5">
        <Button size="sm" color="bg-gray-dark">
          Close Account
        </Button>
      </div> */}
    </section>
  );
};
