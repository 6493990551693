import { useState } from 'react';
import {
  useDisableTwoFactorAuth,
} from 'modules/account/api/twoFactorAuth';
import Button from 'components/Button';
import { TwoFactorSetupModal } from './TwoFactorSetupModal';
import { Loader } from '@mantine/core';
import { ConfirmPassword } from './ConfirmPassword';
import { RecoveryCodes } from './RecoveryCodes';
import { useAuth } from 'modules/auth';
import ResponsiveModal from 'components/Modal';

export const TwoFactorAuth = () => {
  const [setupModalOpened, setSetupModalOpened] = useState(false);
  const [recoveryModalOpened, setRecoveryModalOpened] = useState(false);
  const [disableModalOpened, setDisableModalOpened] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);

  const { user } = useAuth();

  const { update: disableTwoFactorAuth } = useDisableTwoFactorAuth(
    (resp) => {}
  );

  const refreshPage = () => {
    window.location.reload();
  };

  const handleQrDisable = () => {
    disableTwoFactorAuth({});
  };

  return (
    <div>
      <header>
        <h1 className="text-2xl font-bold">Two-Step Authentication</h1>
        <div className="flex justify-between items-center">
          <div className="my-4 w-3/5">
            <p className="mb-4">
              {' '}
              Add additional security to your account using two-step
              authentication{' '}
            </p>{' '}
            <p>
              {' '}
              When two factor authentication is enabled, you will be prompted
              for a secure, random token during authentication. You may retrieve
              this token from your phone's Google Authenticator application.
            </p>
          </div>
          <article>
            {user ? (
              <div>
                {user?.two_factor ? (
                  <div>
                    <article className="flex gap-10 items-center">
                      <div className="flex">
                        <Button
                          size="sm"
                          className="bg-white text-primary border border-primary mx-3"
                          onClick={() => {
                            setRecoveryModalOpened(true);
                          }}
                        >
                          {' '}
                          Show Recovery Codes{' '}
                        </Button>
                        <Button
                          className="bg-red-500"
                          type="submit"
                          size="sm"
                          onClick={() => {
                            setDisableModalOpened(true);
                          }}
                        >
                          Disable
                        </Button>
                      </div>
                      {/* Confirm Password before showing recovery codes */}
                      <ResponsiveModal
                        title={
                          <h2>Two Factor Authentication - Recovery Codes</h2>
                        }
                        opened={recoveryModalOpened}
                        onClose={() => setRecoveryModalOpened(false)}
                        size="50%"
                      >
                        {passwordConfirmed ? (
                          <RecoveryCodes />
                        ) : (
                          <ConfirmPassword
                            handlePasswordConfirmed={() => {
                              setPasswordConfirmed(true);
                            }}
                          />
                        )}
                      </ResponsiveModal>

                      {/* Confirm Password before disabling 2FA */}
                      <ResponsiveModal
                        title={<h2> Disable Two Factor Authentication </h2>}
                        opened={disableModalOpened}
                        onClose={() => {
                          setDisableModalOpened(false);
                          refreshPage();
                        }}
                        size="50%"
                      >
                        {' '}
                        <ConfirmPassword
                          handlePasswordConfirmed={() => {
                            setPasswordConfirmed(true);
                            handleQrDisable();
                            setDisableModalOpened(false);
                          }}
                        />
                      </ResponsiveModal>
                    </article>
                  </div>
                ) : (
                  <article className="flex gap-10 items-center">
                    <Button
                      type="submit"
                      size="sm"
                      onClick={() => setSetupModalOpened(true)}
                    >
                      Enable
                    </Button>
                    {/* Display Recover Code Modal*/}
                    <TwoFactorSetupModal
                      opened={setupModalOpened}
                      onClose={() => {
                        setSetupModalOpened(false);
                        refreshPage();
                      }}
                    />
                  </article>
                )}
              </div>
            ) : (
              <div>
                <Loader />
              </div>
            )}
          </article>
        </div>
        <div className=""></div>
      </header>
    </div>
  );
};
