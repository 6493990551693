import { Transaction } from 'modules/transactions';
import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

const getTransaction = async (orderId: string) => {
  const resp = await axios.get<Transaction>(
    `/api/account/transaction/order/${orderId}`
  );
  return resp.data;
};

export const useGetTransaction = (
  onSuccess: OnSuccessCallback<Transaction>
) => {
  return useUpdateApi(getTransaction, { onSuccess });
};
