import { SupportedPaymentMethod } from 'common/types';

export const getPaymentMethod = (paymentMethodId: string) => {
  switch (paymentMethodId) {
    case SupportedPaymentMethod.BANK_CARD:
      return 'Debit Card';
    case SupportedPaymentMethod.UNBANK_CASH:
      return 'Account Balance';
    case SupportedPaymentMethod.CASH:
      return 'Cash';
    default:
      return 'Unknown Payment Method';
  }
};
