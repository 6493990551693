import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { SellLimits } from 'modules/transactions/components/SellLimits';
import { Loader } from '@mantine/core';
import { BuyLimits } from 'modules/transactions/components/BuyLimits';
import { useLimits } from '../providers/limits';

export const TransactionLimitsPage = () => {
  const { buyLimits, sellLimits } = useLimits();

  const [selectedLimits, setSelectedLimits] = useState<string>('');

  if (buyLimits && sellLimits) {
    if (selectedLimits === 'sell') {
      return <SellLimits limits={sellLimits} />;
    } else if (selectedLimits === 'buy') {
      return <BuyLimits limits={buyLimits} />;
    } else {
      return (
        <div className="dashboard-page">
          {' '}
          <header className="">
            <div>
              <h2 className="mb-2 text-secondary">Transaction Limits </h2>
              <p>Read transactional limits and service limits</p>
            </div>
          </header>
          <div
            className="flex justify-between items-center py-7 px-6 my-4 border rounded-lg border-gray-dark hover:cursor-pointer hover:border-primary"
            onClick={() => {
              setSelectedLimits('buy');
            }}
          >
            <div>
              <h3 className="text-xl font-bold text-primary"> Buy Limits</h3>
              <p>Debit Card, Account Balance and Cash In</p>
            </div>
            <ChevronRightIcon className="h-7 text-primary" />
          </div>
          <div
            className="flex justify-between items-center py-7 px-6 my-4 border rounded-lg border-gray-dark hover:cursor-pointer hover:border-primary"
            onClick={() => {
              setSelectedLimits('sell');
            }}
          >
            <div>
              <h3 className="text-xl font-bold text-primary"> Sell Limits</h3>
              <p>Cash at ATM, Paypal and Venmo</p>
            </div>
            <ChevronRightIcon className="h-7 text-primary" />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="dashboard-page flex text-center items-center">
        <div className="mx-auto my-auto flex max-w-lg flex-col items-center text-center lg:w-2/3">
          <div className=" mb-4 p-2">
            <Loader size="xl" className="text-primary" />
          </div>
          <div className="mx-auto">
            <h3 className="font-bold text-xl"> Loading Transaction Limits</h3>

            <p className="my-2">
              Please give us a moment to prepare your account limits
            </p>
          </div>
        </div>
      </div>
    );
  }
};
