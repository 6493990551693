import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';
import { BankCard } from '../types/BankCard';

export type AddBankCardDTO = {
  name: string;
  transaction_details: {
    description: string;
    client_ip: string;
  };
  ignore_duplicates: boolean;
  software: string;
  expiry_month: string;
  expiry_year: string;
  cvv2: string;
  card: string;
  capture: boolean;
  save_card: boolean;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
};

export type CardDetails = Pick<
  AddBankCardDTO,
  'name' | 'card' | 'cvv2' | 'expiry_month' | 'expiry_year'
>;

export type BillingInformation = Pick<
  AddBankCardDTO,
  'street_address' | 'state' | 'zip_code' | 'city' | 'country'
>;

const addBankCard = async (credentials: AddBankCardDTO) => {
  const resp = await axios.post<{ data: BankCard }>('/api/account/bank/cards', {
    ...credentials,
    save_card: true,
  });
  return resp.data;
};

export const useAddBankCard = (
  onSuccess: OnSuccessCallback<{ data: BankCard }>
) => {
  return useUpdateApi(addBankCard, { onSuccess });
};
