import { UserIcon } from '@heroicons/react/solid';
import TextField from 'components/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CardDetails } from '../api/addBankCard';
import cardIcon from 'assets/icons/credit-card.svg';
import cvvIcon from 'assets/icons/cvv-icon.svg';
import { CalendarIcon } from '@heroicons/react/outline';
import { Checkbox } from '@mantine/core';
import Button from 'components/Button';
import InputMask from 'react-input-mask';
type BankCardFormProps = {
  onSubmit: (values: CardDetails) => void;
};

export const BankCardForm = ({ onSubmit }: BankCardFormProps) => {
  const { errors, touched, ...formik } = useFormik<
    Omit<CardDetails, 'expiry_month' | 'expiry_year'> & {
      acceptTerms: boolean;
      expiry: string;
    }
  >({
    initialValues: {
      card: '',
      cvv2: '',
      expiry: '',
      name: '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      card: Yup.string()
        .required('Card is a required field.')
        .length(19, 'Card number should be 16 digits long.'),
      cvv2: Yup.string()
        .required('CVV is a required field')
        .min(3, 'CVV should be at least 3 digits long.')
        .max(4, 'CVV should be at most 4 digits long.'),
      expiry: Yup.string()
        .required('Expiry date is a required field.')
        .test('test-expiry-year', 'Must be future date', (value) => {
          if (!value) {
            return false;
          }
          const [month, year] = value.split('/');
          const currentYear = new Date().getFullYear().toString();
          const currentMonth = new Date().getMonth() + 1;

          if (Number(year) < Number(currentYear)) {
            return false;
          } else if (
            Number(year) === Number(currentYear) &&
            Number(month) <= currentMonth
          ) {
            return false;
          }
          return true;
        }),
      name: Yup.string().required('Name is a required field.'),
      acceptTerms: Yup.boolean().equals(
        [true],
        'You must agree to the debit card terms to add a debit card.'
      ),
    }),
    onSubmit: (values) => {
      const { expiry, acceptTerms, card, ...rest } = values;
      const strippedCard = card.replace(/\s/g, '');
      const [expiry_month, expiry_year] = expiry.split('/');

      onSubmit({ ...rest, expiry_month, card: strippedCard, expiry_year });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="space-y-8">
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <TextField
          icon={<UserIcon className="h-6" />}
          placeholder="Name On Card"
          {...formik.getFieldProps('name')}
          error={errors.name && touched.name && errors.name}
        />
        <InputMask
          mask="9999 9999 9999 9999"
          maskPlaceholder=""
          placeholder="Card Number"
          {...formik.getFieldProps('card')}
        >
          <TextField
            icon={<img src={cardIcon} className="h-4" alt="" />}
            error={errors.card && touched.card && errors.card}
          />
        </InputMask>
      </div>
      <div className="flex gap-8">
        <TextField
          type="number"
          placeholder="CVV Code"
          icon={<img src={cvvIcon} className="h-4" alt="" />}
          {...formik.getFieldProps('cvv2')}
          error={errors.cvv2 && touched.cvv2 && errors.cvv2}
        />
        <InputMask
          mask="99/9999"
          maskPlaceholder={'MM/YYYY'}
          placeholder="Expiration"
          {...formik.getFieldProps('expiry')}
        >
          <TextField
            icon={<CalendarIcon className="h-6" />}
            error={errors.expiry && touched.expiry && errors.expiry}
          />
        </InputMask>
      </div>
      <Checkbox
        label={
          <span>
            By adding a new card, you agree to the{' '}
            <a
              href="https://unbank.com/terms-of-service/"
              className="text-primary hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              credit/debit card terms
            </a>
          </span>
        }
        classNames={{ input: 'border-primary' }}
        {...formik.getFieldProps('acceptTerms')}
      />
      <div className="!-mt-0 text-red-500">
        {errors.acceptTerms && touched.acceptTerms && errors.acceptTerms}
      </div>
      <div className="text-center">
        <Button type="submit">Continue</Button>
      </div>
    </form>
  );
};
