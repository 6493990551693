import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

export type VerifyPhoneCredentails = {
  code: string;
};

const verifyPhone = async ({ code }: VerifyPhoneCredentails) => {
  const resp = await axios.post('/api/account/verify/number', { code });
  return resp.data;
};

export const useVerifyPhone = (onSuccess?: OnSuccessCallback) => {
  return useUpdateApi(verifyPhone, {
    onSuccess,
    successNotificationText: 'Successfully verified phone number!',
  });
};
