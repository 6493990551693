export const BitcoinChart = () => {
  return (
    <div className="relative h-[560px] w-full overflow-hidden rounded-md bg-white pt-8 text-right text-xs leading-4 shadow-custom">
      <div className="h-[540px] w-full">
        <iframe
          title="crypto-chart"
          src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505"
          width="100%"
          height="536px"
          scrolling="auto"
          marginWidth={0}
          marginHeight={0}
          frameBorder={0}
          className="leading-[14px]"
        ></iframe>
      </div>
      <div className="absolute bottom-0 left-0 right-0 pb-4 pr-2 text-xs text-primary">
        <a href="https://coinlib.io" target="_blank" rel="noreferrer">
          Cryptocurrency Prices
        </a>
        &nbsp;by Coinlib
      </div>
    </div>
  );
};
