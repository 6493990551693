import { useCallback, useState } from 'react';
import notification from 'utils/notification';

type UpdateFunction<TParams, TResponse> = (data: TParams) => Promise<TResponse>;
export type OnSuccessCallback<TResponse = unknown> = (data: TResponse) => void;

type UseUpdateApiOptions<TResponse> = {
  onSuccess?: OnSuccessCallback<TResponse>;
  successNotificationText?: string; // used for if we want to show success notifications
};

export default function useUpdateApi<TData, TResponse>(
  updateFn: UpdateFunction<TData, TResponse>,
  options: UseUpdateApiOptions<TResponse> = {}
): {
  hasError: boolean;
  isLoading: boolean;
  data: TResponse | null;
  update: (data: TData) => void;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [responseData, setData] = useState<TResponse | null>(null);

  const update = useCallback(
    async (data: TData) => {
      setIsLoading(true);
      try {
        const resp = await updateFn(data);

        setData(resp);
        // if (options) {
        const { onSuccess, successNotificationText } = options;
        if (successNotificationText) {
          notification.notify(successNotificationText);
        }
        if (onSuccess) {
          onSuccess(resp);
        }
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [options, updateFn]
  );

  return { isLoading, hasError, data: responseData, update };
}
