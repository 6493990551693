export interface NotificationResponse {
    current_page: number;
    data: UnbankNotification[];

}
export interface UnbankNotification {
    id: string;
    type: string;
    notifiable_type: string;
    notifiable_id: string,
    data: NotificationData;
    read_at?: string;
    created_at: string;
    updated_at: string;
}
interface NotificationData {
    title?: string;
    message: string;
    status: string;
}
export interface NotificationReadResponse {
    read_count: number;
    message: string;
    status: number
}
export enum NotificationType {
    MESSAGE_NOTIFICATION = 'App\\Notifications\\MessageNotification',
    VERIFY_EMAIL_NOTIFICATION = 'App\\Notifications\\VerifyEmail',
    PRODUCT_ANNOUNCEMENT = 'ProductAnnouncement',
    SECURITY_ALERT = 'SecurityAlert',
    CRYPTO_ALERT = 'CryptoUpdated',
}
